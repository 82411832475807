import { Form as AForm, notification } from "antd";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CleanButton from "../../common/components/CleanButton";
import { Form } from "../../models/forms/form";
import { FormGroup } from "../../models/forms/form-group";
import { payrollPortalBaseUrl, useGetAcknowledgementsQuery, useGetFormsQuery, useSaveFormMutation, useEmployeeFormAcknowledgementMutation, useUploadFormMutation } from "../../state/portalApi";
import { RootState } from "../../store";
import { saveAs } from "file-saver";

export enum FormTypes {
  Category = 0,
  Department = 1
}

export enum FormType {
  LOCAL = "LOCAL",
  HOST = "HOST"
}

const useForms = () => {
  const { loggedInEmployeeId, token } = useSelector((state: RootState) => state.global);
  const { data, isLoading } = useGetFormsQuery(loggedInEmployeeId);
  const { data: acks, isLoading: acksLoading } = useGetAcknowledgementsQuery(true);
  const history = useNavigate();
  const [selectedForm, setSelectedForm] = useState<Form>(null);
  const [uploadForm, uploadResult] = useUploadFormMutation();
  const [saveForm] = useSaveFormMutation();
  const [defaultType, setDefaultType] = useState<FormTypes>(FormTypes.Category);
  const [defaultFormType, setDefaultFormType] = useState<FormType>(null);
  const [formForm] = AForm.useForm();
  const [categoryForm] = AForm.useForm();
  const [ackMessage, setAckMessage] = useState<boolean>(false);
  const [updateAck] = useEmployeeFormAcknowledgementMutation();

  const reset = () => {
    setSelectedForm(null);
    formForm.resetFields();
    setDefaultType(FormTypes.Category);
    setDefaultFormType(null);
    formForm.setFieldsValue({ formCatDep: defaultType });
    setAckMessage(false);
  };

  const handleAcknowledgeList = () => history("/forms/acknowledgements");
  const handleManageForms = () => history("/forms/manage-forms");

  const handleAcknowledgeDataExport = (row) => {
    const exportAckData = acks?.data?.forms?.find((x) => x.formListId === row.formListId);
    const finalArray = ["Employee Number\tEmployee Name\n"];
    exportAckData?.acknowledged.forEach((eachValue) => {
      finalArray.push(`${eachValue.employeeNumber}\t${eachValue.fullName}\n`);
    });
    const blob = new Blob(finalArray, { type: "application/vnd.ms-excel;charset=utf-8" });
    saveAs(blob, `${exportAckData.formName}`);
  };

  const handleUnacknowledgedDataExport = (row) => {
    const exportUnAckData = acks?.data?.forms?.find((x) => x.formListId === row.formListId);
    const finalArray = ["Employee Number\tEmployee Name\n"];
    exportUnAckData?.notAcknowledged.forEach((eachValue) => {
      finalArray.push(`${eachValue.employeeNumber}\t${eachValue.fullName}\n`);
    });
    const blob = new Blob(finalArray, { type: "application/vnd.ms-excel;charset=utf-8" });
    saveAs(blob, `${exportUnAckData.formName}`);
  };

  const ackCols = [
    { key: "catDep", dataIndex: "categoryName", title: "Category/Department" },
    { key: "FormName", dataIndex: "formName", title: "Form Name" },
    {
      key: "acknowledgedCount",
      dataIndex: "acknowledgedCount",
      title: "Acknowledged Count",
      render: (a, row) => (
        <CleanButton icon="download" onClick={() => handleAcknowledgeDataExport(row)}>
          {a}
        </CleanButton>
      )
    },
    {
      key: "NotAcknowledgedCount",
      dataIndex: "notAcknowledgedCount",
      title: "Unacknowledged Count",
      render: (a, row) => (
        <CleanButton icon="download" onClick={() => handleUnacknowledgedDataExport(row)}>
          {a}
        </CleanButton>
      )
    }
  ];

  const fetchPrintFile = async (fileName: string) => {
    const response = await fetch(`${payrollPortalBaseUrl}Form/ByFilename?fileName=${fileName}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`
      }
    });

    if (!response?.ok) {
      notification.error({ message: "There was an issue fetching the file. Please ensure the file exists in the correct location. " });
      return;
    }

    return response.blob();
  };

  const handlePrintForm = async (form: Form) => {
    if (form.formType === FormType.HOST) {
      window.open(form.formURL, "_blank");
      return;
    }
    const formUrl = form?.formURL;
    const fileName = formUrl?.slice(formUrl?.lastIndexOf("/") + 1);
    if (!fileName) {
      return notification.error({ message: "This file cannot be downloaded" });
    }

    const response = await fetchPrintFile(fileName);
    if (response) {
      const url = window.URL.createObjectURL(response);
      window.open(url, "_blank");
    }
  };

  const handleSelectForm = (formListId: number) => {
    reset();
    data?.data?.forms.forEach((formGroup: FormGroup) => {
      const selectItem: Form = formGroup?.forms.find((x) => x.formListId === formListId);
      const type: FormTypes = selectItem?.departmentId ? FormTypes.Department : FormTypes.Category;
      const formType: FormType = selectItem?.formType !== null ? (selectItem?.formType?.toUpperCase() === FormType.LOCAL ? FormType.LOCAL : FormType.HOST) : null;

      if (selectItem) {
        setSelectedForm(selectItem);
        setDefaultType(type);
        setDefaultFormType(formType);
        setAckMessage(selectItem.requiresAcknowledgement);
        formForm.setFieldsValue({
          ...selectItem,
          formCatDep: type,
          slectFormName: selectItem.formName,
          formType: formType,
          formURLLink: formType === FormType.HOST ? selectItem?.formURL : null
        });
      }
    });
  };

  const validateURL = (str) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };

  const handleSaveForm = async () => {
    const values = await formForm.validateFields();
    let validURL: boolean;

    if (defaultFormType === FormType.HOST) {
      validURL = validateURL(values.formURLLink);
      if (!validURL) {
        return notification.error({ message: "Please enter a valid URL" });
      }
    }

    const updatedForm: Form = {
      ...selectedForm,
      ...values,
      formListId: selectedForm?.formListId,
      departmentId: values?.formCatDep === FormTypes.Department ? values.departmentId : null,
      formCategoryId: values?.formCatDep === FormTypes.Category ? values.formCategoryId : null,
      formMessage: { isDeleted: false, messageCode: "", messageDescription: "", messageText: "" },
      isDeleted: false,
      isAcknowledged: false,
      isArchived: false,
      formURL: validURL ? values.formURLLink : selectedForm?.formURL,
      ackMessage: values.requiresAcknowledgement ? values.ackMessage : null
    };

    const response = await saveForm(updatedForm).unwrap();
    if (response.isSuccess) {
      notification.success({ message: "Form successfully saved. You can now view the updated form within the list." });
      reset();
    } else {
      notification.error({ message: response.message });
    }
  };

  const handleUploadForm = async (item) => {
    const formData = new FormData();
    formData.append("request", item.file);

    const response = await uploadForm(formData).unwrap();
    if (response) {
      setSelectedForm({
        ...selectedForm,
        formURL: response.data?.id
      });

      item.onSuccess(null, item.File, null);
    }
  };

  const handleFormType = (type) => {
    setDefaultFormType(type);
  };

  const handleArchived = async () => {
    const form = {
      ...selectedForm,
      formMessage: { isDeleted: false, messageCode: "", messageDescription: "", messageText: "" },
      isArchived: true
    };

    const response = await saveForm(form).unwrap();
    if (response.isSuccess) {
      notification.success({ message: "Form successfully archived." });
    } else {
      notification.error({ message: response.message });
    }

    reset();
  };

  const handleUnArchived = async () => {
    const form = {
      ...selectedForm,
      formMessage: { isDeleted: false, messageCode: "", messageDescription: "", messageText: "" },
      isArchived: false
    };

    const response = await saveForm(form).unwrap();
    if (response.isSuccess) {
      notification.success({ message: "Form successfully unarchived." });
    } else {
      notification.error({ message: response.message });
    }

    reset();
  };

  const handleAcknowledgeForm = async (row) => {
    await updateAck({ employeeId: loggedInEmployeeId, formId: row.formListId }).unwrap();
  };

  return {
    forms: data?.data?.forms,
    isLoading,
    handleManageForms,
    handlePrintForm,
    handleSelectForm,
    formForm,
    categories: data?.data?.categories,
    departments: data?.data?.departments,
    handleSaveForm,
    reset,
    handleUploadForm,
    defaultType,
    handleAcknowledgeList,
    acks: acks?.data?.forms,
    acksLoading,
    ackCols,
    categoryForm,
    defaultFormType,
    handleFormType,
    setAckMessage,
    ackMessage,
    selectedForm,
    handleArchived,
    handleUnArchived,
    handleAcknowledgeForm
  };
};

export default useForms;
