import React, { CSSProperties } from "react";
import { Button, Tooltip } from "antd";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonShape, ButtonType } from "antd/lib/button";
import { SizeType } from "antd/lib/config-provider/SizeContext";

/* eslint-disable react/require-default-props */
type CleanButtonProps = {
  block?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  icon?: IconName;
  disabled?: boolean;
  [x: string]: unknown;
  type?: ButtonType;
  shape?: ButtonShape;
  size?: SizeType;
  loading?:
    | boolean
    | {
        delay?: number;
      };
  prefixCls?: string;
  style?: CSSProperties;
  className?: string;
  tooltip?: string;
  ghost?: boolean;
  danger?: boolean;
  children?: React.ReactNode;
};
/* eslint-enable react/require-default-props */

const CleanButton = ({ ...props }: CleanButtonProps) => {
  const { icon, children, tooltip } = props;
  return tooltip ? (
    <Tooltip title={tooltip}>
      <Button className="erp-clean-button" style={{ height: "auto" }} {...props} icon={<FontAwesomeIcon icon={["fas", icon]} />}>
        {children}
      </Button>
    </Tooltip>
  ) : (
    <Button className="erp-clean-button" style={{ height: "auto" }} {...props} icon={<FontAwesomeIcon icon={["fas", icon]} />}>
      {children}
    </Button>
  );
};

export default CleanButton;
