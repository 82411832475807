/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, current } from "@reduxjs/toolkit";
import { deleteNodeInTree, searchTree } from "../common/constants/helpers";
import { ApprovalChain } from "../models/approval-chain/approval-chain";
import { ChainDetail } from "../models/approval-chain/approval-chain-detail";
import { EmployeeMeta } from "../models/employee/employee-meta";

interface ApprovalChainState {
  activeChain: ApprovalChain;
  selectedDetailNode: ChainDetail;
  selectedEmployee: EmployeeMeta;
}

const initialState: ApprovalChainState = {
  activeChain: null,
  selectedDetailNode: null,
  selectedEmployee: null
};

const approvalStore = createSlice({
  name: "approval",
  initialState,
  reducers: {
    getActiveChain: (state) => {
      state.activeChain;
    },
    setActiveChain: (state, action) => {
      state.activeChain = action.payload;
    },
    setSelectedDetailNode: (state, action) => {
      state.selectedDetailNode = action.payload;
    },
    setSelectedEmployee: (state, action) => {
      state.selectedEmployee = action.payload;
    },
    addNode: (state, action) => {
      const newNode: ChainDetail = action.payload;
      const nodeInTree: ChainDetail = searchTree(state.activeChain.detail, "children", "chainDetailId", state.selectedDetailNode.chainDetailId);

      if (nodeInTree) {
        nodeInTree.children.push(newNode);
      }
    },
    deleteNode: (state, action) => {
      const nodeToFind: ChainDetail = action.payload;
      const nodeInTree: ChainDetail = searchTree(state.activeChain.detail, "children", "chainDetailId", nodeToFind?.chainDetailId);

      if (nodeInTree) {
        deleteNodeInTree(state.activeChain.detail, nodeInTree);
      }
    },
    updateChain: (state, action) => {
      state.activeChain = {
        ...state.activeChain,
        ...action.payload
      };
    },
    resetApprovalState: (state) => {
      state.activeChain = null;
      state.selectedDetailNode = null;
      state.selectedEmployee = null;
    }
  }
});

export const { getActiveChain, setActiveChain, setSelectedDetailNode, setSelectedEmployee, addNode, updateChain, deleteNode, resetApprovalState } = approvalStore.actions;

export default approvalStore.reducer;
