import React, { useState } from "react";
import { Input } from "antd";
import { Popover, Progress } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PasswordRequirement, { getStrength, requirements } from "../components/password-requirement";

const usePasswordPopover = (placeholder) => {
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [value, setValue] = useState("");
  const checks = requirements.map((requirement, index) => <PasswordRequirement key={index} label={requirement.label} meets={requirement.re.test(value)} />);

  const strength = getStrength(value);
  const color = strength === 100 ? "teal" : strength > 50 ? "yellow" : "red";

  const renderPopover = (
    <Popover
      opened={popoverOpened}
      position="right"
      placement="start"
      withArrow
      styles={{ popover: { width: "100%" } }}
      style={{ width: "100%" }}
      noFocusTrap
      transition="pop-top-left"
      onFocusCapture={() => setPopoverOpened(true)}
      onBlurCapture={() => setPopoverOpened(false)}
      target={
        <Input.Password
          iconRender={(visible) => (visible ? <FontAwesomeIcon icon={["fas", "eye"]} /> : <FontAwesomeIcon icon={["fas", "eye-slash"]} />)}
          value={value}
          onChange={(event) => setValue(event.currentTarget.value)}
          placeholder={placeholder}
        />
      }
    >
      <Progress color={color} value={strength} size={5} style={{ marginBottom: 10 }} />
      <PasswordRequirement label="Includes at least 8 characters" meets={value.length > 7} />
      {checks}
    </Popover>
  );

  return {
    value,
    renderPopover
  };
};

export default usePasswordPopover;
