import React from "react";
import { Tag } from "antd";

const ActiveTag = ({ ...props }) => {
  if (props.value) {
    return <Tag color="success">Active</Tag>;
  }
  return <Tag color="warning">Inactive</Tag>;
};

export default ActiveTag;
