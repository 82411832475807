import React from "react";
import { Image, Layout, Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo2 from "../../../../assets/logo2.png";
import "../desktop/DesktopLayout.less";
import useSideNav from "./useSideNav";

const { Sider } = Layout;

/// This component is intended to replace the current side menu
/// however, I have not yet figured out what the best way to handle the modules
/// e.g., Utility, Payroll, Tax etc., and allow us to build the menu dynamically
/// based on the licensing of the respective client.
///
/// So, for now, it will be a hard coded menu.

const PayPortalSideMenu = ({ ...props }) => {
  const { buildSideMenu, toggle, menuCollapsed } = useSideNav();

  return (
    <Sider width={250} collapsedWidth={0} breakpoint="md" trigger={<FontAwesomeIcon className="erp-menu-trigger" icon={["fas", "bars"]} />} collapsible collapsed={menuCollapsed} onCollapse={toggle}>
      <Image width={250} style={{ margin: "auto", textAlign: "center" }} preview={false} src={logo2} />
      <Menu theme="light" mode="inline">
        {buildSideMenu()}
      </Menu>
    </Sider>
  );
};

export default PayPortalSideMenu;
