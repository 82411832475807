import moment from "moment";
import { defaultTimeFormat } from "../../common/constants/helpers";

export const calculateElapsedTime = (
  startTime: moment.MomentInput,
  endTime: moment.MomentInput,
  takeLunch: boolean,
  hoursCalcPrecision: number | null,
  lunchTimeThreshold: number | null,
  lunchMinutes: (number | string) | null
) => {
  let time = 0;
  const start = moment(startTime, defaultTimeFormat).set("date", 0);
  const end = moment(endTime, defaultTimeFormat).set("date", 0);

  let duration = moment.duration(end.diff(start));
  const precisionFactor = hoursCalcPrecision === 2 ? 100 : 1000;

  // Most likely an overnight shift
  if (duration.asHours() < 0) {
    duration = moment.duration(duration.add(24, "hours"));
  }

  // Handle lunch minutes if they exist
  if (takeLunch && moment(end).diff(moment(start), "m") >= 30) {
    time = Math.round(duration.subtract(lunchMinutes, "minutes").asHours() * precisionFactor) / precisionFactor;
  } else {
    time = Math.round(duration.asHours() * precisionFactor) / precisionFactor;
  }

  return time < 0 ? 0 : time;
};

export const calculateTimeDifference = (startTime: moment.MomentInput, endTime: moment.MomentInput) => {
  const tempDate = moment();
  const start = moment(startTime).set("date", 0);
  const end = moment(endTime).set("date", 0);

  start.set("date", tempDate.get("date"));
  start.set("month", tempDate.get("month"));
  start.set("year", tempDate.get("year"));

  end.set("date", tempDate.get("date"));
  end.set("month", tempDate.get("month"));
  end.set("year", tempDate.get("year"));

  return moment(end).diff(moment(start), "m") / 60;
};
