import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";
import { flatten } from "../../common/constants/helpers";
import { ApprovalChain } from "../../models/approval-chain/approval-chain";
import { ChainDetail } from "../../models/approval-chain/approval-chain-detail";
import { EmployeeMeta } from "../../models/employee/employee-meta";
import { addNode, deleteNode, setActiveChain, setSelectedDetailNode, setSelectedEmployee, updateChain } from "../../state/approvalStore";
import { useDeleteApprovalChainMutation, useGetApprovalChainDetailMutation, useGetApprovalChainsQuery, useGetEmployeeListQuery, useSaveApprovalChainMutation } from "../../state/portalApi";
import { RootState } from "../../store";

const useApprovalChain = () => {
  const [isExpand, setIsExpand] = useState<boolean>(false);
  const [searchedEmployee, setSearchedEmployee] = useState(null);
  const { data: approvalChains, isLoading } = useGetApprovalChainsQuery(undefined);
  const { data: employees, isLoading: employeesLoading } = useGetEmployeeListQuery(undefined);
  const [getDetail] = useGetApprovalChainDetailMutation();
  const [saveChain] = useSaveApprovalChainMutation();
  const [deleteChain] = useDeleteApprovalChainMutation();
  const { activeChain, selectedDetailNode } = useSelector((state: RootState) => state.approval);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveChain(null));
    dispatch(setSelectedEmployee(null));
    dispatch(setSelectedDetailNode(null));
  }, [dispatch]);

  const handleClear = () => {
    dispatch(setActiveChain(null));
    dispatch(setSelectedEmployee(null));
    dispatch(setSelectedDetailNode(null));
    setIsExpand(false);
    setSearchedEmployee(null);
  };

  const handleCreateNewChain = () => {
    const newChain: ApprovalChain = {
      isDeleted: false,
      chainId: undefined,
      chainDescription: "New approval chain. Put your description here.",
      chainName: "New Approval Chain",
      detail: null
    };

    dispatch(setActiveChain(newChain));
  };

  const handleCreateChainNode = (employee: EmployeeMeta, parentEmployeeId?: number | null) => {
    const key = Math.floor(Math.random() * 9999 + 1);
    const chainNode: ChainDetail = {
      approverEmployeeId: parentEmployeeId,
      chainDetailId: key,
      chainId: undefined,
      children: [],
      employeeId: employee?.employeeID,
      employeeNumber: employee?.employeeNumber,
      firstName: employee?.firstName,
      fullName: employee?.fullName,
      lastName: employee?.lastName,
      middleName: employee?.middleName,
      key: key,
      title: employee?.fullName
    };

    return chainNode;
  };

  const handleSelectRootEmployee = (employeeId: number | string) => {
    const selectedEmployee = employees[employeeId];
    const employee: EmployeeMeta = employees?.find((x) => x.employeeID === selectedEmployee?.employeeID);
    dispatch(setSelectedEmployee(employee));

    const rootNode: ChainDetail = handleCreateChainNode(employee);
    dispatch(
      updateChain({
        detail: rootNode
      })
    );
  };

  const handleSelectEmployee = (employeeId: number | string) => {
    const selectedEmployee = employees[employeeId];
    const employee: EmployeeMeta = employees?.find((x) => x.employeeID === selectedEmployee?.employeeID);
    const node: ChainDetail = handleCreateChainNode(employee);

    const tempArray: any = [];
    const tempData = flatten(tempArray, activeChain?.detail);
    const toAdd = tempData?.filter((e) => e.employeeId === Number(selectedEmployee?.employeeID));

    if (toAdd[0]?.employeeId) {
      notification.error({ message: "Employee already exists in this approval chain." });
    } else if (selectedEmployee?.employeeID) {
      dispatch(addNode(node));
    }
  };

  const handleSelectChain = async (chainId: number | string) => {
    if (chainId) {
      const chain = approvalChains?.data?.find((x) => x.chainId === chainId);
      const detail = await getDetail(chain.chainId).unwrap();
      dispatch(setActiveChain(chain));
      dispatch(updateChain({ detail: detail?.data }));
    } else {
      handleClear();
    }
    if (isExpand) setIsExpand(false);
  };

  const handleExpand = () => {
    if (!isExpand) {
      setIsExpand(true);
    } else {
      setIsExpand(false);
    }
  };

  const handleChangeTitle = (titleText: string) => {
    dispatch(updateChain({ chainName: titleText }));
  };

  const handleSelectNode = (key, node) => {
    const selectedNode: ChainDetail = { ...node.node };

    dispatch(setSelectedDetailNode(selectedNode));
  };

  const handleSave = async () => {
    const response = await saveChain(activeChain).unwrap();
    if (response.isSuccess) {
      notification.success({ message: response.message });
    } else {
      notification.error({ message: response.message });
    }

    handleClear();
  };

  const handleDelete = async () => {
    const response = await deleteChain(activeChain?.chainId).unwrap();
    if (response.isSuccess) {
      notification.success({ message: response.message });
    } else {
      notification.error({ message: response.message });
    }

    handleClear();
  };

  const handleChainDelete = async (node: ChainDetail) => {
    dispatch(deleteNode(node));
  };

  const handleSearchedEmployee = (employeeID) => {
    const selectedEmployee = employees[employeeID];
    const tempArray = [];
    const tempData = flatten(tempArray, activeChain?.detail);
    const toAdd = tempData?.filter((e) => e.employeeId === selectedEmployee?.employeeID);
    if (toAdd.length) {
      setSearchedEmployee(toAdd[0]?.employeeNumber);
      setIsExpand(true);
    } else {
      setSearchedEmployee(null);
    }
  };

  return {
    activeChain,
    employees,
    employeesLoading,
    approvalChains,
    isLoading,
    selectedDetailNode,
    handleCreateNewChain,
    handleSelectChain,
    handleChangeTitle,
    handleSelectRootEmployee,
    handleSelectNode,
    handleClear,
    handleSelectEmployee,
    handleSave,
    handleDelete,
    handleChainDelete,
    isExpand,
    handleExpand,
    handleSearchedEmployee,
    searchedEmployee
  };
};

export default useApprovalChain;
