import React from "react";
import { Badge, Card } from "antd";

type BannerCardProps = {
  title: string;
  children?: React.ReactNode; // eslint-disable-line react/require-default-props
  internal?: boolean; // eslint-disable-line react/require-default-props
  hideTitle?: boolean; // eslint-disable-line react/require-default-props
};

const BannerCard = ({ ...props }: BannerCardProps) => {
  const className = props.internal ? "erp-banner-card-internal erp-auth-card" : "erp-banner-card";
  const position = props.internal ? "start" : "end";
  const style = props.internal ? { backgroundColor: "#fff" } : { backgroundColor: "#f5f6f7" };

  return !props.hideTitle ? (
    <Badge.Ribbon placement={position} text={props.title}>
      <Card className={className} style={style}>
        {props.children}
      </Card>
    </Badge.Ribbon>
  ) : (
    <Card className={className} style={style}>
      {props.children}
    </Card>
  );
};

export default BannerCard;
