import React from "react";

export const portalContext = {
  theme: {
    colors: {
      primary: "#009ca6",
      secondary: "#7b57cb",
      tertiary: "#d62598",
      warning: "#ff8a3d",
      danger: "#cd425b"
    }
  },
  api: {
    baseUrl: "https://localhost:44393/api/"
  }
};

export const PortalContext = React.createContext(portalContext);
