import { Badge, Card, Group, Space, Text } from "@mantine/core";
import { Table } from "antd";
import moment from "moment";
import React from "react";
import BannerCard from "../../common/components/BannerCard";
import Page from "../../common/components/Page";
import useMediaQuery from "../../common/hooks/useMediaQuery";
import useInventory from "./useInventory";

const Inventory = () => {
  const { data, isLoading, columns } = useInventory();
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <Page>
      <Page.Title title="Inventory" icon="database" showBreadcrumb />
      <Page.Content>
        <BannerCard title="" hideTitle>
          {isMobile ? (
            React.Children.toArray(
              data?.map((inventory) => {
                return (
                  <>
                    <br />
                    <Card shadow="sm" padding="md">
                      <Group position="apart" style={{ marginBottom: 5, marginTop: 2 }}>
                        <Text weight={500}>{inventory?.itemName}</Text>
                        <Badge color="teal" variant="gradient">
                          Qty: {inventory?.quantity}
                        </Badge>
                      </Group>
                      <Text size="sm" style={{ lineHeight: 1.5 }}>
                        {inventory?.tagOrDescription}
                      </Text>
                      <Text size="sm" style={{ lineHeight: 1.5, color: "grey" }}>
                        Received: {moment(inventory?.receivedDate).format("MM-DD-YYYY")}
                      </Text>
                    </Card>
                  </>
                );
              })
            )
          ) : (
            <Table loading={isLoading} size="small" dataSource={data} columns={columns} />
          )}
        </BannerCard>
      </Page.Content>
    </Page>
  );
};

export default Inventory;
