import { Button, Checkbox, Form, Input, Select } from "antd";
import React from "react";

const ManageCategoryForm = ({ ...props }) => {
  const { form, categories, handleSetForm, handleReset, handleSaveCategory } = props;
  return (
    <Form form={form} layout="vertical" initialValues={{ isDeleted: true }}>
      <Form.Item name="formCategoryId">
        <Select style={{ width: "100%" }} placeholder="Select a category" onChange={handleSetForm}>
          {React.Children.toArray(
            categories?.map((data) => {
              return <Select.Option value={data?.formCategoryId}>{data?.categoryName}</Select.Option>;
            })
          )}
        </Select>
      </Form.Item>
      <Form.Item name="categoryName" label="Category Name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="categoryDescription" label="Category Description" rules={[{ required: true }]}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item name="isDeleted" valuePropName="checked">
        <Checkbox>Is Active</Checkbox>
      </Form.Item>
      <Form.Item>
        <Button type="primary" block onClick={handleSaveCategory}>
          Submit
        </Button>
      </Form.Item>
      <Form.Item>
        <Button type="dashed" block onClick={handleReset}>
          Reset
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageCategoryForm;
