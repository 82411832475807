import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import Page from "../../../common/components/Page";
import { RootState } from "../../../store";
import useForms, { FormType } from "../useForms";
import ManageFormToolbar from "./manage-form-toolbar";
import ManageFormsForm from "./manage-forms-form";
import UploadForm from "./upload-form";

const ManageForms = () => {
  const {
    forms,
    formForm,
    handleSelectForm,
    categories,
    departments,
    handleSaveForm,
    reset,
    handleUploadForm,
    defaultType,
    defaultFormType,
    handleFormType,
    setAckMessage,
    ackMessage,
    selectedForm,
    handleArchived,
    handleUnArchived
  } = useForms();
  const { activeEmployee } = useSelector((state: RootState) => state.global);

  return (
    <Page>
      <Page.Title title="Manage Forms" icon="file-alt" showBreadcrumb />
      <ManageFormToolbar
        activeEmployee={activeEmployee}
        handleSelectForm={handleSelectForm}
        forms={forms}
        handleSaveForm={handleSaveForm}
        reset={reset}
        selectedForm={selectedForm}
        handleArchived={handleArchived}
        handleUnArchived={handleUnArchived}
      />
      <Page.Content>
        <Row gutter={[16, 16]}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <ManageFormsForm
              forms={forms}
              formForm={formForm}
              categories={categories?.filter((eachCategory) => !eachCategory.isDeleted)}
              departments={departments}
              defaultType={defaultType}
              handleSelectForm={handleSelectForm}
              handleFormType={handleFormType}
              defaultFormType={defaultFormType}
              ackMessage={ackMessage}
              setAckMessage={setAckMessage}
            />
          </Col>
          {defaultFormType === FormType.LOCAL && (
            <Col lg={12} md={12} sm={24} xs={24}>
              <UploadForm onUpload={handleUploadForm} />
            </Col>
          )}
        </Row>
      </Page.Content>
    </Page>
  );
};

export default ManageForms;
