import { Button, Col, Result, Row } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import PayPortalSideMenu from "../layout/side-menu/back-office-side-menu";
import Page from "./Page";

const AccessDenied = () => {
  const history = useNavigate();
  return (
    <Page>
      <Page.Content>
        <Row>
          <Col span={4}>
            <PayPortalSideMenu />
          </Col>
          <Col span={20}>
            <Result
              status="403"
              title="Access Denied"
              subTitle="You do not have permission to access this page"
              extra={[
                <Button type="primary" onClick={() => history("/dashboard")}>
                  Back Home
                </Button>
              ]}
            />
          </Col>
        </Row>
      </Page.Content>
    </Page>
  );
};

export default AccessDenied;
