import React from "react";
import { Card, Group, Badge, Text } from "@mantine/core";

const ActionCard = ({ ...props }) => {
  const { title, badgeData, badgeExtra, children } = props;
  return (
    <Card shadow="sm" padding="md" radius="md">
      <Group position="apart" style={{ marginBottom: 5, marginTop: 2 }}>
        <Text weight={500}>{title}</Text>
        {badgeData && badgeExtra ? (
          <Badge color="pink" variant="light">
            {badgeData} {badgeExtra}
          </Badge>
        ) : null}
      </Group>
      {children}
    </Card>
  );
};

export default ActionCard;
