import React, { useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Menu, Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setConfig, setMenuCollapsed } from "../../../state/globalStore";
import { RootState } from "../../../store";
import iconList from "../../constants/fontAwesomeIcons";
import getConfigs from "../../../views/getConfigs";
import { setActiveTimecard, setIsDirty } from "../../../state/timecardStore";
import { useGetAllConfigOptionsQuery } from "../../../state/portalApi";

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useSideNav = () => {
  const { menuCollapsed, activeEmployee } = useSelector((state: RootState) => state.global);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();
  const { isDirty, activeTimecard } = useSelector((state: RootState) => state.timecard);
  const prevAmount = usePrevious(location.pathname);
  const { data: configData } = useGetAllConfigOptionsQuery(undefined);
  const configurations = getConfigs([
    "Dashboard",
    "PayHistory",
    "ShowTimecard",
    "Benefits",
    "Leave",
    "Forms",
    "Inventory",
    "ShowTimecardApproval",
    "ShowApprovalChain",
    "EnablePaycodeMapping",
    "SideMenuExpanded"
  ]);

  useEffect(() => {
    dispatch(setConfig(configData?.data));
  }, [configData]);

  useEffect(() => {
    if (location.pathname !== "/employees/detail" && location.pathname === "/timecard" && activeTimecard) {
      dispatch(setActiveTimecard(activeTimecard));
    }

    if (prevAmount !== location.pathname && !isDirty) {
      dispatch(setActiveTimecard(null));
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    iconList;
  }, [location.pathname]);

  useEffect(() => {
    if (configurations.SideMenuExpanded) {
      dispatch(setMenuCollapsed(!(configurations.SideMenuExpanded?.toLowerCase() === "true")));
    }
  }, [configurations.SideMenuExpanded]);

  const toggle = () => {
    dispatch(setMenuCollapsed(undefined));
  };

  const handleOk = () => {
    dispatch(setIsDirty(false));
    dispatch(setActiveTimecard(null));
    return history(`${location.pathname}`);
  };

  const handleCancel = () => {
    dispatch(setIsDirty(true));
    dispatch(setActiveTimecard(activeTimecard));
    return history("/timecard");
  };

  const handleOnMenuItemClick = (setVisible) => {
    if (setVisible) setVisible(false);
  };

  const buildSideMenu = (setVisible = null) => {
    return (
      <Menu defaultSelectedKeys={["/dashboard"]} selectedKeys={[location.pathname]} onClick={() => handleOnMenuItemClick(setVisible)}>
        {isDirty && location.pathname !== "/timecard" && location.pathname !== "/employees/detail" && (
          <Modal
            width={480}
            visible={isDirty}
            closable={false}
            title={<div style={{ fontSize: 16, color: "red" }}>Are you sure you want to leave this page? You may lose unsaved changes.</div>}
            onOk={handleOk}
            onCancel={handleCancel}
          />
        )}
        <Menu.ItemGroup key="dashboard" title="Payroll">
          {configurations.Dashboard?.toLowerCase() === "true" && (
            <Menu.Item key="/dashboard" title="Dashboard" icon={<FontAwesomeIcon icon={["fas", "tachometer-alt"]} />}>
              Dashboard
              <Link to="/dashboard" />
            </Menu.Item>
          )}
          {configurations.ShowTimecard?.toLowerCase() === "true" && (
            <Menu.Item key="/timecard" title="Timecard" icon={<FontAwesomeIcon icon={["fas", "clock"]} />}>
              Timecard
              <Link to="/timecard" />
            </Menu.Item>
          )}
          {configurations.PayHistory?.toLowerCase() === "true" && (
            <Menu.Item key="/pay-history" title="Pay History" icon={<FontAwesomeIcon icon={["fas", "history"]} />}>
              Pay History
              <Link to="/pay-history" />
            </Menu.Item>
          )}
          {configurations.Benefits?.toLowerCase() === "true" && (
            <Menu.Item key="/benefits" title="Benefits" icon={<FontAwesomeIcon icon={["fas", "heart"]} />}>
              Benefits
              <Link to="/benefits" />
            </Menu.Item>
          )}
          {configurations.Leave?.toLowerCase() === "true" && (
            <Menu.Item key="/leave" title="Leave" icon={<FontAwesomeIcon icon={["fas", "calendar"]} />}>
              Leave
              <Link to="/leave" />
            </Menu.Item>
          )}
          {configurations.Forms?.toLowerCase() === "true" && (
            <Menu.Item key="/forms" title="Forms" icon={<FontAwesomeIcon icon={["fas", "file-alt"]} />}>
              Forms
              <Link to="/forms" />
            </Menu.Item>
          )}
          {configurations.Inventory?.toLowerCase() === "true" && (
            <Menu.Item key="/inventory" title="Inventory" icon={<FontAwesomeIcon icon={["fas", "database"]} />}>
              Inventory
              <Link to="/inventory" />
            </Menu.Item>
          )}
          {(activeEmployee?.isAdmin || activeEmployee?.isManager) && configurations.ShowTimecardApproval?.toLowerCase() === "true" && (
            <Menu.Item key="/timecard-approval" title="Timecard Approval" className="sideNav-menu" icon={<FontAwesomeIcon icon={["fas", "thumbs-up"]} />}>
              Timecard Approval
              <Link to="/timecard-approval" />
            </Menu.Item>
          )}
        </Menu.ItemGroup>
        {(activeEmployee?.isAdmin || activeEmployee?.isManager) && (
          <Menu.ItemGroup key="2" title="Administration">
            <Menu.Item key={location.pathname.includes("/employees") ? location.pathname : "/employees"} title="Employees" icon={<FontAwesomeIcon icon={["fas", "users"]} />}>
              Employees
              <Link to="/employees" />
            </Menu.Item>
            {configurations.ShowApprovalChain?.toLowerCase() === "true" && activeEmployee?.isAdmin && (
              <Menu.Item key="/approval-chain" title="Approval Chain" icon={<FontAwesomeIcon icon={["fas", "sitemap"]} />}>
                Approval Chain
                <Link to="/approval-chain" />
              </Menu.Item>
            )}
            {activeEmployee?.isAdmin && (
              <Menu.Item key="/config" title="Configuration" icon={<FontAwesomeIcon icon={["fas", "cog"]} />}>
                Configuration
                <Link to="/config" />
              </Menu.Item>
            )}
            {configurations.EnablePaycodeMapping?.toLowerCase() === "true" && activeEmployee?.isAdmin && (
              <Menu.Item key="/paycodes" title="Paycodes" icon={<FontAwesomeIcon icon={["fas", "wrench"]} />}>
                Paycodes
                <Link to="/paycodes" />
              </Menu.Item>
            )}
          </Menu.ItemGroup>
        )}
      </Menu>
    );
  };

  return { buildSideMenu, toggle, menuCollapsed };
};

export default useSideNav;
