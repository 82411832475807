import { Card, Col, Descriptions, Empty, Row, Skeleton } from "antd";
import React from "react";
import BannerCard from "../../common/components/BannerCard";
import { formattedDate } from "../../common/constants/helpers";
import useBenefits from "./useBenefits";

const BenefitsCards = ({ ...props }) => {
  const { fromEmployeeBenefits, selectedEmployeeId } = props;
  const { data, isLoading } = useBenefits(fromEmployeeBenefits, selectedEmployeeId);

  return data?.benefits?.length > 0 ? (
    <Row gutter={[16, 16]}>
      {React.Children.toArray(
        data?.benefits.map((benefit) => {
          return (
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <BannerCard internal hideTitle title="">
                {isLoading ? (
                  <Skeleton active />
                ) : (
                  <Descriptions title={benefit?.benefitTypeDescription} column={1}>
                    <Descriptions.Item label="Your Contribution">{"$" + benefit?.employeeContribution.toFixed(2)}</Descriptions.Item>
                    <Descriptions.Item label="Description">{benefit?.bBenefitTypeDescription}</Descriptions.Item>
                    <Descriptions.Item label="Eligibility Date">{formattedDate(benefit?.eligibilityDate)}</Descriptions.Item>
                  </Descriptions>
                )}
              </BannerCard>
            </Col>
          );
        })
      )}
    </Row>
  ) : (
    <Empty description="No benefits exist." />
  );
};

export default BenefitsCards;
