import React, { useState } from "react";
import moment from "moment";
import { Layout, Menu } from "antd";
import AppHeader from "./AppHeader";
import useSideNav from "../side-menu/useSideNav";
import MobileNavbar from "../../layout/mobile/mobile-nav-bar";
import useMediaQuery from "../../hooks/useMediaQuery";
import "./DesktopLayout.less";
import PayPortalSideMenu from "../side-menu/back-office-side-menu";

const DesktopLayout = ({ ...props }) => {
  const [visible, setVisible] = useState(false);
  const { buildSideMenu } = useSideNav();
  const isMobile = useMediaQuery("(max-width:768px)");

  const SideMenu = () => {
    return <Menu className="mobileMenu">{buildSideMenu(setVisible)}</Menu>;
  };

  return (
    <Layout style={{ height: "100vh" }}>
      {isMobile ? <MobileNavbar menu={<SideMenu />} visibility={visible} setVisibility={setVisible} /> : <PayPortalSideMenu />}
      <Layout className="site-layout">
        {!isMobile && <AppHeader />}
        <Layout.Content
          style={{
            padding: 10,
            minHeight: 280,
            overflow: "auto",
            marginLeft: 24,
            marginRight: 0
          }}
        >
          {props.children}
        </Layout.Content>
        {/*
          Used HTML footer tag instead of using from ANTD Footer
          Because using ANTD Footer throws error with react-idle-timer npm package
        */}
        <footer style={{ textAlign: "center" }}>
          <p style={{ wordBreak: "normal", width: "90%" }}>
            ©{moment().format("YYYY")} Copyright Civica North America, Inc., <a href="https://civica.com">d.b.a. Civica</a>. All rights reserved.
          </p>
        </footer>
      </Layout>
    </Layout>
  );
};

export default DesktopLayout;
