import { Col, Form, Row, Select, Table } from "antd";
import React from "react";
import BannerCard from "../../common/components/BannerCard";
import CleanButton from "../../common/components/CleanButton";
import Page from "../../common/components/Page";
import usePaycodeMaintenance from "./usePaycodeMaintenance";

const Paycodes = () => {
  const { handleSave, codesAlreadyAdded, availableCode, handleChange, payCodeLoading, totalPayCodes, allDepartment, paycodeList, departId, payCodeByIdLoading, form } = usePaycodeMaintenance();

  return (
    <Page>
      <Page.Title title="Paycodes" icon="wrench" />
      <Page.Toolbar>
        <Form form={form}>
          <Form.Item name="departId">
            <Select style={{ width: "300px", marginTop: "15px" }} allowClear onChange={handleChange} placeholder="Select a department">
              {React.Children.toArray(
                allDepartment?.map((depart) => {
                  return <Select.Option value={depart?.departmentId}>{depart?.departmentDescription}</Select.Option>;
                })
              )}
            </Select>
          </Form.Item>
        </Form>
        <CleanButton icon="save" onClick={handleSave} disabled={!departId}>
          Save
        </CleanButton>
      </Page.Toolbar>
      <Page.Content>
        <Row gutter={[16, 16]}>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <BannerCard title="Available Codes">
              <Table loading={payCodeLoading} dataSource={totalPayCodes} columns={availableCode} scroll={{ x: 240 }} pagination={{ size: "small", pageSize: 5 }} />
            </BannerCard>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            {departId ? (
              <BannerCard title={`Codes added to ${allDepartment?.find((x) => x.departmentId === departId).departmentDescription}`}>
                <Table loading={payCodeByIdLoading} dataSource={paycodeList} columns={codesAlreadyAdded} scroll={{ x: 240 }} pagination={{ size: "small", pageSize: 5 }} />
              </BannerCard>
            ) : null}
          </Col>
        </Row>
      </Page.Content>
    </Page>
  );
};

export default Paycodes;
