import { Grid, Card, InputWrapper, TextInput } from "@mantine/core";
import moment from "moment";
import React from "react";
import { defaultDateFormat } from "../../common/constants/helpers";

const TimecardApprovalEntry = ({ ...props }) => {
  const { entries } = props;
  return (
    <>
      {React.Children.toArray(
        entries?.map((entry) => {
          return (
            <Card
              sx={(t) => ({
                margin: t.spacing.lg,
                padding: t.spacing.sm,
                borderColor: t.colors.green
              })}
              radius="md"
              withBorder
            >
              <Grid gutter="sm" grow align="center" columns={24}>
                <Grid.Col span={8}>
                  <InputWrapper label="Work Date">
                    <TextInput radius="md" variant="filled" disabled={true} defaultValue={moment(entry?.workDate).format(defaultDateFormat)} />
                  </InputWrapper>
                </Grid.Col>
                <Grid.Col span={8}>
                  <InputWrapper label="Start Time">
                    <TextInput radius="md" variant="filled" disabled={true} defaultValue={moment(entry?.startTime).format("HH:MM")} />
                  </InputWrapper>
                </Grid.Col>
                <Grid.Col span={8}>
                  <InputWrapper label="End Time">
                    <TextInput radius="md" variant="filled" disabled={true} defaultValue={moment(entry?.startTime).format("HH:MM")} />
                  </InputWrapper>
                </Grid.Col>
                <Grid.Col span={24}>
                  <InputWrapper label="Pay Code">
                    <TextInput radius="md" variant="filled" disabled={true} defaultValue={entry?.code + "-" + entry?.payCodeDescription} />
                  </InputWrapper>
                </Grid.Col>
                <Grid.Col span={24}>
                  <InputWrapper label="Location">
                    <TextInput radius="md" variant="filled" disabled={true} defaultValue={entry?.locationName} />
                  </InputWrapper>
                </Grid.Col>
                <Grid.Col span={24}>
                  <InputWrapper label="Note">
                    <TextInput radius="md" variant="filled" disabled={true} defaultValue={entry?.locationName} />
                  </InputWrapper>
                </Grid.Col>
                <Grid.Col span={12}>
                  <InputWrapper label="Hours">
                    <TextInput radius="md" variant="filled" disabled={true} defaultValue={entry?.laborHours + "hrs"} />
                  </InputWrapper>
                </Grid.Col>
                <Grid.Col span={12}>
                  <InputWrapper label="Lunch">
                    <TextInput radius="md" variant="filled" disabled={true} defaultValue={entry?.deductLunch ? "Yes" : "False"} />
                  </InputWrapper>
                </Grid.Col>
              </Grid>
            </Card>
          );
        })
      )}
    </>
  );
};

export default TimecardApprovalEntry;
