import React from "react";
import { Grid, Card, InputWrapper, TextInput } from "@mantine/core";

const TimecardApprovalLeaves = ({ ...props }) => {
  const { accumulatedLeaves } = props;
  return (
    <>
      {React.Children.toArray(
        accumulatedLeaves?.map((leaves) => {
          return (
            <Card
              sx={(t) => ({
                margin: t.spacing.lg,
                padding: t.spacing.sm,
                borderColor: t.colors.green
              })}
              radius="md"
              withBorder
            >
              <Grid gutter="sm" grow align="center" columns={24}>
                <Grid.Col span={16}>
                  <InputWrapper label="Accrual Description">
                    <TextInput radius="md" variant="filled" disabled={true} defaultValue={leaves?.accrualClassDescription} />
                  </InputWrapper>
                </Grid.Col>
                <Grid.Col span={8}>
                  <InputWrapper label="Earned">
                    <TextInput radius="md" variant="filled" disabled={true} defaultValue={leaves?.accrualEarnedYTD} />
                  </InputWrapper>
                </Grid.Col>
                <Grid.Col span={8}>
                  <InputWrapper label="Used">
                    <TextInput radius="md" variant="filled" disabled={true} defaultValue={leaves?.accrualUsedYTD} />
                  </InputWrapper>
                </Grid.Col>
                <Grid.Col span={8}>
                  <InputWrapper label="Balance">
                    <TextInput radius="md" variant="filled" disabled={true} defaultValue={leaves?.accruedBalance} />
                  </InputWrapper>
                </Grid.Col>
                <Grid.Col span={8}>
                  <InputWrapper label="Hours">
                    <TextInput radius="md" variant="filled" disabled={true} defaultValue={leaves?.maxHours + " hrs"} />
                  </InputWrapper>
                </Grid.Col>
              </Grid>
            </Card>
          );
        })
      )}
    </>
  );
};

export default TimecardApprovalLeaves;
