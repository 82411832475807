import { Form, notification } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import usePasswordPopover from "../../../common/hooks/use-password-popover";
import { setToken } from "../../../state/globalStore";
import { useGetUsernameByEmailMutation, useResetEmployeePasswordMutation, useValidateUserMutation } from "../../../state/portalApi";

const useForgetPassword = () => {
  const [employeeFound, setEmployeeFound] = useState<boolean>(false);
  const [resetForm] = Form.useForm();
  const [forgotForm] = Form.useForm();
  const [resetPassword, { isLoading: resetLoading }] = useResetEmployeePasswordMutation();
  const [validateUser, validateUserResponse] = useValidateUserMutation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const { value: passwordValue, renderPopover } = usePasswordPopover("Password");
  const { value: confirmValue, renderPopover: confirmRender } = usePasswordPopover("Confirm Password");
  const [findUsername] = useGetUsernameByEmailMutation();
  const userName = localStorage.getItem("username");

  const handleValidateUser = async () => {
    const values = await resetForm.validateFields();
    if (values) {
      const response = await validateUser({ username: userName ? userName : values.username, lastFour: values.lastFourSSN }).unwrap();
      if (response.data) {
        setEmployeeFound(true);
      } else {
        notification.error({ message: response.message });
      }
    }
  };

  const handleResetPassword = async () => {
    const values = await resetForm.validateFields();
    if (values && values.password) {
      const request = {
        userId: validateUserResponse.data.data.userId,
        password: passwordValue,
        confirmPassword: passwordValue
      };

      const response = await resetPassword(request).unwrap();
      if (response.data) {
        dispatch(setToken(response.data));
        history("/dashboard");
        notification.success({ message: "Password reset successfully." });
      } else {
        notification.error({ message: response.message });
      }
    }
  };

  const validateEmail = async (email) => {
    const emailRegEx = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return !!emailRegEx.test(email);
  };

  const handleFindUsername = async () => {
    const values = await resetForm.validateFields();
    const response = await findUsername(values.email).unwrap();
    const validEmail = await validateEmail(values?.email);
    if (!validEmail) return notification.error({ message: "Please enter a valid email" });

    if (response.isSuccess) {
      notification.success({ message: response.message });
    } else {
      notification.error({ message: response.message });
    }
  };

  return { employeeFound, resetForm, forgotForm, handleResetPassword, resetLoading, handleFindUsername, handleValidateUser, renderPopover, confirmRender };
};

export default useForgetPassword;
