import { ActionIcon, Button, Card, Grid, Group, Text, ThemeIcon } from "@mantine/core";
import { BellIcon, DownloadIcon, FileIcon, TrashIcon } from "@modulz/radix-icons";
import { Popconfirm } from "antd";
import React from "react";
import { Form } from "../../models/forms/form";
import useForms from "./useForms";

type FormGroupCardProps = {
  forms: Form[];
  handlePrintForm: (e) => void;
  handleDeleteForm: (e) => void;
};

const FormGroupCard = ({ ...props }: FormGroupCardProps) => {
  const { forms, handlePrintForm, handleDeleteForm } = props;
  const { handleAcknowledgeForm } = useForms();

  return (
    <>
      {React.Children.toArray(
        forms?.map((form: Form) => {
          return (
            <Card
              sx={(t) => ({
                margin: t.spacing.lg,
                padding: t.spacing.sm
              })}
              radius="md"
            >
              <Card.Section
                sx={(t) => ({
                  padding: t.spacing.md
                })}
              >
                <Grid style={{ padding: 10 }}>
                  <Grid.Col span={2}>
                    <ThemeIcon>
                      <FileIcon />
                    </ThemeIcon>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <Text weight={600}>{form?.formName}</Text>
                  </Grid.Col>
                  <Grid.Col span={2}>
                    {form?.requiresAcknowledgement && form.isAcknowledged === false && (
                      <Popconfirm okText="Acknowledge" onConfirm={() => handleAcknowledgeForm(form)} title={form.ackMessage}>
                        <ActionIcon
                          variant="light"
                          radius="md"
                          sx={(t) => ({
                            left: 20,
                            position: "relative"
                          })}
                        >
                          <BellIcon />
                        </ActionIcon>
                      </Popconfirm>
                    )}
                  </Grid.Col>
                </Grid>
              </Card.Section>
              <Card.Section
                sx={(t) => ({
                  padding: t.spacing.sm
                })}
              >
                <Text color="dimmed">{form?.formDescription}</Text>
              </Card.Section>
              <Card.Section style={{ padding: 20 }}>
                <Group direction="column">
                  <Button onClick={() => handlePrintForm(form)} radius="md" fullWidth leftIcon={<DownloadIcon />}>
                    Download
                  </Button>
                  <Popconfirm onConfirm={() => handleDeleteForm(form)} title="Form will be deleted">
                    <Button size="sm" radius="md" color="red" variant="outline" fullWidth leftIcon={<TrashIcon />}>
                      Delete
                    </Button>
                  </Popconfirm>
                </Group>
              </Card.Section>
            </Card>
          );
        })
      )}
    </>
  );
};

export default FormGroupCard;
