import React, { useState } from "react";
import { Card, Center, Image, Popover, Progress } from "@mantine/core";
import { Button, Col, DatePicker, Divider, Form, Input, Row, Select } from "antd";
import logo2 from "../../../../assets/logo2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { requiredRules } from "../../../common/constants/rules";
import CleanButton from "../../../common/components/CleanButton";
import useRegister from "./useRegister";
import { dateFormatList, securityQuestions } from "../../../common/constants/helpers";
import useMediaQuery from "../../../common/hooks/useMediaQuery";

const RegistrationForm = ({ ...props }) => {
  const { form, handleRegister, handleLogin, renderPopover, confirmRender } = useRegister();
  const isMobile = useMediaQuery("(max-width:768px)");

  const getAllSecurityQuestions = () => {
    const allSecurityQuestion = [];
    for (const key in securityQuestions) {
      allSecurityQuestion.push(
        <Select.Option value={key}>
          <div style={{ whiteSpace: "initial", display: "flex", height: "auto", lineHeight: "normal" }}>
            <span>{securityQuestions[key]}</span>
          </div>
        </Select.Option>
      );
    }
    return allSecurityQuestion;
  };

  return (
    <>
      <Center style={{ height: isMobile ? 1300 : 900 }}>
        <Card className="erp-auth-card" radius="md" shadow="sm" padding="lg" style={{ width: isMobile ? "90%" : "50%" }}>
          <Row gutter={16} align="middle">
            <Col xs={24} sm={24} md={24} lg={7} xl={12}>
              <Card.Section>
                <Image fit="cover" src={logo2} alt="Norway" height={isMobile ? "80%" : "50%"} />
              </Card.Section>
            </Col>
            <Col xs={24} sm={24} md={24} lg={17} xl={12}>
              <Form form={form} layout="vertical">
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form.Item name="firstName" label="First Name" {...requiredRules}>
                      <Input autoFocus />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form.Item name="lastName" label="Last Name" {...requiredRules}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item name="email" label="Email" {...requiredRules}>
                      <Input type="email" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form.Item name="employeeNumber" label="Employee Number" {...requiredRules}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form.Item name="lastFourSSN" label="Last Four (SSN)" {...requiredRules}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form.Item name="birthDate" label="Birth Date" {...requiredRules}>
                      <DatePicker format={dateFormatList} style={{ width: "100%", border: "none" }} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form.Item name="username" label="Username" {...requiredRules}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form.Item name="password" label="Password" {...requiredRules}>
                      {renderPopover}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form.Item name="confirmPassword" label="Confirm Password" {...requiredRules}>
                      {confirmRender}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item name="securityQuestionFK" label="Security Question" {...requiredRules}>
                      <Select placeholder="Choose security question">{getAllSecurityQuestions()}</Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item name="securityAnswer" label="Answer" {...requiredRules}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Button icon={<FontAwesomeIcon icon={["fas", "check"]} />} type="primary" block onClick={handleRegister}>
                      Register
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <CleanButton icon="sign-in" type="link" block onClick={handleLogin}>
                      Log in
                    </CleanButton>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
      </Center>
    </>
  );
};

export default RegistrationForm;
