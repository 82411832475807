import React, { useEffect, useState } from "react";
import { Card, Carousel } from "antd";
import { Chart } from "react-google-charts";

const PayHistoryChart = ({ ...props }) => {
  const { payHistoryInfo } = props;
  const [payChartConfig, setPayChartConfig] = useState(null);
  const [withHoldingChartConfig, setWithHoldingChartConfig] = useState(null);

  useEffect(() => {
    if (payHistoryInfo?.pays && payHistoryInfo?.pays.length) {
      payChartConfiguration();
    }
    if (payHistoryInfo?.withholdings && payHistoryInfo?.withholdings.length) {
      withholdingChartConfiguration();
    }
  }, [payHistoryInfo]);

  const payChartConfiguration = () => {
    let tempPayTitles = {};
    payHistoryInfo.pays.map((eachData) => {
      if (!tempPayTitles[eachData.hoursCodeDisplay]) {
        tempPayTitles[eachData.hoursCodeDisplay] = 0;
      }
      tempPayTitles[eachData.hoursCodeDisplay] += eachData.amount;
    });
    const tempPayArray = [["Display Title", "Amount"]];
    for (const i in tempPayTitles) {
      tempPayArray.push([i, tempPayTitles[i]]);
    }
    setPayChartConfig(tempPayArray);
  };

  const withholdingChartConfiguration = () => {
    let tempWithHoldingTitles = {};
    payHistoryInfo.withholdings.map((eachData) => {
      if (!tempWithHoldingTitles[eachData.withholdingDisplay]) {
        tempWithHoldingTitles[eachData.withholdingDisplay] = 0;
      }
      tempWithHoldingTitles[eachData.withholdingDisplay] += eachData.amount;
    });
    let tempWithHoldingArray = [["Display Title", "Amount"]];
    for (const i in tempWithHoldingTitles) {
      tempWithHoldingArray.push([i, tempWithHoldingTitles[i]]);
    }
    setWithHoldingChartConfig(tempWithHoldingArray);
  };

  return (
    <Card>
      <Carousel autoplay={true} autoplaySpeed={2 * 60 * 1000} infinite={true}>
        <div>
          <div style={{ textAlign: "center" }}>
            <h2>Pay Breakdown</h2>
          </div>
          {payChartConfig && <Chart chartType="PieChart" data={payChartConfig} options={{ legend: "bottom", backgroundColor: "#F5F6F7" }} width="100%" height="400px" />}
        </div>
        <div>
          <div style={{ textAlign: "center" }}>
            <h2>Withholdings Breakdown</h2>
          </div>
          {withHoldingChartConfig && <Chart chartType="PieChart" data={withHoldingChartConfig} options={{ legend: "bottom", backgroundColor: "#F5F6F7" }} width="100%" height="400px" />}
        </div>
      </Carousel>
    </Card>
  );
};

export default PayHistoryChart;
