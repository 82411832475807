import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Result, Upload } from "antd";
import React from "react";
import BannerCard from "../../../common/components/BannerCard";

const UploadForm = ({ ...props }) => {
  const { onUpload } = props;

  return (
    <BannerCard title="Upload New Form">
      <Result status="info" title="Important Note" subTitle="Uploading a new file will replace any existing file already uploaded to this form." />
      <Upload.Dragger style={{ padding: "40px" }} name="file" multiple={false} customRequest={onUpload}>
        <p className="ant-upload-drag-icon">
          <FontAwesomeIcon icon={["fas", "upload"]} />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">Drag a file or click in this area to upload a file.</p>
      </Upload.Dragger>
    </BannerCard>
  );
};

export default UploadForm;
