import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, notification } from "antd";
import { RootState } from "./store";
import { setNetworkConnection } from "./state/networkStore";

const NoNetworkModal = () => {
  const { isConnectedToNet } = useSelector((state: RootState) => state.network);
  const dispatch = useDispatch();

  window.addEventListener("offline", (event) => {
    dispatch(setNetworkConnection(false));
  });

  window.addEventListener("online", (event) => {
    notification.success({ key: "online", maxCount: 1, message: "You're back online" });
    dispatch(setNetworkConnection(true));
  });

  return (
    <Modal visible={!isConnectedToNet} title="No Internet Connection" footer="" closable={false}>
      <hr style={{ marginTop: "-5%" }} />
      <span>You are offline. Please check your internet connection and try again.</span>
    </Modal>
  );
};

export default NoNetworkModal;
