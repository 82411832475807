import { Card, notification } from "antd";
import React, { useState } from "react";
import Page from "../../../common/components/Page";
import { useSaveCategoryMutation } from "../../../state/portalApi";
import useForms from "../useForms";
import ManageCategoryForm from "./manage-categories-form";

const ManageCatgories = () => {
  const { categories, categoryForm } = useForms();
  const [selectedCategory, setSelectedCategory] = useState<boolean>(false);
  const [formCategoryId, setFormCategoryId] = useState<number>(0);
  const [saveCategory] = useSaveCategoryMutation();

  const handleSetForm = (categoryId) => {
    const category = categories?.find((x) => x.formCategoryId === categoryId);
    categoryForm.setFieldsValue({ ...category, isDeleted: !category?.isDeleted });
    setSelectedCategory(true);
    setFormCategoryId(categoryId);
  };

  const handleReset = () => {
    categoryForm.resetFields();
    setSelectedCategory(false);
    setFormCategoryId(null);
  };

  const handleSaveCategory = async () => {
    const values = await categoryForm.validateFields();
    const value = { ...values, isDeleted: !values.isDeleted };

    const saveResponse = await saveCategory(value).unwrap();
    if (saveResponse.isSuccess) {
      notification.success({ message: "Category saved successfully" });
    } else {
      notification.error({ message: "Unable to save the record" });
    }
    categoryForm.resetFields();
  };

  return (
    <Page>
      <Page.Title title="Manage Categories" icon="file-archive" showBreadcrumb />
      <Page.Content>
        <Card style={{ maxWidth: "480px", margin: "left" }}>
          <ManageCategoryForm form={categoryForm} categories={categories} handleSetForm={handleSetForm} handleReset={handleReset} handleSaveCategory={handleSaveCategory} />
        </Card>
      </Page.Content>
    </Page>
  );
};

export default ManageCatgories;
