import React from "react";
import { Tabs } from "antd";
import Benefits from "../../benefits/benefits";
import LeaveTable from "../../leave/leave-table";
import Timecard from "../../timecard/timecard";
import EmployeeDetailSubstituteApprovers from "./employee-detail-substitute-approvers";
import EmployeeInfo from "./employee-info";

const EmployeeTabs = ({ ...props }) => {
  const { form, selectedEmployeeId, selectedEmployee } = props;

  return (
    <Tabs defaultActiveKey="1">
      <Tabs.TabPane tab="Employee Info" key={1}>
        <EmployeeInfo form={form} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Substitutes" key={2} disabled={!selectedEmployee?.isRegistered}>
        <EmployeeDetailSubstituteApprovers />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Timecard" key={3} disabled={!selectedEmployee?.isRegistered}>
        <Timecard fromEmployeeTimeCard selectedEmployeeId={selectedEmployeeId} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Leave" key={4}>
        <LeaveTable fromEmployeeLeave selectedEmployeeId={selectedEmployeeId} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Benefits" key={5}>
        <Benefits fromEmployeeBenefits selectedEmployeeId={selectedEmployeeId} />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default EmployeeTabs;
