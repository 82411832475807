import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Form, notification } from "antd";
import usePasswordPopover from "../../../common/hooks/use-password-popover";
import { AuthToken } from "../../../models/common/auth-token";
import { RegistrationRequest } from "../../../models/common/registration-request";
import { ServiceResponse } from "../../../models/common/service-response";
import { setToken } from "../../../state/globalStore";
import { useSaveRegistrationMutation } from "../../../state/portalApi";
import moment from "moment";
import { defaultISOFormat } from "../../../common/constants/helpers";

const useRegister = () => {
  const [form] = Form.useForm();
  const [saveRegistration] = useSaveRegistrationMutation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const { value, renderPopover } = usePasswordPopover("Password");
  const { value: confirmValue, renderPopover: confirmRender } = usePasswordPopover("Confirm Password");

  const handleLogin = () => history("/login");

  const handleRegister = async () => {
    const values = await form.validateFields();
    if (values) {
      const request: RegistrationRequest = { ...values, birthDate: moment(values.birthDate).format(defaultISOFormat), securityQuestionFK: 1 };

      const response: ServiceResponse<AuthToken> = await saveRegistration(request).unwrap();
      if (response.isSuccess) {
        dispatch(setToken(response.data));
        history("/dashboard");
        notification.success({ message: "Registration completed successfully." });
      } else {
        notification.error({ message: response.message });
      }
    }
  };

  return { form, history, handleLogin, handleRegister, renderPopover, confirmRender };
};

export default useRegister;
