import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, notification } from "antd";
import { RootState } from "../../store";
import { useGetEmployeeInfoQuery, useResetEmployeePasswordMutation, useUpdateEmailMutation, useValidateUserMutation } from "../../state/portalApi";
import { useNavigate } from "react-router";
import { setActiveEmployee, setToken } from "../../state/globalStore";

const useUserProfile = () => {
  const { activeEmployee, loggedInEmployeeId } = useSelector((state: RootState) => state.global);
  const [userForm] = Form.useForm();
  const [profilePic, setProfilePic] = useState("");
  const [resetPassword] = useResetEmployeePasswordMutation();
  const [validateUser, validateUserResponse] = useValidateUserMutation();
  const [updateEmail, updateEmailResponse] = useUpdateEmailMutation();
  const { data, isLoading } = useGetEmployeeInfoQuery(loggedInEmployeeId);
  const history = useNavigate();
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState<boolean>();

  useEffect(() => {
    userForm.setFieldsValue({
      profilePicture: activeEmployee?.firstName.slice(0, 1),
      FullName: activeEmployee?.fullName,
      email: activeEmployee?.email
    });
    setProfilePic(activeEmployee?.firstName.slice(0, 1));
  }, [activeEmployee, userForm]);

  const handleValidateUser = async (values) => {
    if (values) {
      const response = await validateUser({ username: localStorage.getItem("username"), lastFour: values.SSN }).unwrap();
      if (response.data) {
        return response?.data;
      } else {
        notification.error({ message: response.message });
        return false;
      }
    }
  };

  const handleChangePassword = async () => {
    const values = await userForm.validateFields();
    const vaildate = await handleValidateUser(values);
    if (values && vaildate && vaildate.userId) {
      const request = {
        userId: vaildate.userId,
        password: values.password,
        confirmPassword: values.confirmPassword
      };

      const response = await resetPassword(request).unwrap();
      if (response.data) {
        dispatch(setToken(response.data));
        notification.success({ message: "Password reset successfully." });
        history("/dashboard");
      } else {
        notification.error({ message: response.message });
      }
    }
  };

  const handleUpdateEmail = async () => {
    const values = await userForm.getFieldsValue();
    const response = await updateEmail({ employeeId: loggedInEmployeeId, email: values.email }).unwrap();
    const tempEmp = { ...activeEmployee };
    if (response.isSuccess) {
      notification.success({ message: "Email updated successfully" });
      tempEmp.email = values.email;
      dispatch(setActiveEmployee(tempEmp));
    } else {
      userForm.setFieldsValue({ email: activeEmployee.email });
      notification.error({ message: response.message });
    }
    setIsEdit(false);
  };

  return { userForm, handleChangePassword, profilePic, handleUpdateEmail, setIsEdit, isEdit };
};

export default useUserProfile;
