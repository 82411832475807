import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { List, Button, Table, Row, Col, Select, Avatar, Skeleton, Form } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import BannerCard from "../../../common/components/BannerCard";
import CleanButton from "../../../common/components/CleanButton";
import { RootState } from "../../../store";
import useEmployees from "./useEmployees";

const EmployeeDetailSubstituteApprovers = () => {
  const { substituteApprovers, selectedEmployee } = useSelector((state: RootState) => state.global);
  const { handleSetApprover, handleAddSub, handleRemoveSub } = useEmployees();
  const [form] = Form.useForm();

  const handleAddReset = async () => {
    await handleAddSub();
    form.resetFields();
  };

  return (
    <BannerCard title="Substitute Approvers">
      <br />
      <List
        dataSource={substituteApprovers ?? []}
        size="small"
        footer={
          <Form form={form}>
            <Row gutter={16}>
              <Col xl={14} lg={14} md={14} sm={18} xs={18}>
                <Form.Item name="substituteId">
                  <Select style={{ width: "100%" }} placeholder="Select a substitute approver" onChange={handleSetApprover}>
                    {React.Children.toArray(selectedEmployee?.subordinates?.map((sub) => <Select.Option value={sub?.employeeID}>{sub?.fullName}</Select.Option>))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={2} lg={2} md={4} sm={4} xs={4}>
                <Form.Item>
                  <Button type="primary" icon={<FontAwesomeIcon icon={["fas", "plus"]} />} onClick={handleAddReset} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        }
        renderItem={(o) => (
          <List.Item actions={[<CleanButton icon="trash" type="text" onClick={() => handleRemoveSub(o?.substituteEmployeeId)} />]} title={o?.fullName}>
            <List.Item.Meta avatar={<Avatar>{o?.firstName?.charAt(0) + o?.lastName?.charAt(0)}</Avatar>} title={o?.fullName} />
          </List.Item>
        )}
      />
    </BannerCard>
  );
};

export default EmployeeDetailSubstituteApprovers;
