import React from "react";
import { Card, Col, Popconfirm, Row, Select, Tree, Typography } from "antd";
import { Select as MSelect } from "@mantine/core";
import CleanButton from "../../common/components/CleanButton";
import Page from "../../common/components/Page";
import { ApprovalChain } from "../../models/approval-chain/approval-chain";
import { EmployeeMeta } from "../../models/employee/employee-meta";
import useApprovalChain from "./useApprovalChain";

const ApprovalChains = () => {
  const {
    activeChain,
    approvalChains,
    employees,
    employeesLoading,
    selectedDetailNode,
    handleCreateNewChain,
    handleSelectChain,
    handleSelectNode,
    handleClear,
    handleChangeTitle,
    handleSelectRootEmployee,
    handleSelectEmployee,
    handleSave,
    handleDelete,
    handleChainDelete,
    isExpand,
    handleExpand,
    handleSearchedEmployee,
    searchedEmployee
  } = useApprovalChain();

  return (
    <Page>
      <Page.Title title="Approval Chain" icon="sitemap" showBreadcrumb />
      <Page.Toolbar>
        <Row>
          {!activeChain && (
            <Col style={{ marginLeft: "5px", marginTop: "5px" }}>
              <CleanButton icon="plus" onClick={handleCreateNewChain}>
                Add
              </CleanButton>
            </Col>
          )}
          {activeChain && (
            <Col style={{ marginLeft: "5px", marginTop: "5px" }}>
              <CleanButton icon="save" onClick={handleSave}>
                Save
              </CleanButton>
            </Col>
          )}
          <Col style={{ marginLeft: "5px", marginTop: "5px" }}>
            <CleanButton icon="undo" onClick={handleClear}>
              Clear
            </CleanButton>
          </Col>
          {activeChain?.chainId && (
            <>
              <Col style={{ marginLeft: "5px", marginTop: "5px" }}>
                <Popconfirm title="Approval chain will be deleted" onConfirm={handleDelete}>
                  <CleanButton icon="trash">Delete</CleanButton>
                </Popconfirm>
              </Col>
              <Col style={{ marginLeft: "5px", marginTop: "5px" }}>
                <CleanButton icon={isExpand ? "compress" : "expand"} onClick={handleExpand}>
                  {isExpand ? "Collapse" : "Expand"}
                </CleanButton>
              </Col>
            </>
          )}
        </Row>
      </Page.Toolbar>
      <Page.Content>
        <Row gutter={[16, 16]}>
          <Col lg={8} md={8} sm={24} xs={24}>
            <Select value={activeChain?.chainId} placeholder="Please select an approval chain to view" style={{ width: "100%" }} onChange={handleSelectChain}>
              {React.Children.toArray(approvalChains?.data?.map((chain: ApprovalChain) => <Select.Option value={chain?.chainId}>{chain?.chainName}</Select.Option>))}
            </Select>
          </Col>
          {activeChain && (
            <Col span={24}>
              <Card
                title={
                  <Row>
                    <Col xl={16} lg={16} md={12} sm={24} xs={24}>
                      <Typography.Title level={4} editable={{ onChange: handleChangeTitle }}>
                        {activeChain?.chainName}
                      </Typography.Title>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                      <MSelect
                        placeholder="Select an employee to search"
                        onChange={handleSearchedEmployee}
                        searchable
                        clearable
                        radius="md"
                        data={employees?.map((emp: EmployeeMeta, index) => ({ value: index.toString(), label: emp?.employeeNumber + " - " + emp?.fullName }))}
                      />
                    </Col>
                  </Row>
                }
              >
                <Row gutter={[16, 16]}>
                  {!activeChain?.detail && (
                    <Col span={24}>
                      <MSelect
                        placeholder="Select a person to start the chain"
                        onChange={handleSelectRootEmployee}
                        searchable
                        clearable
                        radius="md"
                        data={employees?.map((emp: EmployeeMeta, index) => ({ value: index.toString(), label: emp?.employeeNumber + " - " + emp?.fullName }))}
                      />
                    </Col>
                  )}
                  {activeChain?.detail && (
                    <>
                      <Col span={12}>
                        <Tree
                          key={String(isExpand)}
                          treeData={[activeChain?.detail]}
                          defaultExpandAll={isExpand}
                          onSelect={handleSelectNode}
                          titleRender={(x) => (
                            <span>
                              {searchedEmployee && searchedEmployee === x.employeeNumber ? (
                                <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
                                  {x?.employeeNumber} - {x?.fullName}
                                </span>
                              ) : (
                                `${x?.employeeNumber} - ${x?.fullName}`
                              )}
                              {x?.approverEmployeeId && (
                                <Popconfirm title="Are you sure you want to delete?" onConfirm={() => handleChainDelete(selectedDetailNode)}>
                                  <CleanButton icon="trash" type="link" style={{ background: "transparent", marginTop: "-5%", marginBottom: "-5%" }} tooltip="Delete" />
                                </Popconfirm>
                              )}
                            </span>
                          )}
                        />
                      </Col>
                      {selectedDetailNode && (
                        <Col span={12}>
                          <MSelect
                            placeholder="Select an employee to add to this person"
                            onChange={handleSelectEmployee}
                            searchable
                            clearable
                            radius="md"
                            data={employees?.map((emp: EmployeeMeta, index) => ({ value: index.toString(), label: emp?.employeeNumber + " - " + emp?.fullName }))}
                          />
                        </Col>
                      )}
                    </>
                  )}
                </Row>
              </Card>
            </Col>
          )}
        </Row>
      </Page.Content>
    </Page>
  );
};

export default ApprovalChains;
