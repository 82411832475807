import { Col, Row } from "antd";
import React from "react";
import BannerCard from "../../common/components/BannerCard";
import Page from "../../common/components/Page";
import BenefitsCards from "./benefits-cards";
import Dependants from "./dependants";
import InsuranceCards from "./insurance-cards";

const Benefits = ({ ...props }) => {
  const { fromEmployeeBenefits, selectedEmployeeId } = props;
  return (
    <Page>
      {!fromEmployeeBenefits && <Page.Title title="Benefits" icon="heart" showBreadcrumb />}
      <Page.Content>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <BannerCard title="Benefits">
                <BenefitsCards fromEmployeeBenefits={fromEmployeeBenefits} selectedEmployeeId={selectedEmployeeId} />
              </BannerCard>
            </Col>
            <Col span={24}>
              <BannerCard title="Insurance">
                <InsuranceCards fromEmployeeBenefits={fromEmployeeBenefits} selectedEmployeeId={selectedEmployeeId} />
              </BannerCard>
            </Col>
            <Col span={24}>
              <BannerCard title="Dependents">
                <Dependants fromEmployeeBenefits={fromEmployeeBenefits} selectedEmployeeId={selectedEmployeeId} />
              </BannerCard>
            </Col>
          </Row>
        </Col>
      </Page.Content>
    </Page>
  );
};

export default Benefits;
