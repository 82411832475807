import React from "react";
import { Button, Input, Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface filterDropdownProps {
  setSelectedKeys: any;
  selectedKeys: any;
  confirm: any;
  clearFilters?: any;
}

const ColumnSearch = (dataIndex, title, resetFilters) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: filterDropdownProps) => (
    <div style={{ padding: 12 }}>
      <Input
        placeholder={`Search by ${title}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => confirm()}
        style={{ marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button type="primary" onClick={() => confirm()} icon={<FontAwesomeIcon icon={["fas", "search"]} />} size="small" style={{ width: 100 }}>
          Search
        </Button>
        <Button onClick={() => resetFilters(clearFilters, confirm)} size="small" style={{ width: 100 }}>
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: () => <FontAwesomeIcon icon={["fas", "search"]} />,
  onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "")
});

export default ColumnSearch;
