import React from "react";
import { Col, Row, Select } from "antd";
import CleanButton from "../../../common/components/CleanButton";
import { FormGroup } from "../../../models/forms/form-group";
import { Form } from "../../../models/forms/form";
import Page from "../../../common/components/Page";

const ManageFormToolbar = ({ ...props }) => {
  const { activeEmployee, handleSelectForm, forms, handleSaveForm, reset, selectedForm, handleArchived, handleUnArchived } = props;

  return (
    <Page.Toolbar>
      <Row>
        {activeEmployee?.isManager && !activeEmployee?.isAdmin ? (
          <Select style={{ width: "300px" }} allowClear onChange={handleSelectForm} placeholder="Select a form to edit">
            {React.Children.toArray(
              forms?.map((formGroup: FormGroup) => {
                return (
                  <Select.OptGroup label={formGroup?.name}>
                    {formGroup?.forms
                      .filter((x) => !x.isArchived)
                      .map((form: Form) => (
                        <Select.Option value={form?.formListId}>{form?.formName}</Select.Option>
                      ))}
                  </Select.OptGroup>
                );
              })
            )}
          </Select>
        ) : (
          <Select style={{ width: "300px" }} allowClear onChange={handleSelectForm} placeholder="Select a form to edit">
            {React.Children.toArray(
              forms?.map((formGroup: FormGroup) => {
                return (
                  <Select.OptGroup label={formGroup?.name}>
                    {formGroup?.forms.map((form: Form) => (
                      <Select.Option value={form?.formListId}>{form?.formName}</Select.Option>
                    ))}
                  </Select.OptGroup>
                );
              })
            )}
          </Select>
        )}
        <Col style={{ marginLeft: "5px", marginTop: "5px" }}>
          <CleanButton icon="save" onClick={handleSaveForm}>
            Save
          </CleanButton>
        </Col>
        <Col style={{ marginLeft: "5px", marginTop: "5px" }}>
          <CleanButton icon="undo" onClick={reset}>
            Reset
          </CleanButton>
        </Col>
        {selectedForm && selectedForm?.isArchived === false && (
          <Col style={{ marginLeft: "5px", marginTop: "5px" }}>
            <CleanButton icon="archive" onClick={handleArchived}>
              Archive
            </CleanButton>
          </Col>
        )}
        {selectedForm && selectedForm?.isArchived === true && activeEmployee?.isAdmin && (
          <Col style={{ marginLeft: "5px", marginTop: "5px" }}>
            <CleanButton icon="folder-open" onClick={handleUnArchived}>
              Unarchive
            </CleanButton>
          </Col>
        )}
      </Row>
    </Page.Toolbar>
  );
};

export default ManageFormToolbar;
