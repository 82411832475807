import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApprovalChain } from "../models/approval-chain/approval-chain";
import { ChainDetail } from "../models/approval-chain/approval-chain-detail";
import { ApprovalActionRequest } from "../models/approvals/approval-action-request";
import { DependentInsurance } from "../models/benefits/dependent-insturance";
import { AuthToken } from "../models/common/auth-token";
import { RegistrationRequest } from "../models/common/registration-request";
import { ServiceResponse } from "../models/common/service-response";
import { AddSubstituteRequest } from "../models/employee/add-substitute-request";
import { Employee } from "../models/employee/employee";
import { EmployeeMeta } from "../models/employee/employee-meta";
import { RoleChangeRequest } from "../models/employee/role-change-request";
import { Form } from "../models/forms/form";
import { FormAcknowledgementReport } from "../models/forms/form-report";
import { FormResponse } from "../models/forms/form-response";
import { FormUploadResponse } from "../models/forms/form-upload-response";
import { EmployeeTimeCard } from "../models/timecard/employee-timecard";
import { RootState } from "../store";

export const payrollPortalBaseUrl = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/PayPortalService2Proxy/api/";

export const portalApi = createApi({
  reducerPath: "portal",
  baseQuery: fetchBaseQuery({
    baseUrl: payrollPortalBaseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).global.token;
      // headers.set("Content-Type", "application/json");

      // Set Bearer to the token received from the login processes
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    }
  }),
  tagTypes: ["Approval", "FormList", "Timecards", "Chains", "EmployeeList", "ConfigurationList", "EmployeeInfo"],
  keepUnusedDataFor: 10,
  endpoints: (builder) => ({
    token: builder.mutation<ServiceResponse<AuthToken>, unknown>({
      query: (requestParams) => ({
        url: `Auth/token`,
        method: "POST",
        body: requestParams
      })
    }),
    getEmployeeInfo: builder.query<ServiceResponse<Employee>, unknown>({
      query: (employeeId) => ({
        url: `Employee?employeeId=${employeeId}`
      }),
      providesTags: ["EmployeeInfo"]
    }),
    getEmployeeLeave: builder.query({
      query: (employeeId) => ({
        url: `Employee/Leave?employeeId=${employeeId}`
      })
    }),
    getLeaveDetail: builder.query({
      query: ({ employeeId, leaveId }) => ({
        url: `Employee/Leave/Detail?employeeId=${employeeId}&accrualClassId=${leaveId}`
      })
    }),
    getBenefits: builder.query({
      query: (employeeId) => ({
        url: `Employee/Benefits?employeeId=${employeeId}`
      })
    }),
    getDependentDetails: builder.query<DependentInsurance[], unknown>({
      query: ({ employeeId, dependentId }) => ({
        url: `Employee/Benefits/Dependent/Detail?employeeId=${employeeId}&dependentId=${dependentId}`
      })
    }),
    getInventory: builder.query({
      query: (employeeId) => ({
        url: `Employee/Inventory?employeeId=${employeeId}`
      })
    }),
    getTimecards: builder.query({
      query: ({ employeeId, isCalledFromEmp = false }) => ({
        url: `Timecard/Tab?employeeId=${employeeId}&isCalledFromEmp=${isCalledFromEmp}`
      }),
      providesTags: ["Timecards"]
    }),
    getTimecardInfo: builder.mutation<EmployeeTimeCard, unknown>({
      query: ({ employeeId, timePeriodId, isFk }) => ({
        url: `Timecard?employeeId=${employeeId}&timePeriodId=${timePeriodId}&isFk=${isFk}`,
        method: "GET"
      })
    }),
    getPayHistory: builder.query({
      query: (employeeId) => ({
        url: `Employee/PayHistory?employeeId=${employeeId}`
      })
    }),
    getPayHistoryData: builder.mutation({
      query: ({ employeeId, checkId }) => ({
        url: `Employee/PayHistory?employeeId=${employeeId}&payHistoryId=${checkId}`
      })
    }),
    getForms: builder.query<ServiceResponse<FormResponse>, unknown>({
      query: (employeeId) => ({
        url: `Form/ByEmployee?employeeId=${employeeId}`
      }),
      providesTags: ["FormList"]
    }),
    saveForm: builder.mutation<ServiceResponse<number>, Form>({
      query: (form) => ({
        url: `Form/Save`,
        method: "POST",
        body: form
      }),
      invalidatesTags: ["FormList"]
    }),
    uploadForm: builder.mutation<ServiceResponse<FormUploadResponse>, unknown>({
      query: (request) => ({
        url: `Form`,
        method: "POST",
        body: request
      }),
      invalidatesTags: ["FormList"]
    }),
    getAcknowledgements: builder.query<ServiceResponse<FormAcknowledgementReport>, unknown>({
      query: (activeOnly) => ({
        url: `Form/Acknowledgements/Report?activeOnly=${activeOnly}`
      })
    }),
    getEmployeeList: builder.query<EmployeeMeta[], unknown>({
      query: () => ({
        url: `Employee/List`
      }),
      providesTags: ["EmployeeList"]
    }),
    getActiveEmployeeInfo: builder.mutation<ServiceResponse<Employee>, unknown>({
      query: ({ employeeId, isCalledFromEmp = false }) => ({
        url: `Employee?employeeId=${employeeId}&isCalledFromEmp=${isCalledFromEmp}`,
        method: "GET"
      })
    }),
    saveTimecard: builder.mutation<ServiceResponse, EmployeeTimeCard>({
      query: (timecard) => ({
        url: `Timecard`,
        method: "POST",
        body: timecard
      }),
      invalidatesTags: ["Timecards"]
    }),
    makeApprovalAction: builder.mutation<ServiceResponse, ApprovalActionRequest>({
      query: (request) => ({
        url: `Timecard/Action`,
        method: "POST",
        body: request
      }),
      invalidatesTags: ["Approval", "Timecards"]
    }),
    getApprovalChains: builder.query<ServiceResponse<ApprovalChain[]>, unknown>({
      query: () => ({
        url: `Timecard/ChainList`
      }),
      providesTags: ["Chains"]
    }),
    getApprovalChainDetail: builder.mutation<ServiceResponse<ChainDetail[]>, number>({
      query: (chainId) => ({
        url: `Timecard/Chain/Detail/ByChain?chainId=${chainId}`,
        method: "GET"
      })
    }),
    deleteApprovalChain: builder.mutation<ServiceResponse, number>({
      query: (chainId) => ({
        url: `Timecard/DeleteChain?chainId=${chainId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Chains"]
    }),
    saveApprovalChain: builder.mutation<ServiceResponse, ApprovalChain>({
      query: (chain) => ({
        url: "Timecard/SaveChain",
        method: "POST",
        body: chain
      }),
      invalidatesTags: ["Chains"]
    }),
    getApprovals: builder.query({
      query: (employeeId) => ({
        url: `Timecard/ApprovalList?employeeId=${employeeId}`
      }),
      providesTags: ["Approval"]
    }),
    saveRegistration: builder.mutation<ServiceResponse<AuthToken>, RegistrationRequest>({
      query: (request) => ({
        url: `Auth/register`,
        method: "POST",
        body: request
      })
    }),
    validateUser: builder.mutation<ServiceResponse<any>, unknown>({
      query: ({ username, lastFour }) => ({
        url: `Auth/User/ByNameAndSocial?username=${username}&lastFour=${lastFour}`,
        method: "GET"
      })
    }),
    resetEmployeePassword: builder.mutation({
      query: (request) => ({
        url: `Auth/reset`,
        method: "POST",
        body: request
      })
    }),
    saveCategory: builder.mutation({
      query: (form) => ({
        url: `Form/Category`,
        method: "POST",
        body: form
      }),
      invalidatesTags: ["FormList"]
    }),
    changeRole: builder.mutation<ServiceResponse, RoleChangeRequest>({
      query: (request) => ({
        url: `Employee/ChangeRole`,
        method: "POST",
        body: request
      }),
      invalidatesTags: ["EmployeeList"]
    }),
    getEmployeeSubsitutes: builder.mutation({
      query: (employeeId) => ({
        url: `Employee/Substitutes?employeeId=${employeeId}`,
        method: "GET"
      })
    }),
    addSubsitute: builder.mutation<ServiceResponse, AddSubstituteRequest>({
      query: (request) => ({
        url: `Employee/Substitutes`,
        method: "POST",
        body: request
      })
    }),
    removeSubsitute: builder.mutation({
      query: (request) => ({
        url: `Employee/Substitutes`,
        method: "DELETE",
        body: request
      })
    }),
    getAllConfigOptions: builder.query({
      query: () => ({
        url: `Config/AllOptions`
      }),
      providesTags: ["ConfigurationList"]
    }),
    getPaycodesById: builder.mutation({
      query: (payCodeId) => ({
        url: `Timecard/Paycodes/ByDepartment?departmentId=${payCodeId}`,
        method: "GET"
      })
    }),
    savePaycodes: builder.mutation({
      query: (payCodes) => ({
        url: `Timecard/Paycodes`,
        method: "POST",
        body: payCodes
      })
    }),
    updateConfigOption: builder.mutation({
      query: (request) => ({
        url: `Config`,
        method: "PUT",
        body: request
      }),
      invalidatesTags: ["ConfigurationList"]
    }),
    updateEmail: builder.mutation({
      query: ({ employeeId, email }) => ({
        url: `Auth/UpdateEmail?employeeId=${employeeId}&email=${email}`,
        method: "PUT"
      }),
      invalidatesTags: ["EmployeeInfo"]
    }),
    getUsernameByEmail: builder.mutation({
      query: (email) => ({
        url: `Auth/GetUsernameByEmail?email=${email}`,
        method: "GET"
      })
    }),
    employeeFormAcknowledgement: builder.mutation({
      query: ({ employeeId, formId }) => ({
        url: `Form/EmployeeFormAcknowledgement?employeeId=${employeeId}&formId=${formId}`,
        method: "PUT"
      }),
      invalidatesTags: ["FormList"]
    }),
    printPayStub: builder.mutation({
      query: (historyFk) => ({
        url: `Form/GetEmployeePayStub?historyFk=${historyFk}`,
        method: "GET"
      })
    }),
    deleteRegistration: builder.mutation({
      query: (employeeId) => ({
        url: `Auth/ResetUserRegistration?employeeId=${employeeId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["EmployeeList"]
    }),
    timeperiodsByDays: builder.query({
      query: (days) => ({
        url: `/Timecard/TimePeriodsByDays?days=${days}`,
        method: "GET"
      })
    }),
    getApprovalListCount: builder.query({
      query: (empId) => ({
        url: `Timecard/ApprovalListCount?employeeId=${empId}`,
        method: "GET"
      })
    })
  })
});

export const {
  useTokenMutation,
  useGetEmployeeInfoQuery,
  useLazyGetEmployeeInfoQuery,
  useGetEmployeeLeaveQuery,
  useGetLeaveDetailQuery,
  useGetBenefitsQuery,
  useGetDependentDetailsQuery,
  useGetInventoryQuery,
  useGetTimecardsQuery,
  useGetTimecardInfoMutation,
  useGetPayHistoryQuery,
  useGetPayHistoryDataMutation,
  useGetFormsQuery,
  useUploadFormMutation,
  useGetAcknowledgementsQuery,
  useGetEmployeeListQuery,
  useGetActiveEmployeeInfoMutation,
  useSaveTimecardMutation,
  useMakeApprovalActionMutation,
  useGetApprovalChainsQuery,
  useGetApprovalChainDetailMutation,
  useGetApprovalsQuery,
  useDeleteApprovalChainMutation,
  useSaveRegistrationMutation,
  useResetEmployeePasswordMutation,
  useSaveCategoryMutation,
  useSaveFormMutation,
  useSaveApprovalChainMutation,
  useValidateUserMutation,
  useChangeRoleMutation,
  useGetEmployeeSubsitutesMutation,
  useAddSubsituteMutation,
  useRemoveSubsituteMutation,
  useGetAllConfigOptionsQuery,
  useGetPaycodesByIdMutation,
  useSavePaycodesMutation,
  useUpdateConfigOptionMutation,
  useUpdateEmailMutation,
  useGetUsernameByEmailMutation,
  useEmployeeFormAcknowledgementMutation,
  usePrintPayStubMutation,
  useDeleteRegistrationMutation,
  useTimeperiodsByDaysQuery,
  useGetApprovalListCountQuery
} = portalApi;
