import { Collapse, Descriptions, Empty } from "antd";
import React from "react";
import BannerCard from "../../common/components/BannerCard";
import { formattedDate } from "../../common/constants/helpers";
import useBenefits from "./useBenefits";

const Dependants = ({ ...props }) => {
  const { fromEmployeeBenefits, selectedEmployeeId } = props;
  const { data, detail, isLoading, handleSelectDependant } = useBenefits(fromEmployeeBenefits, selectedEmployeeId);

  return data?.dependents?.length > 0 ? (
    <Collapse accordion onChange={handleSelectDependant}>
      {React.Children.toArray(
        data?.dependents.map((dep) => {
          return (
            <Collapse.Panel key={dep?.personId} header={dep?.fullName}>
              {React.Children.toArray(
                detail?.map((ins) => {
                  return (
                    <BannerCard internal hideTitle title="">
                      <Descriptions title={ins?.insuranceType + " - " + ins?.insuranceDescription} column={1}>
                        <Descriptions.Item label="Description">{ins?.insuranceDescription}</Descriptions.Item>
                        <Descriptions.Item label="Effective Date">{formattedDate(ins?.effectiveDate)}</Descriptions.Item>
                        <Descriptions.Item label="Expiration Date">{formattedDate(ins?.expirationDate)}</Descriptions.Item>
                      </Descriptions>
                    </BannerCard>
                  );
                })
              )}
            </Collapse.Panel>
          );
        })
      )}
    </Collapse>
  ) : (
    <Empty description="No dependents exist." />
  );
};

export default Dependants;
