import React from "react";
import { Col, Row, Typography } from "antd";
import BreadCrumbs from "../navigation/breadcrumb";

const Style = {
  marginRight: "1rem",
  marginBottom: "1rem",
  fontSize: "40px",
  backgroundColor: "#e9ecef",
  paddingLeft: "1rem",
  // paddingRight: '1rem',
  paddingTop: "0.25rem",
  paddingBottom: "0.25rem",
  borderRadius: "0.75rem"
};

const PageTitle = ({ ...props }) => {
  const { isMobile } = props;

  return (
    <Row justify="space-between" align="middle">
      <Col>
        <div className="erp-page-title" style={Style}>
          {props.icon}
        </div>
      </Col>
      <Col>
        <Row gutter={[0, 0]}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Typography.Title style={{ marginTop: "-16px" }} level={!isMobile ? 5 : 2}>
              {props.title}
            </Typography.Title>
          </Col>
          {props.showBreadcrumb && !props.subtitle && (
            <div style={{ marginTop: "8px" }}>
              <Col lg={24} md={24} sm={24} xs={24}>
                <BreadCrumbs />
              </Col>
            </div>
          )}
          {props.subtitle && (
            <Col lg={24} md={24} sm={24} xs={24}>
              <Typography.Title style={{ marginTop: "-20px", color: "gray" }} level={!isMobile ? 5 : 3}>
                {props.subtitle}
              </Typography.Title>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default PageTitle;
