import { Table } from "antd";
import React from "react";
import BannerCard from "../../common/components/BannerCard";
import useDashboard from "./useDashboard";

const DashboardTaxes = () => {
  const { data, taxColumns, loading, newLayoutTaxColumns, newLayout } = useDashboard();

  return (
    <BannerCard title="Tax Information">
      {newLayout && <Table loading={loading} size="small" dataSource={data?.taxes} columns={newLayoutTaxColumns} scroll={{ x: 480 }} />}
      <Table loading={loading} size="small" dataSource={data?.taxes} columns={taxColumns} scroll={{ x: 480 }} />
    </BannerCard>
  );
};

export default DashboardTaxes;
