import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Row, Col } from "antd";
import { useMediaQuery } from "@mantine/hooks";
import CleanButton from "../../common/components/CleanButton";
import Page from "../../common/components/Page";
import { RootState } from "../../store";
import getConfigs from "../getConfigs";
import FormList from "./form-list";
import useForms from "./useForms";

const Forms = () => {
  const { forms, handleManageForms, handleAcknowledgeList } = useForms();
  const { activeEmployee } = useSelector((state: RootState) => state.global);
  const navigate = useNavigate();
  const mobileView = useMediaQuery("(max-width:768px)");
  const handleManageCategories = () => navigate("/forms/manage-categories");
  const configurations = getConfigs(["ManagerCanAddForm", "ManagersCanViewAcknowledgements", "ManagerCanAddCategory"]);

  return (
    <Page>
      <Page.Title title="Forms" icon="file-alt" showBreadcrumb />
      <Page.Toolbar>
        <Row>
          {((activeEmployee?.isManager && configurations.ManagerCanAddForm?.toLowerCase() === "true") || activeEmployee?.isAdmin) && (
            <Col style={{ marginLeft: "5px", marginTop: "5px", width: mobileView ? "100%" : "auto" }}>
              <CleanButton icon="file-alt" onClick={handleManageForms} style={{ width: mobileView ? "100%" : "auto" }}>
                Manage Forms
              </CleanButton>
            </Col>
          )}
          {((activeEmployee?.isManager && configurations.ManagersCanViewAcknowledgements?.toLowerCase() === "true") || activeEmployee?.isAdmin) && (
            <Col style={{ marginLeft: "5px", marginTop: "5px", width: mobileView ? "100%" : "auto" }}>
              <CleanButton icon="bell" onClick={handleAcknowledgeList} style={{ width: mobileView ? "100%" : "auto" }}>
                Acknowledgements
              </CleanButton>
            </Col>
          )}
          {((activeEmployee?.isManager && configurations.ManagerCanAddCategory?.toLowerCase() === "true") || activeEmployee?.isAdmin) && (
            <Col style={{ marginLeft: "5px", marginTop: "5px", width: mobileView ? "100%" : "auto" }}>
              <CleanButton icon="file-archive" onClick={handleManageCategories} style={{ width: mobileView ? "100%" : "auto" }}>
                Manage Categories
              </CleanButton>
            </Col>
          )}
        </Row>
      </Page.Toolbar>
      <Page.Content>
        <FormList forms={forms} />
      </Page.Content>
    </Page>
  );
};

export default Forms;
