import React from "react";
import { Form, InputNumber, Modal, Select, Space, Typography } from "antd";

const ConfigModal = ({ ...props }) => {
  return (
    <Modal visible={props.showModal} okText="Save" title="Update Configuration" onOk={props.handleSave} onCancel={props.handleCancel}>
      <Space direction="vertical">
        <Typography.Text>
          <b>Group:</b> {props.editObject.optionGroup}
        </Typography.Text>
        <Typography.Text>
          <b>Option:</b> {props.editObject.configOption1}
        </Typography.Text>
        <Typography.Text>
          <b>Description:</b> {props.editObject.optionDescription}
        </Typography.Text>
        <Form form={props.modalForm}>
          <Form.Item
            name="value"
            label={
              <Typography.Text style={{ fontSize: "0.875rem" }}>
                <b>Update Value</b>
              </Typography.Text>
            }
          >
            {props.editObject.optionType === "bool" ? (
              <Select style={{ width: "40%" }}>
                <Select.Option value="true">Enabled</Select.Option>
                <Select.Option value="false">Disabled</Select.Option>
              </Select>
            ) : props.editObject.optionType === "int" ? (
              <InputNumber />
            ) : null}
          </Form.Item>
        </Form>
      </Space>
    </Modal>
  );
};

export default ConfigModal;
