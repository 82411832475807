import React, { useEffect, useState } from "react";
import { Checkbox, Form, FormInstance, Input, Select } from "antd";
import BannerCard from "../../../common/components/BannerCard";
import { FormCategory } from "../../../models/forms/form-category";
import { FormDepartment } from "../../../models/forms/form-department";
import { FormType, FormTypes } from "../useForms";
import { FormGroup } from "../../../models/forms/form-group";

type Props = {
  formForm: FormInstance;
  categories: FormCategory[];
  departments: FormDepartment[];
  defaultType: FormTypes;
  handleSelectForm: (e) => void;
  forms: FormGroup[];
  handleFormType: (e) => void;
  defaultFormType: FormType;
  ackMessage: boolean;
  setAckMessage: (e) => void;
};
const ManageFormsForm = ({ ...props }: Props) => {
  const { formForm, categories, departments, defaultType, ackMessage, setAckMessage, handleFormType, defaultFormType } = props;
  const [selectedType, setSelectedType] = useState<FormTypes>(defaultType);
  // const [ackMessage, setAckMessage] = useState<boolean>(false);

  // TK: 1/27/22 - A bug exists here where the state is not updating on the form instance
  // causing the dropdowns to render incorrectly. @TODO
  // AK: 02/03/22 - Bug of causing the dropdown is fixed

  useEffect(() => {
    if (defaultType === FormTypes.Category) {
      setSelectedType(FormTypes.Category);
    } else {
      setSelectedType(FormTypes.Department);
    }
    formForm.setFieldsValue({
      formCatDep: defaultType
    });
  }, [defaultType, formForm]);

  const handleChange = (type) => {
    if (type === FormTypes.Category) {
      formForm.setFieldsValue({
        formCategoryId: null
      });
    } else {
      formForm.setFieldsValue({
        formDepartmentId: null
      });
    }
    setSelectedType(type);
  };

  const handleSelectLocked = (e) => {
    e.preventDefault();
    const val = e.target.checked;
    if (val) {
      setAckMessage(val);
    } else {
      setAckMessage(val);
    }
  };

  return (
    <BannerCard title="Form Data">
      <Form form={formForm} layout="vertical" initialValues={{ requiresAcknowledgement: false }}>
        <Form.Item name="formName" label="Form Name" rules={[{ required: true }]}>
          <Input max={250} />
        </Form.Item>
        <Form.Item name="formDescription" label="Form Description" rules={[{ required: true }]}>
          <Input.TextArea rows={6} maxLength={2000} showCount />
        </Form.Item>
        <Form.Item name="formCatDep" label="Type" rules={[{ required: true }]}>
          <Select placeholder="Select a type" onChange={handleChange}>
            <Select.Option value={FormTypes.Category}>Category</Select.Option>
            <Select.Option value={FormTypes.Department}>Department</Select.Option>
          </Select>
        </Form.Item>
        {selectedType === FormTypes.Category && (
          <Form.Item name="formCategoryId" label="Form Category" rules={[{ required: selectedType === FormTypes.Category }]}>
            <Select>{React.Children.toArray(categories?.map((category: FormCategory) => <Select.Option value={category?.formCategoryId}>{category?.categoryName}</Select.Option>))}</Select>
          </Form.Item>
        )}
        {selectedType === FormTypes.Department && (
          <Form.Item name="departmentId" label="Form Department" rules={[{ required: selectedType === FormTypes.Department }]}>
            <Select>
              {React.Children.toArray(departments?.map((department: FormDepartment) => <Select.Option value={department?.departmentId}>{department?.departmentDescription}</Select.Option>))}
            </Select>
          </Form.Item>
        )}
        <Form.Item name="formType" label="Form Type">
          <Select placeholder="Select a form type" onChange={handleFormType}>
            <Select.Option value={FormType.LOCAL}>Local</Select.Option>
            <Select.Option value={FormType.HOST}>URL</Select.Option>
          </Select>
        </Form.Item>
        {defaultFormType && defaultFormType === FormType.HOST && (
          <Form.Item name="formURLLink" label="Form URL" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        )}
        <Form.Item name="requiresAcknowledgement" valuePropName="checked">
          <Checkbox onChange={handleSelectLocked}>Requires Acknowledgement</Checkbox>
        </Form.Item>
        {ackMessage && (
          <Form.Item name="ackMessage" label="Acknowledgement Message" rules={[{ required: true }]}>
            <Input.TextArea rows={3} />
          </Form.Item>
        )}
      </Form>
    </BannerCard>
  );
};

export default ManageFormsForm;
