import React from "react";
import logo2 from "../../../../assets/logo2.png";
import RegistrationForm from "./RegistrationForm";
import useRegister from "./useRegister";

const Register = () => {
  const { form, handleLogin, handleRegister } = useRegister();

  return <RegistrationForm form={form} logo2={logo2} existingAccount={handleLogin} handleRegister={handleRegister} />;
};

export default Register;
