import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, Row } from "antd";
import Page from "../../../common/components/Page";
import { buildAddress, viewDate } from "../../../common/constants/helpers";
import { RootState } from "../../../store";
import EmployeeDetailSummary from "./employee-detail-summary";
import EmployeeTabs from "./employee-tabs";
import { setSelectedEmployee } from "../../../state/globalStore";

const EmployeeDetail = () => {
  const [form] = Form.useForm();
  const { selectedEmployee } = useSelector((state: RootState) => state.global);
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue({
      ...selectedEmployee,
      startDate: viewDate(selectedEmployee?.startDate),
      fullAddress: buildAddress(selectedEmployee?.addresses[0]),
      email: selectedEmployee?.emails[0]?.emailAddress ?? "N/A",
      phone: selectedEmployee?.phones[0]?.number ?? "N/A"
    });
  }, [form, selectedEmployee]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedEmployee(null));
    };
  }, []);

  return (
    <Page>
      <Page.Title title="Employee Information" icon="user" />
      <Page.Content>
        <Row gutter={8}>
          <Col xl={5} lg={5} md={8} sm={24} xs={24}>
            <EmployeeDetailSummary activeEmployee={selectedEmployee} />
          </Col>
          <Col xl={19} lg={19} md={16} sm={24} xs={24}>
            <EmployeeTabs form={form} selectedEmployeeId={selectedEmployee?.employeeId} selectedEmployee={selectedEmployee} />
          </Col>
        </Row>
      </Page.Content>
    </Page>
  );
};

export default EmployeeDetail;
