import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Button, Input, Modal, Space, Steps, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TimecardStatus } from "../../common/constants/helpers";
import { TimecardActions } from "../../models/timecard/timecard-actions.enum";
import { useGetApprovalsQuery, useGetEmployeeLeaveQuery, useTimeperiodsByDaysQuery, useMakeApprovalActionMutation } from "../../state/portalApi";
import { RootState } from "../../store";
import getConfigs from "../getConfigs";

const useTimecardApproval = () => {
  const [entriesData, setEntriesData] = useState<[]>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isLeaveModalVisible, setIsLeaveModalVisible] = useState<boolean>(false);
  const [empFK, setEmpFK] = useState<number>(0);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<number>(0);
  const [approvalData, setApprovalData] = useState({ directApprovals: [], altApprovals: [], subApprovals: [] });
  const [toggleValue, setToggleValue] = useState<boolean>(false);
  const { loggedInEmployeeId } = useSelector((state: RootState) => state.global);
  const { data, isLoading } = useGetApprovalsQuery(loggedInEmployeeId);
  const [makeApprovalAction] = useMakeApprovalActionMutation();
  const getCurrentStep = (actions) => actions?.filter((o) => o.IsNext)[0]?.chainOrder - 1 ?? 0;
  const configurations = getConfigs(["ShowTimecardLocation"]);
  let rejectNote = "";
  const { data: leaveData, isLoading: leaveLoading } = useGetEmployeeLeaveQuery(empFK, { refetchOnMountOrArgChange: true });
  const { data: timePeriodsList } = useTimeperiodsByDaysQuery(90);

  useEffect(() => {
    if (!leaveLoading && leaveData.length) {
      setEntriesData(leaveData);
    }
  }, [leaveData, leaveLoading]);

  useEffect(() => {
    setApprovalData(data);
  }, [data]);

  useEffect(() => {
    filterData();
  }, [selectedTimePeriod, toggleValue]);

  const filterData = () => {
    let tempApprovalData = { directApprovals: [], altApprovals: [], subApprovals: [] };
    if (!selectedTimePeriod && !toggleValue) {
      setApprovalData(data);
      return;
    }
    data.directApprovals.forEach((eachData) => {
      if (selectedTimePeriod && toggleValue) {
        if (eachData.timePeriodFK === selectedTimePeriod && eachData.statusFK === TimecardStatus.Approved) {
          tempApprovalData.directApprovals.push(eachData);
        }
      } else if (selectedTimePeriod) {
        if (eachData.timePeriodFK === selectedTimePeriod) {
          tempApprovalData.directApprovals.push(eachData);
        }
      } else if (toggleValue) {
        // added  TimecardStatus.None for initial check need to upated or remove later
        if (eachData.statusFk === TimecardStatus.Approved || TimecardStatus.None) {
          tempApprovalData.directApprovals.push(eachData);
        }
      }
    });
    data.altApprovals.forEach((eachData) => {
      if (selectedTimePeriod && toggleValue) {
        if (eachData.timePeriodFK === selectedTimePeriod && eachData.statusFK === TimecardStatus.Approved) {
          tempApprovalData.altApprovals.push(eachData);
        }
      } else if (selectedTimePeriod) {
        if (eachData.timePeriodFK === selectedTimePeriod) {
          tempApprovalData.altApprovals.push(eachData);
        }
      } else if (toggleValue) {
        // added  TimecardStatus.None for initial check need to upated or remove later
        if (eachData.statusFk === TimecardStatus.Approved || TimecardStatus.None) {
          tempApprovalData.altApprovals.push(eachData);
        }
      }
    });
    data.subApprovals.forEach((eachData) => {
      if (selectedTimePeriod && toggleValue) {
        if (eachData.timePeriodFK === selectedTimePeriod && eachData.statusFK === TimecardStatus.Approved) {
          tempApprovalData.subApprovals.push(eachData);
        }
      } else if (selectedTimePeriod) {
        if (eachData.timePeriodFK === selectedTimePeriod) {
          tempApprovalData.subApprovals.push(eachData);
        }
      } else if (toggleValue) {
        // added  TimecardStatus.None for initial check need to upated or remove later
        if (eachData.statusFk === TimecardStatus.Approved || TimecardStatus.None) {
          tempApprovalData.subApprovals.push(eachData);
        }
      }
    });
    setApprovalData(tempApprovalData);
  };

  const handleSetEntriesData = (row) => {
    setEntriesData(row?.entries);
    setIsModalVisible(true);
  };

  const handleSetLeavesData = async (row) => {
    setEmpFK(row.employeeFK);
    setIsLeaveModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setIsLeaveModalVisible(false);
    setEntriesData([]);
    setEmpFK(0);
  };

  const hanldeIcon = (link) => {
    if (link.isNext) {
      return (
        <Tooltip title="Awaiting Approval">
          <FontAwesomeIcon color="grey" icon={["fas", "hashtag"]} />
        </Tooltip>
      );
    } else if (link.actionId === 0 || link.actionId === 5 || link.actionId === 6) {
      return (
        <Tooltip title="No Action">
          <FontAwesomeIcon style={{ color: "grey" }} icon={["fas", "ban"]} />
        </Tooltip>
      );
    } else if (link.actionId === 2) {
      return (
        <Tooltip title="Timecard Submitted">
          <FontAwesomeIcon style={{ color: "red" }} icon={["fas", "check"]} />
        </Tooltip>
      );
    } else if (link.actionId === 4) {
      return (
        <Tooltip title="Approver Rejected">
          <FontAwesomeIcon style={{ color: "red" }} icon={["fas", "xmark"]} />
        </Tooltip>
      );
    } else if (link.actionId === 3) {
      return (
        <Tooltip title="Approver Approved">
          <FontAwesomeIcon color="red" icon={["fas", "check"]} />
        </Tooltip>
      );
    }
  };

  const renderStepper = (actions) => {
    return <Steps current={getCurrentStep(actions)}>{React.Children.toArray(actions?.map((action) => <Steps.Step title={action?.firstName} icon={hanldeIcon(action)} />))}</Steps>;
  };

  const takeAction = async (actionKey, actionValue, employeeTimecardId) => {
    const action = Number(actionKey);

    const handleRejectNote = (e) => {
      rejectNote = e.target.value;
    };

    const actionRequest = {
      timecardId: employeeTimecardId,
      action: action,
      note: "",
      actionEmployeeId: loggedInEmployeeId
    };

    if (action === TimecardActions.Reject) {
      Modal.confirm({
        title: <Input onChange={handleRejectNote} placeholder="Rejection note" />,
        onOk: async () => {
          actionRequest.note = rejectNote;
          await makeApprovalAction(actionRequest).unwrap();
        },
        onCancel: () => {},
        bodyStyle: {}
      });
    } else {
      await makeApprovalAction(actionRequest).unwrap();
    }
  };

  const renderActionButtons = (actions, approvalEntry) => {
    return (
      <Space direction="vertical">
        {Object.entries(actions)?.map(([k, v]) => (
          <Button type="primary" block onClick={() => takeAction(k, v, approvalEntry?.employeeTimecardId)}>
            {v}
          </Button>
        ))}
      </Space>
    );
  };

  const columns = [
    { key: "actions", dataIndex: "availableActions", render: (actions, row) => renderActionButtons(actions, row) },
    { key: "name", dataIndex: "fullName" },
    {
      key: "laborHours",
      dataIndex: "hours",
      render: (hours, row) => (
        <Button type="text" icon={<FontAwesomeIcon icon={["fas", "briefcase"]} />} onClick={() => handleSetEntriesData(row)}>
          {hours + " hrs."}
        </Button>
      )
    },
    {
      key: "leaves",
      render: (hours, row) => (
        <Button type="text" icon={<FontAwesomeIcon icon={["fas", "calendar"]} />} onClick={() => handleSetLeavesData(row)}>
          Leaves
        </Button>
      )
    },
    {
      key: "actions",
      dataIndex: "actions",
      render: (actions) => {
        const tempAction = [...actions];
        return renderStepper(tempAction);
      }
    }
  ];

  const modalColumns = [
    { key: "workDate", dataIndex: "workDate", title: "Work Date", render: (val) => moment(val).format("MM-DD-YYYY") },
    { key: "startTime", dataIndex: "startTime", title: "Start Time", render: (val) => moment(val).format("hh:mm A") },
    { key: "endTime", dataIndex: "endTime", title: "End Time", render: (val) => moment(val).format("hh:mm A") },
    { key: "payCodeDescription", dataIndex: "payCodeDescription", title: "Code" },
    {
      key: "locationName",
      dataIndex: configurations.ShowTimecardLocation?.toLowerCase() === "true" ? "locationName" : null,
      title: configurations.ShowTimecardLocation?.toLowerCase() === "true" ? "Location" : null
    },
    { key: "laborHours", dataIndex: "laborHours", title: "Hours", render: (val) => val + " hrs." },
    { key: "deductLunch", dataIndex: "deductLunch", title: "Lunch", render: (val) => (val === true ? "Yes" : "No") },
    { key: "note", dataIndex: "note", title: "Note" }
  ];

  const leaveModalColumns = [
    { key: "accrualClassDescription", dataIndex: "accrualClassDescription", title: "Accrual Description" },
    { key: "accrualEarnedYTD", dataIndex: "accrualEarnedYTD", title: "Earned" },
    { key: "accrualUsedYTD", dataIndex: "accrualUsedYTD", title: "Used" },
    { key: "accruedBalance", dataIndex: "accruedBalance", title: "Balance" },
    { key: "maxHours", dataIndex: "maxHours", title: "Hours", render: (val) => val + " hrs." }
  ];

  const handleSelectTimecard = (timePeriodId: number) => {
    setSelectedTimePeriod(timePeriodId);
  };

  const handleClearTimecard = () => {
    setSelectedTimePeriod(0);
  };

  const handleApprovedFilter = (toggleCheck) => {
    setToggleValue(toggleCheck);
  };

  return {
    data: approvalData,
    isLoading,
    columns,
    entriesData,
    setIsModalVisible,
    isModalVisible,
    handleCloseModal,
    modalColumns,
    handleSetEntriesData,
    renderStepper,
    isLeaveModalVisible,
    leaveModalColumns,
    setEmpFK,
    leaveData,
    leaveLoading,
    empFK,
    timePeriodsList: timePeriodsList?.data,
    handleSelectTimecard,
    handleClearTimecard,
    handleApprovedFilter
  };
};

export default useTimecardApproval;
