import { Form, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CleanButton from "../../../common/components/CleanButton";
import { checkCase } from "../../../common/constants/helpers";
import { setConfig } from "../../../state/globalStore";
import { useGetAllConfigOptionsQuery, useUpdateConfigOptionMutation } from "../../../state/portalApi";

const useConfig = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [editObject, setEditObject] = useState({});
  const [modalForm] = Form.useForm();
  const dispatch = useDispatch();
  const { data, isLoading } = useGetAllConfigOptionsQuery(undefined);
  const [updateConfig] = useUpdateConfigOptionMutation();

  useEffect(() => {
    dispatch(setConfig(data?.data));
  }, [data?.data, dispatch]);

  const columns = [
    { key: "option", dataIndex: "configOption1", title: "Option", render: (value) => checkCase(value) },
    { key: "group", dataIndex: "optionGroup", title: "Group" },
    { key: "description", dataIndex: "optionDescription", title: "Description" },
    { key: "value", title: "Value", render: (row, record) => (record.optionType === "bool" ? (record.optionValue === "true" ? "Enabled" : "Disabled") : record.optionValue) },
    { key: "id", title: "Actions", render: (row, record, rowIndex) => <CleanButton icon="edit" onClick={() => handleEdit(record)} tooltip={"Edit"} /> }
  ];

  const handleEdit = (record) => {
    setShowModal(true);
    modalForm.setFieldsValue({ value: record.optionValue });
    setEditObject(record);
  };

  const handleSave = async () => {
    const request = {
      ...editObject,
      optionValue: String(modalForm.getFieldValue("value"))
    };
    const response = await updateConfig(request).unwrap();
    if (response.isSuccess) {
      handleCancel();
      notification.success({ message: "Configuration updated successfully" });
    } else {
      notification.error({ message: response.message });
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    modalForm.resetFields();
    setEditObject({});
  };

  return {
    data: data?.data,
    isLoading,
    columns,
    handleSave,
    handleCancel,
    showModal,
    modalForm,
    editObject,
    handleEdit
  };
};

export default useConfig;
