import React from "react";
import { Col, Layout, Row } from "antd";
import "./DesktopLayout.less";
import UserMenu from "./user-menu";

const AppHeader = ({ ...props }) => {
  return (
    <Layout.Header className="site-layout-background" style={{ padding: 0 }}>
      <div
        style={{
          float: "right",
          margin: "0rem 2rem 4rem 1rem",
          textAlign: "center"
        }}
      >
        <Row gutter={24} justify="space-between" align="middle">
          <Col>
            <UserMenu />
          </Col>
        </Row>
      </div>
    </Layout.Header>
  );
};

export default AppHeader;
