import { configureStore, Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import globalReducer from "./state/globalStore";
import { portalApi } from "./state/portalApi";
import timecardReducer from "./state/timecardStore";
import approvalReducer from "./state/approvalStore";
import networkReducer from "./state/networkStore";
import { notification } from "antd";

export const errorInterceptor: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (!action?.payload?.isSuccess) {
    notification.error({ message: action?.payload?.message });
    return next(action);
  }
};

export const store = configureStore({
  reducer: {
    global: globalReducer,
    timecard: timecardReducer,
    approval: approvalReducer,
    network: networkReducer,
    [portalApi.reducerPath]: portalApi.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([portalApi.middleware])
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
