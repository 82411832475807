/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";
import { EmployeeTimeCard } from "../models/timecard/employee-timecard";
import { EmployeeTimeCardEntry } from "../models/timecard/employee-timecard-entry";

interface TimecardState {
  activeTimecard: EmployeeTimeCard;
  totalHours: number;
  isDirty: boolean;
  payCodes: any[];
}

const initialState: TimecardState = {
  activeTimecard: null,
  totalHours: 0,
  isDirty: false,
  payCodes: []
};

const timecardStore = createSlice({
  name: "timecard",
  initialState,
  reducers: {
    getActiveTimecard: (state) => {
      state.activeTimecard;
    },
    setActiveTimecard: (state, action) => {
      state.activeTimecard = action.payload;
    },
    getTotalHours: (state) => {
      state.totalHours;
    },
    setTotalHours: (state, action) => {
      state.totalHours = action.payload;
    },
    addEntry: (state, action) => {
      state.activeTimecard.entries.push(action.payload);
    },
    removeEntry: (state, action) => {
      const entries = []
      state.activeTimecard.entries.forEach((x, index) => {
        if (index !== action.payload) {
          entries.push(x)
        }
      });
      state.activeTimecard.entries = entries;
    },
    updateEntry: (state, action) => {
      const updatedEntry: EmployeeTimeCardEntry = action.payload;
      const entries = state.activeTimecard.entries;
      const index = entries.findIndex((x) => {
        if (x.key && x.key > 0) return x.key === updatedEntry?.key;
        return x.employeeTimeCardDetailID === updatedEntry?.employeeTimeCardDetailID;
      });
      entries[index] = updatedEntry;
    },
    setIsDirty: (state, action) => {
      state.isDirty = action.payload;
    },
    setPayCodes: (state, action) => {
      state.payCodes = action.payload;
    },
    resetTimeCardState: (state) => {
      state.activeTimecard = null;
      state.isDirty = false;
      state.payCodes = [];
      state.totalHours = 0;
    }
  }
});

export const { getActiveTimecard, setActiveTimecard, getTotalHours, setTotalHours, addEntry, removeEntry, updateEntry, setIsDirty, setPayCodes, resetTimeCardState } = timecardStore.actions;

export default timecardStore.reducer;
