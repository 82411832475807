import { Card, Center } from "@mantine/core";
import { Spin, Image } from "antd";
import React, { useState } from "react";
import logo from "../../../../assets/logo2.png";
import useMediaQuery from "../../../common/hooks/useMediaQuery";
import ForgetPasswordForm from "./forget-password-form";

const ForgetPassword = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <Center style={{ height: 750 }}>
      <Card shadow="sm" padding="lg" style={{ width: isMobile ? "90%" : "50%" }}>
        {loading ? (
          <Spin />
        ) : (
          <>
            <Center>
              <Image src={logo} preview={false} height={isMobile ? 150 : 350} />
            </Center>
            <ForgetPasswordForm />
          </>
        )}
      </Card>
    </Center>
  );
};

export default ForgetPassword;
