import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Grid, Text, Button } from "@mantine/core";
import React from "react";

const ConfigCardForm = ({ ...props }) => {
  const { config, handleEdit } = props;
  return (
    <Card
      sx={(t) => ({
        margin: t.spacing.lg,
        padding: t.spacing.sm,
        borderColor: t.colors.green
      })}
      radius="md"
      withBorder
    >
      <Card.Section
        sx={(t) => ({
          padding: t.spacing.md
        })}
      >
        <Grid style={{ padding: 10 }} columns={24}>
          <Grid.Col span={12}>
            <Text weight={600}>{config?.optionGroup}</Text>
          </Grid.Col>
          <Grid.Col span={6}></Grid.Col>
          <Grid.Col span={4}>
            <Text weight={400}>{config.optionValue === "true" ? "Enabled" : "Disabled"}</Text>
          </Grid.Col>
        </Grid>
      </Card.Section>
      <Card.Section
        sx={(t) => ({
          padding: t.spacing.sm
        })}
      >
        <Text color="dimmed">{config?.optionDescription}</Text>
      </Card.Section>
      <Button onClick={() => handleEdit(config)} radius="md" fullWidth leftIcon={<FontAwesomeIcon icon="edit" />}>
        Edit
      </Button>
    </Card>
  );
};

export default ConfigCardForm;
