import { Form, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CleanButton from "../../common/components/CleanButton";
import { useGetFormsQuery, useGetPaycodesByIdMutation, useGetTimecardsQuery, useSavePaycodesMutation } from "../../state/portalApi";
import { RootState } from "../../store";
import { setPayCodes } from "../../state/timecardStore";
import { MapCodes } from "../../models/employee/MapPayCodes";
import ColumnSearch from "../../common/components/ColumnSearch";
import { resetFilters, sortStrColumn } from "../../common/constants/helpers";

const usePaycodeMaintenance = () => {
  const [form] = Form.useForm();
  const [departId, setDepartId] = useState<number>(0);
  const { loggedInEmployeeId } = useSelector((state: RootState) => state.global);
  const { payCodes } = useSelector((state: RootState) => state.timecard);
  const { data: payCodeData, isLoading: payCodeLoading } = useGetTimecardsQuery({ employeeId: loggedInEmployeeId });
  const { data: allForms } = useGetFormsQuery(loggedInEmployeeId);
  const [getPayCodes, paycodeById] = useGetPaycodesByIdMutation();
  const dispatch = useDispatch();
  const [savePayCode] = useSavePaycodesMutation();

  useEffect(() => {
    dispatch(setPayCodes(paycodeById?.data?.data));
  }, [dispatch, paycodeById?.data]);

  const handleSave = async () => {
    const codeList = [];
    payCodes?.forEach((val) => {
      const codeID = payCodeData?.payCodes.find((x) => x?.code === val.code)?.payCodeId;
      codeList.push(codeID);
    });
    const mapCode: MapCodes = {
      departmentId: departId,
      payCodeIds: codeList
    };

    const res = await savePayCode(mapCode).unwrap();
    if (res.isSuccess) {
      notification.success({ message: "Paycode save successfully" });
    } else {
      notification.warn({ message: "Unable to save record" });
    }
    setDepartId(0);
    dispatch(setPayCodes([]));
    form.setFieldsValue({ departId: null });
  };

  const handleAddCheck = (val) => {
    if (!departId) {
      notification.error({ message: "Select a department" });
      setPayCodes({ DepartmentId: departId, Codes: [] });
    } else if (payCodes.find((x) => x.code === val.code)) {
      notification.error({ message: "Paycode already exist" });
    } else {
      dispatch(setPayCodes([...payCodes, val]));
      notification.success({ message: "Paycode added successfully" });
    }
  };

  const handleDelete = (index) => {
    const tempCodes = [...payCodes];
    tempCodes.splice(index, 1);
    dispatch(setPayCodes(tempCodes));
    notification.success({ message: "Paycode deleted successfully" });
  };

  const handleChange = async (val) => {
    setDepartId(!val ? 0 : val);
    await getPayCodes(!val ? 0 : val).unwrap();
  };

  const availableCode = [
    { key: "codeDescription", dataIndex: "codeDescription", title: "Code Description", ...ColumnSearch("codeDescription", "Code Description", resetFilters), ...sortStrColumn("codeDescription") },
    {
      title: "Action",
      render: (val) => {
        return <CleanButton icon="plus" type="link" onClick={() => handleAddCheck(val)} style={{ width: "40px" }} tooltip={"Add"} />;
      }
    }
  ];

  const codesAlreadyAdded = [
    {
      key: "codeDescription",
      dataIndex: "codeDescription",
      title: "Code Description",
      ...ColumnSearch("codeDescription", "Code Description", resetFilters),
      ...sortStrColumn("codeDescription")
    },
    {
      key: "",
      dataIndex: "",
      title: "Action",
      render: (val, row, index) => {
        return <CleanButton icon="trash" type="link" onClick={() => handleDelete(index)} style={{ width: "40px" }} tooltip={"Delete"} />;
      }
    }
  ];

  return {
    handleSave,
    availableCode,
    codesAlreadyAdded,
    handleChange,
    totalPayCodes: payCodeData?.payCodes,
    payCodeLoading,
    allDepartment: allForms?.data?.departments,
    paycodeList: payCodes,
    departId,
    payCodeByIdLoading: paycodeById?.isLoading,
    form
  };
};

export default usePaycodeMaintenance;
