import React from "react";
import { Col, Row, Select, Space, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageTitle from "./PageTitle";

const PageContext = React.createContext(null);

const usePageContext = () => {
  const context = React.useContext(PageContext);
  if (!context) {
    throw new Error("InputGroup compound components cannot be rendered outside the InputGroup component");
  }
  return context;
};

/**
 *
 * @param param0 Props for the title and subtitle on the page summary
 * @returns Title Component
 */
const Title = ({ ...props }) => {
  return (
    <>
      <Row>
        <PageTitle icon={<FontAwesomeIcon icon={props.icon} />} title={props.title} showBreadcrumb={props.showBreadcrumb} subtitle={props.subtitle} />
      </Row>
    </>
  );
};

/**
 *
 * @param param0 Children components to go in the detail section
 * @returns Toolbar component
 */
const Toolbar = ({ ...props }) => {
  return (
    <>
      <Space>{props.children}</Space>
    </>
  );
};

/**
 *
 * @param param0 Children components to go in the detail section
 * @returns Details component
 */
const Controls = ({ ...props }) => {
  const { selectLoading, onSelect, selectType, selectData, selectIndex, selectValue } = props;
  return (
    <Row gutter={[16, 16]}>
      <Col lg={8} md={8} sm={24} xs={24}>
        <Select loading={selectLoading} onChange={onSelect} style={{ width: "100%" }} placeholder={"Select a " + selectType}>
          {React.Children.toArray(
            selectData?.map((data) => {
              return <Select.Option value={data[selectIndex]}>{data[selectValue]}</Select.Option>;
            })
          )}
        </Select>
      </Col>
    </Row>
  );
};

/**
 *
 * @param props
 * @returns
 */
const Content = ({ ...props }) => {
  return <>{props.children}</>;
};

/**
 *
 * @param props Accepts the props for its own children
 * @returns PageSummary
 */
const Page = ({ ...props }) => {
  return (
    <PageContext.Provider value={props}>
      <Space direction="vertical" size={16}>
        {props.loading ? <Spin size="large" /> : props.children}
      </Space>
    </PageContext.Provider>
  );
};

/**
 * Assign the PageSummary its compound component fragments
 */
Page.Title = Title;
Page.Toolbar = Toolbar;
Page.Controls = Controls;
Page.Content = Content;

export default Page;
