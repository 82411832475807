import React from "react";
import { Col, Form, Input, Row } from "antd";
import BannerCard from "../../common/components/BannerCard";
import CleanButton from "../../common/components/CleanButton";

const ChangePassword = ({ ...props }) => {
  const { userForm, handleChangePassword } = props;

  const validatePassword = (rule, value, callback) => {
    const pass = userForm.getFieldValue("password");
    if (!pass) {
      callback("Password is required");
    } else if (pass.length < 6) {
      callback("Password must contains atleast 6 characters");
    }
    callback();
  };

  const validatePasswords = (rule, value, callback) => {
    const pass = userForm.getFieldValue("password");
    const confirmPass = userForm.getFieldValue("confirmPassword");
    if (!confirmPass) {
      callback("Confirm passwrod is required");
    } else if (pass !== confirmPass) {
      callback("The password you have entered does not match");
    }
    callback();
  };

  return (
    <>
      <Col xl={6} lg={6} />
      <Col xl={12} lg={12} md={24} sm={24} xs={24}>
        <BannerCard title="Change Password">
          <Form layout="vertical" form={userForm}>
            <Row gutter={[12, 12]}>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Form.Item label="Last Four Digits of SSN" name="SSN" rules={[{ required: true }]}>
                  <Input maxLength={4} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Form.Item label="Password" name="password" rules={[{ required: true, message: "" }, { validator: validatePassword }]}>
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Form.Item label="Confirm Password" name="confirmPassword" rules={[{ required: true, message: "" }, { validator: validatePasswords }]}>
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col>
                <CleanButton icon="unlock-alt" onClick={handleChangePassword}>
                  Change Password
                </CleanButton>
              </Col>
            </Row>
          </Form>
        </BannerCard>
      </Col>
      <Col xl={6} lg={6} />
    </>
  );
};

export default ChangePassword;
