import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";

const getConfigs = (configField) => {
  const { config } = useSelector((state: RootState) => state.global);

  const getAllValues = () => {
    const allValues = { ...configField };
    config?.forEach((eachConfiguration) => {
      if (configField.includes(eachConfiguration.configOption1)) {
        allValues[eachConfiguration.configOption1] = eachConfiguration.optionValue;
      }
    });
    return allValues;
  };

  return getAllValues();
};

export default getConfigs;
