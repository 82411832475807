import { createSlice } from "@reduxjs/toolkit";

interface NetworkState { isConnectedToNet: boolean; }

const initialState: NetworkState = { isConnectedToNet: true };

const networkStore = createSlice({
  name: "network",
  initialState,
  reducers: {
    setNetworkConnection: (state, action) => {
      state.isConnectedToNet = action.payload;
    }
  }
});

export const { setNetworkConnection } = networkStore.actions;

export default networkStore.reducer;
