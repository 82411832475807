import { Card, Group, Badge, Text, Button } from "@mantine/core";
import { Col, Table } from "antd";
import React from "react";
import ActionCard from "../../common/components/action-card";
import BannerCard from "../../common/components/BannerCard";
import useMediaQuery from "../../common/hooks/useMediaQuery";
import useLeave from "./useLeave";

const LeaveTable = ({ ...props }) => {
  const { fromEmployeeLeave, selectedEmployeeId } = props;
  const { data, loading, columns, handleViewDetail } = useLeave(fromEmployeeLeave, selectedEmployeeId);
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
      <BannerCard title="Employee Leave">
        {isMobile ? (
          React.Children.toArray(
            data?.map((leave) => {
              return (
                <>
                  <br />
                  <ActionCard title={leave?.accrualClassDescription} badgeData={leave?.accruedBalance} badgeExtra="hrs">
                    <Text size="sm" style={{ lineHeight: 1.5 }}>
                      {leave?.accrualEarnedYTD} hours earned year-to-date.
                    </Text>
                    <Text size="sm" style={{ lineHeight: 1.5 }}>
                      {leave?.accrualUsedYTD} hours used year-to-date.
                    </Text>
                    <Button variant="light" color="blue" fullWidth style={{ marginTop: 14 }} onClick={() => handleViewDetail(leave?.accrualClassID)}>
                      View Details
                    </Button>
                  </ActionCard>
                </>
              );
            })
          )
        ) : (
          <Table loading={loading} dataSource={data} columns={columns} size="small" />
        )}
      </BannerCard>
    </Col>
  );
};

export default LeaveTable;
