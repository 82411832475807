import { Col, DatePicker, Form, Input, Row } from "antd";
import React from "react";
import BannerCard from "../../../common/components/BannerCard";

const EmployeeInfo = ({ ...props }) => {
  const { form } = props;

  return (
    <Form form={form} layout="vertical">
      <BannerCard title="" hideTitle>
        <Row gutter={[16, 16]}>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <BannerCard title="Personal Data" internal>
              <br />
              <Row gutter={16}>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <Form.Item name="firstName" label="First Name">
                    <Input readOnly />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <Form.Item name="lastName" label="Last Name">
                    <Input readOnly />
                  </Form.Item>
                </Col>
                <Col xl={24} lg={12} md={24} sm={24} xs={24}>
                  <Form.Item name="fullAddress" label="Primary Address">
                    <Input readOnly />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <Form.Item name="email" label="Email Address">
                    <Input readOnly />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <Form.Item name="phone" label="Phone Number">
                    <Input readOnly />
                  </Form.Item>
                </Col>
              </Row>
            </BannerCard>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <BannerCard title="Employee Info" internal>
              <br />
              <Row gutter={16}>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <Form.Item name="employeeNumber" label="Employee Number">
                    <Input readOnly />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <Form.Item name="startDate" label="Start Date">
                    <DatePicker style={{ width: "100%" }} disabled />
                  </Form.Item>
                </Col>
                <Col xl={24} lg={12} md={24} sm={24} xs={24}>
                  <Form.Item name="jobTitle" label="Job Title">
                    <Input readOnly />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <Form.Item name="department" label="Department">
                    <Input readOnly />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <Form.Item name="lunchMinutes" label="Lunch Minutes">
                    <Input readOnly />
                  </Form.Item>
                </Col>
              </Row>
            </BannerCard>
          </Col>
        </Row>
      </BannerCard>
    </Form>
  );
};

export default EmployeeInfo;
