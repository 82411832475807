import { Space } from "antd";
import React from "react";
import CleanButton from "../../common/components/CleanButton";
import { EmployeeTimeCard } from "../../models/timecard/employee-timecard";

interface LocationStateType {
  state: {
    returnData?: any;
    condition?: string;
    search?: string;
    returnStatus?: boolean;
  };
  key: string;
  pathname: string;
  search: string;
  hash: string;
}

type TimecardToolbarProps = {
  isDirty: boolean;
  timecardInfo: EmployeeTimeCard;
  handleSaveTimecard: (e) => void;
  allowEdit: () => Promise<void>;
  handleSubmitTimecard: () => Promise<void>;
  handleUnsubmitTimecard: () => Promise<void>;
  canEdit: () => boolean;
  canSubmit: () => boolean;
  canUnsubmit: () => boolean;
  location: LocationStateType;
};

const TimecardToolbar = ({ ...props }: TimecardToolbarProps) => {
  const { isDirty, timecardInfo, handleSaveTimecard, allowEdit, handleSubmitTimecard, handleUnsubmitTimecard, canEdit, canSubmit, canUnsubmit, location } = props;
  return (
    <Space direction="horizontal">
      {isDirty && canSubmit() && (
        <CleanButton disabled={location.pathname !== "/timecard" && location.pathname !== "/employees/detail"} icon="save" onClick={handleSaveTimecard}>
          Save
        </CleanButton>
      )}
      {/* {isDirty && (
          <CleanButton disabled={!timecardInfo} icon="undo-alt">
            Clear
          </CleanButton>
        )} */}
      {!isDirty && canEdit() && (
        <CleanButton icon="edit" onClick={allowEdit}>
          Edit
        </CleanButton>
      )}
      {canSubmit() && (
        <CleanButton icon="upload" onClick={handleSubmitTimecard}>
          Submit
        </CleanButton>
      )}
      {canUnsubmit() && (
        <CleanButton icon="undo" onClick={handleUnsubmitTimecard}>
          Unsubmit
        </CleanButton>
      )}
    </Space>
  );
};

export default TimecardToolbar;
