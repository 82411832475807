import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, Alert } from "antd";
import Page from "../../common/components/Page";
import useMediaQuery from "../../common/hooks/useMediaQuery";
import DashboardAddresses from "./dashboard-addresses";
import DashboardEmails from "./dashboard-emails";
import DashboardEmergencyContacts from "./dashboard-emergency-contacts";
import DashboardPhones from "./dashboard-phones";
import DashboardTaxes from "./dashboard-taxes";
import useDashboard from "./useDashboard";

const Dashboard = () => {
  const { data, approvalCount } = useDashboard();
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <Page>
      <Page.Title title={data?.fullName} icon="user" showBreadcrumb={false} subtitle={data?.jobTitle} />
      <Page.Content>
        {approvalCount && (data?.isManager || data?.isAdmin) ? (
          <>
            <Alert
              message="Pending Approvals"
              type="error"
              description={
                <span>
                  You have <b>{approvalCount}</b> timecard{approvalCount > 1 ? "s" : ""} waiting for approval. Click {<Link to="/timecard-approval">here</Link>} to view.
                </span>
              }
              closable
            />
            <br />
          </>
        ) : null}
        <Row gutter={[16, 16]}>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <DashboardAddresses isMobile={isMobile} />
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <DashboardEmails />
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <DashboardPhones />
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <DashboardTaxes />
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <DashboardEmergencyContacts />
          </Col>
        </Row>
      </Page.Content>
    </Page>
  );
};

export default Dashboard;
