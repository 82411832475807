import { Table } from "antd";
import React from "react";
import BannerCard from "../../common/components/BannerCard";
import useDashboard from "./useDashboard";

const DashboardEmergencyContacts = () => {
  const { data, emergencyColumns, loading } = useDashboard();

  return (
    <BannerCard title="Emergency Contacts">
      <Table loading={loading} dataSource={data?.emergencyContacts} columns={emergencyColumns} size="small" scroll={{ x: 480 }} />
    </BannerCard>
  );
};

export default DashboardEmergencyContacts;
