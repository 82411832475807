import React, { useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import jwt from "jwt-decode";
import { ConfigProvider } from "antd";
import { MantineProvider } from "@mantine/core";
import { portalContext, PortalContext } from "./portal-context";
import { store, RootState } from "./store";
import Router from "./common/navigation/router";
import "./styles/styles.less";
import "./styles/style-overrides.css";
import { setActiveEmployee, setToken } from "./state/globalStore";
import { useLazyGetEmployeeInfoQuery } from "./state/portalApi";
import IdleTimer from "./IdleTimer";
import NoNetworkModal from "./NoNetworkModal";
import RequestMalformed from "./RequestMalformed";

const validateMessages = {
  required: "${label} is required",
  whitespace: "${label} is required"
};

const AppWrapper = () => {
  const { token } = useSelector((state: RootState) => state.global);
  const storedToken = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [lazyGetEmployeeInfo] = useLazyGetEmployeeInfoQuery();
  let tokenData: any = {};
  if (storedToken) {
    tokenData = jwt(storedToken);
  }

  useEffect(() => {
    const fetchEmployeeData = async () => {
      let employeeData = await lazyGetEmployeeInfo(tokenData.EmployeeId).unwrap();
      dispatch(setActiveEmployee(employeeData?.data));
    };
    if (token) {
      fetchEmployeeData();
    }
  }, [token]);

  if (!token && storedToken) {
    dispatch(setToken({ token: storedToken }));
  }

  return <></>;
};

const App = () =>
  window.top != window.self ? (
    <RequestMalformed />
  ) : (
    <Provider store={store}>
      {localStorage.getItem("token") ? <AppWrapper /> : null}
      <PortalContext.Provider value={portalContext}>
        <ConfigProvider form={{ validateMessages }}>
          <MantineProvider
            withGlobalStyles
            theme={{
              colorScheme: "light",
              loader: "dots",
              dateFormat: "MM-DD-YYYY",
              fontFamily: 'Poppins, "Helvetica Neue", Helvetica, Arial, sans-serif',
              lineHeight: 1.5715,
              spacing: {
                lg: 4,
                md: 8,
                sm: 12,
                xs: 8,
                xl: 2
              },
              fontSizes: {
                md: 14,
                sm: 12,
                lg: 14.4
              },
              headings: {
                fontFamily: 'Poppins, "Helvetica Neue", Helvetica, Arial, sans-serif',
                fontWeight: 400,
                sizes: {
                  h1: { fontSize: 40, lineHeight: 1.5715 },
                  h2: { fontSize: 32, lineHeight: 1.5715 },
                  h3: { fontSize: 28, lineHeight: 1.5715 },
                  h4: { fontSize: 24, lineHeight: 1.5715 },
                  h5: { fontSize: 20, lineHeight: 1.5715 }
                }
              },
              /** This section is where the theme overrides will take place. */
              colors: {
                brand: ["#cfe6e3", "#8bd9d4", "#62ccc8", "#3dbfbf", "#1daeb3", "#009ca6", "#009ca6", "#004d59", "#002a33", "#000a0d"],
                brandSecondary: ["#f7f0ff", "#e6dcf2", "#c2ace6", "#9f80d9", "#7b57cb", "#7b57cb", "#5c3fa6", "#3f2a80", "#271959", "#150e33"]
              },
              primaryColor: "brand"
            }}
          >
            <Router />
          </MantineProvider>
        </ConfigProvider>
      </PortalContext.Provider>
      <IdleTimer />
      <NoNetworkModal />
    </Provider>
  );
export default App;
