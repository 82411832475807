import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router";
import { RootState } from "../../store";
import EmployeeDetail from "../../views/administration/employees/employee-detail";
import Employees from "../../views/administration/employees/employees";
import ApprovalChains from "../../views/approval-chain/approval-chain";
import Benefits from "../../views/benefits/benefits";
import Dashboard from "../../views/dashboard/dashboard";
import FormNotifications from "../../views/forms/form-notifications";
import Forms from "../../views/forms/forms";
import ManageCatgories from "../../views/forms/manage-categories/manage-categories";
import ManageForms from "../../views/forms/manage-forms/manage-forms";
import Inventory from "../../views/inventory/inventory";
import Leave from "../../views/leave/leave";
import ForgetPassword from "../../views/login/forget-password/forget-password";
import Login from "../../views/login/Login";
import Register from "../../views/login/register/register";
import NotFound from "../components/not-found";
import PayHistory from "../../views/pay-history/pay-history";
import TimecardApproval from "../../views/timecard-approval/timecard-approval";
import Timecard from "../../views/timecard/timecard";
import DesktopLayout from "../layout/desktop/DesktopLayout";
import UserProfile from "../../views/user-profile/UserProfile";
import Configuration from "../../views/administration/configuration/configuration";
import Paycodes from "../../views/paycodes/paycode";
import getConfigs from "../../views/getConfigs";
import AccessDenied from "../components/access-denied";

const RequireAuth = ({ ...props }) => {
  const { children } = props;
  const { token, activeEmployee } = useSelector((state: RootState) => state.global);
  const location = useLocation();
  const configurations = getConfigs([
    "Dashboard",
    "PayHistory",
    "ShowTimecard",
    "Benefits",
    "Leave",
    "Forms",
    "Inventory",
    "ShowTimecard",
    "EnablePaycodeMapping",
    "ShowApprovalChain",
    "ShowTimecardApproval",
    "ManagersCanViewAcknowledgements",
    "ManagerCanAddForm",
    "ManagerCanAddCategory"
  ]);

  if (!token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // Added this to restrict user from manual routing
  switch (location.pathname) {
    case "/dashboard":
      if (configurations.Dashboard?.toLowerCase() === "false") {
        return <AccessDenied />;
      }
      break;
    case "/pay-history":
      if (configurations.PayHistory?.toLowerCase() === "false") {
        return <AccessDenied />;
      }
      break;
    case "/benefits":
      if (configurations.Benefits?.toLowerCase() === "false") {
        return <AccessDenied />;
      }
      break;
    case "/leave":
      if (configurations.Leave?.toLowerCase() === "false") {
        return <AccessDenied />;
      }
      break;
    case "/forms":
      if (configurations.Forms?.toLowerCase() === "false") {
        return <AccessDenied />;
      }
      break;
    case "/inventory":
      if (configurations.Inventory?.toLowerCase() === "false") {
        return <AccessDenied />;
      }
      break;
    case "/timecard":
      if (configurations.ShowTimecard?.toLowerCase() === "false") {
        return <AccessDenied />;
      }
      break;
    case "/paycodes":
      if (configurations.EnablePaycodeMapping?.toLowerCase() === "false") {
        return <AccessDenied />;
      } else if (!activeEmployee?.isAdmin) {
        return <AccessDenied />;
      }
      break;
    case "/config":
      if (!activeEmployee?.isAdmin) {
        return <AccessDenied />;
      }
      break;
    case "/approval-chain":
      if (configurations.ShowApprovalChain?.toLowerCase() === "false") {
        return <AccessDenied />;
      } else if (!activeEmployee?.isAdmin) {
        return <AccessDenied />;
      }
      break;
    case "/timecard-approval":
      if (configurations.ShowTimecardApproval?.toLowerCase() === "false") {
        return <AccessDenied />;
      } else if (!activeEmployee?.isAdmin && !activeEmployee?.isManager) {
        return <AccessDenied />;
      }
      break;
    case "/forms/acknowledgements":
      if (configurations.ManagersCanViewAcknowledgements?.toLowerCase() === "false") {
        return <AccessDenied />;
      } else if (!activeEmployee?.isAdmin && !activeEmployee?.isManager) {
        return <AccessDenied />;
      }
      break;
    case "/forms/manage-forms":
      if (configurations.ManagerCanAddForm?.toLowerCase() === "false") {
        return <AccessDenied />;
      } else if (!activeEmployee?.isAdmin && !activeEmployee?.isManager) {
        return <AccessDenied />;
      }
      break;
    case "/forms/manage-categories":
      if (configurations.ManagerCanAddCategory?.toLowerCase() === "false") {
        return <AccessDenied />;
      } else if (!activeEmployee?.isAdmin && !activeEmployee?.isManager) {
        return <AccessDenied />;
      }
      break;
    default:
      if (location.pathname.includes("/employees") && !activeEmployee?.isAdmin && !activeEmployee?.isManager) {
        return <AccessDenied />;
      }
  }

  return <DesktopLayout>{children}</DesktopLayout>;
};

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forget" element={<ForgetPassword />} />
      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      />
      <Route
        path="/user-profile"
        element={
          <RequireAuth>
            <UserProfile />
          </RequireAuth>
        }
      />
      <Route
        path="/timecard"
        element={
          <RequireAuth>
            <Timecard />
          </RequireAuth>
        }
      />
      <Route
        path="/leave"
        element={
          <RequireAuth>
            <Leave />
          </RequireAuth>
        }
      />
      <Route
        path="/benefits"
        element={
          <RequireAuth>
            <Benefits />
          </RequireAuth>
        }
      />
      <Route
        path="/inventory"
        element={
          <RequireAuth>
            <Inventory />
          </RequireAuth>
        }
      />
      <Route
        path="/pay-history"
        element={
          <RequireAuth>
            <PayHistory />
          </RequireAuth>
        }
      />
      <Route
        path="/forms"
        element={
          <RequireAuth>
            <Forms />
          </RequireAuth>
        }
      />
      <Route
        path="/forms/manage-forms"
        element={
          <RequireAuth>
            <ManageForms />
          </RequireAuth>
        }
      />
      <Route
        path="/forms/acknowledgements"
        element={
          <RequireAuth>
            <FormNotifications />
          </RequireAuth>
        }
      />
      <Route
        path="/timecard-approval"
        element={
          <RequireAuth>
            <TimecardApproval />
          </RequireAuth>
        }
      />
      <Route
        path="/approval-chain"
        element={
          <RequireAuth>
            <ApprovalChains />
          </RequireAuth>
        }
      />
      <Route
        path="/employees"
        element={
          <RequireAuth>
            <Employees />
          </RequireAuth>
        }
      />
      <Route
        path="/config"
        element={
          <RequireAuth>
            <Configuration />
          </RequireAuth>
        }
      />
      <Route
        path="/employees/detail"
        element={
          <RequireAuth>
            <EmployeeDetail />
          </RequireAuth>
        }
      />
      <Route
        path="/forms/manage-categories"
        element={
          <RequireAuth>
            <ManageCatgories />
          </RequireAuth>
        }
      />
      <Route
        path="/paycodes"
        element={
          <RequireAuth>
            <Paycodes />
          </RequireAuth>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
