import { Row, Col, Select } from "antd";
import React from "react";
import CleanButton from "../../common/components/CleanButton";
import Page from "../../common/components/Page";
import { formattedDate } from "../../common/constants/helpers";
import PayHistoryBreakdown from "./pay-history-breakdown";
import PayHistoryChart from "./pay-history-chart";
import usePayHistory from "./usePayHistory";

const PayHistory = () => {
  const { data, isLoading, selectCheck, payHistoryInfo, leaveCols, payCols, withholdingCols, printPayStub } = usePayHistory();

  return (
    <Page>
      <Page.Title title="Pay History" icon="history" showBreadcrumb />
      <Page.Content>
        <Row gutter={[16, 16]} align="middle">
          <Col lg={8} md={8} sm={24} xs={24}>
            <Select onChange={selectCheck} loading={isLoading} style={{ width: "100%" }} placeholder="Select a pay period">
              {React.Children.toArray(
                data?.map((d) => {
                  return <Select.Option value={d?.historyId}>{formattedDate(d?.beginDate) + " - " + formattedDate(d?.endDate) + " #" + d?.checkNumber}</Select.Option>;
                })
              )}
            </Select>
          </Col>
          <Col>
            {payHistoryInfo && (
              <CleanButton icon="print" onClick={printPayStub} tooltip={"Print"}>
                Print
              </CleanButton>
            )}
          </Col>
        </Row>
        <br />
        <Row>
          <Col xl={12} lg={12} md={18} sm={24} xs={24}>
            {payHistoryInfo && <PayHistoryChart payHistoryInfo={payHistoryInfo} />}
          </Col>
        </Row>
        <br />
        <Row>
          <Col xl={12} lg={12} md={18} sm={24} xs={24}>
            {payHistoryInfo && <PayHistoryBreakdown payHistoryInfo={payHistoryInfo} leaveCols={leaveCols} payCols={payCols} withholdingCols={withholdingCols} />}
          </Col>
        </Row>
      </Page.Content>
    </Page>
  );
};

export default PayHistory;
