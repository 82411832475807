import React from "react";
import { Col, Form, Input, Row, Avatar } from "antd";
import BannerCard from "../../common/components/BannerCard";
import CleanButton from "../../common/components/CleanButton";

const ProfileDetails = ({ ...props }) => {
  const { userForm, profilePic, handleUpdateEmail, handleIsEdit, isEdit } = props;

  return (
    <>
      <Col xl={6} lg={6} />
      <Col xl={12} lg={12} md={24} sm={24} xs={24}>
        <BannerCard title="User Profile">
          <Form layout="vertical" form={userForm}>
            <Row gutter={[12, 12]}>
              <Col xl={10} lg={10} md={8} />
              <Col xl={4} lg={4} md={6} sm={10} xs={24}>
                <Form.Item name="profilePicture">
                  <Avatar style={{ backgroundColor: "#F56A00", verticalAlign: "middle" }} size={70}>
                    {profilePic}
                  </Avatar>
                </Form.Item>
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Form.Item name="FullName" label="Name">
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col xl={18} lg={16} md={18} sm={18} xs={24}>
                <Form.Item label="Email" name="email">
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
              <Col xl={6} lg={8} md={6} sm={6} xs={24}>
                <Form.Item label=" ">
                  <CleanButton onClick={isEdit ? handleUpdateEmail : handleIsEdit} icon={isEdit ? "save" : "edit"} style={{ width: "100%" }}>
                    {isEdit ? "Update" : "Edit"}
                  </CleanButton>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </BannerCard>
      </Col>
      <Col xl={6} lg={6} />
    </>
  );
};

export default ProfileDetails;
