import { createSlice } from "@reduxjs/toolkit";
import { Employee } from "../models/employee/employee";
import jwt from "jwt-decode";
import { EmployeeSubstituteDTO } from "../models/employee/employee-substitute";
import { ConfigOption } from "../models/config";

interface GlobalState {
  menuCollapsed: boolean;
  loggedInUserId: number;
  loggedInEmployeeId: number;
  token: string;
  activeEmployee: Employee;
  selectedEmployee;
  loading: boolean;
  substituteApprovers: EmployeeSubstituteDTO[];
  config: ConfigOption[];
}

const initialState: GlobalState = {
  menuCollapsed: false,
  loggedInUserId: null,
  loggedInEmployeeId: null,
  token: null,
  activeEmployee: null,
  selectedEmployee: null,
  loading: false,
  substituteApprovers: null,
  config: null
};

const globalStore = createSlice({
  name: "global",
  initialState,
  reducers: {
    getMenuCollapsed: (state) => {
      state.menuCollapsed;
    },
    setMenuCollapsed: (state, action) => {
      state.menuCollapsed = typeof action.payload === "boolean" ? action.payload : !state.menuCollapsed;
    },
    getLoggedInUser: (state) => {
      state.loggedInUserId;
    },
    setLoggedInUser: (state, action) => {
      state.loggedInUserId = action.payload;
    },
    getToken: (state) => {
      state.token;
    },
    setToken: (state, action) => {
      state.loading = true;
      const token: any = jwt(action.payload.token);
      state.loggedInEmployeeId = Number(token.EmployeeId);
      state.token = action.payload.token;
      state.loading = false;
    },
    getActiveEmployee: (state) => {
      state.activeEmployee;
    },
    setActiveEmployee: (state, action) => {
      state.activeEmployee = action.payload;
    },
    clearActiveEmployee: (state) => {
      state.activeEmployee = null;
    },
    getSelectedEmployee: (state) => {
      state.selectedEmployee;
    },
    setSelectedEmployee: (state, action) => {
      state.selectedEmployee = action.payload;
    },
    clearSelectedEmployee: (state) => {
      state.selectedEmployee = null;
    },
    resetState: (state) => {
      state.activeEmployee = null;
      state.loggedInEmployeeId = null;
      state.loggedInUserId = null;
      state.token = null;
      state.loading = false;
      state.config = null;
    },
    getSubstituteApprovers: (state) => {
      state.substituteApprovers;
    },
    setSubstituteApprovers: (state, action) => {
      state.substituteApprovers = action.payload;
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    }
  }
});

export const {
  getMenuCollapsed,
  setMenuCollapsed,
  getLoggedInUser,
  setLoggedInUser,
  getToken,
  setToken,
  getActiveEmployee,
  setActiveEmployee,
  clearActiveEmployee,
  resetState,
  getSelectedEmployee,
  setSelectedEmployee,
  clearSelectedEmployee,
  getSubstituteApprovers,
  setSubstituteApprovers,
  setConfig
} = globalStore.actions;

export default globalStore.reducer;
