import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { asMoney } from "../../common/constants/helpers";
import { setActiveEmployee } from "../../state/globalStore";
import { useGetApprovalListCountQuery, useGetEmployeeInfoQuery } from "../../state/portalApi";
import { RootState } from "../../store";
import { Tax } from "../../models/employee/employee-tax";

const useDashboard = () => {
  const { loggedInEmployeeId, activeEmployee } = useSelector((state: RootState) => state.global);
  const { data, isLoading } = useGetEmployeeInfoQuery(loggedInEmployeeId);
  const [newLayout, setNewLayout] = useState<boolean>(false);
  const { data: approvalCount } = useGetApprovalListCountQuery(loggedInEmployeeId);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!activeEmployee) dispatch(setActiveEmployee(data?.data));

    if (data) {
      const federalTaxes = data?.data?.taxes?.filter((o) => o.taxLocality.toUpperCase() === "FEDERAL");
      setNewLayout(federalTaxes[0]?.new2020W4);
    }
  }, [dispatch, data, activeEmployee]);

  const makeDollarAmount = (addon) => {
    if (Number.isInteger(addon)) {
      return `$${addon}.00`;
    }

    return `${Math.floor(addon * 100) / 100.0}`;
  };

  const getAddon = (tax: Tax) => {
    const taxTypes = ["FEDERAL", "STATE", "CITY", "SCHOOL"];
    if (taxTypes.indexOf(tax.taxLocality) !== -1) {
      if (tax.taxationModeDescription === "Dollar Addon") return tax?.addOn === 0 ? "" : makeDollarAmount(tax?.addOn);

      return tax?.addOn === 0 ? "" : `${tax?.addOn}%`;
    }

    return null;
  };

  const getDependents = (tax: Tax) => {
    if (tax.taxLocality === "FEDERAL" || tax.taxLocality === "STATE") {
      return tax.dependants;
    } else {
      return null;
    }
  };

  const addressColumns = [
    { key: "line1", dataIndex: "addressLine1", title: "Line One" },
    { key: "line2", dataIndex: "addressLine2", title: "Line Two" },
    { key: "city", dataIndex: "city", title: "City" },
    { key: "state", dataIndex: "state", title: "State" },
    { key: "zip", dataIndex: "zip", title: "Zip" }
  ];

  const emailsColumns = [
    { key: "type", dataIndex: "type", title: "Type" },
    { key: "address", dataIndex: "emailAddress", title: "Address" }
  ];

  const phoneColumns = [
    { key: "code", dataIndex: "type", title: "Type" },
    { key: "number", dataIndex: "number", title: "Number" },
    { key: "extension", dataIndex: "extension", title: "Extension" }
  ];

  const taxColumns = [
    { key: "locality", dataIndex: "taxLocality", title: "Tax Type" },
    { key: "description", dataIndex: "display", title: "Description" },
    { key: "dependents", dataIndex: "dependants", title: "Dependants", render: (_, row) => getDependents(row) },
    { key: "addons", dataIndex: "addOn", title: "Addons", render: (_, row) => getAddon(row) }
  ];

  const newLayoutTaxColumns = [
    { key: "locality", dataIndex: "taxLocality", title: "Tax Type" },
    { key: "description", dataIndex: "display", title: "Description" },
    { key: "twoJobs", dataIndex: "have2Jobs", title: "Two Jobs" },
    { key: "dependents", dataIndex: "dependentsAmount", title: "Dependents Amt", ...asMoney },
    { key: "otherIncome", dataIndex: "otherIncome", title: "Other Income", ...asMoney },
    { key: "deductions", dataIndex: "deductions", title: "Deductions", ...asMoney }
  ];

  const emergencyColumns = [
    { key: "firstName", dataIndex: "firstName", title: "First Name" },
    { key: "lastName", dataIndex: "lastName", title: "Last Name" },
    { key: "phoneNumber", dataIndex: "phoneNumbers", title: "Phone Num", render: (numbers) => numbers[0]?.number },
    { key: "relation", dataIndex: "relation", title: "Relation" },
    { key: "comments", dataIndex: "comments", title: "Comments", render: (comments) => comments || "N/A" }
  ];

  return {
    data: data?.data,
    loading: isLoading,
    addressColumns,
    emailsColumns,
    phoneColumns,
    newLayoutTaxColumns,
    taxColumns,
    emergencyColumns,
    newLayout,
    activeEmployee,
    approvalCount
  };
};

export default useDashboard;
