import { Table } from "antd";
import React from "react";
import BannerCard from "../../common/components/BannerCard";
import useDashboard from "./useDashboard";

const DashboardPhones = () => {
  const { data, phoneColumns, loading } = useDashboard();

  return (
    <BannerCard title="Phone Numbers">
      <Table loading={loading} size="small" dataSource={data?.phones} columns={phoneColumns} scroll={{ x: 480 }} />
    </BannerCard>
  );
};

export default DashboardPhones;
