import React, { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ChevronRightIcon, ExitIcon, PersonIcon, QuestionMarkCircledIcon } from "@modulz/radix-icons";
import { Group, Avatar, Text, Menu, UnstyledButton, UnstyledButtonProps } from "@mantine/core";
import { RootState } from "../../../store";
import { Employee } from "../../../models/employee/employee";
import { resetState } from "../../../state/globalStore";
import { resetTimeCardState } from "../../../state/timecardStore";
import { resetApprovalState } from "../../../state/approvalStore";

interface UserButtonProps extends UnstyledButtonProps {
  activeEmployee: Employee;
  icon?: React.ReactNode;
}

const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(({ activeEmployee, icon, ...others }: UserButtonProps, ref) => (
  <UnstyledButton
    ref={ref}
    sx={(theme) => ({
      display: "block",
      width: "100%",
      padding: theme.spacing.md,
      color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,

      "&:hover": {
        backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[0]
      }
    })}
    {...others}
  >
    <Group>
      <Avatar radius="xl">{activeEmployee?.firstName?.charAt(0) + activeEmployee?.lastName?.charAt(0)}</Avatar>

      <div style={{ flex: 1 }}>
        <Text size="sm" weight={500}>
          {activeEmployee?.fullName}
        </Text>

        <Text color="dimmed" size="xs">
          {activeEmployee?.jobTitle}
        </Text>
      </div>

      {icon || <ChevronRightIcon />}
    </Group>
  </UnstyledButton>
));

const UserMenu = () => {
  const { activeEmployee } = useSelector((state: RootState) => state.global);
  const dispatch = useDispatch();
  const history = useNavigate();

  const handleSignOut = () => {
    dispatch(resetState());
    dispatch(resetApprovalState());
    dispatch(resetTimeCardState());
    localStorage.clear();
    history("/login");
  };

  const handleProfile = () => {
    history("/user-profile");
  };

  return (
    <Group position="center">
      <Menu withArrow placement="center" control={<UserButton activeEmployee={activeEmployee} />}>
        <Menu.Item icon={<PersonIcon />} onClick={handleProfile}>
          Profile
        </Menu.Item>
        <Menu.Item icon={<QuestionMarkCircledIcon />}>
          <a
            target="_blank"
            href="https://help.civicacmi.com/appPortal/Default.aspx?appVersion=1.0.32&appName=AuthorityPayrollPortal&dbVersion=1.1.0&cityName=Portal&uName=undefined&uID=6"
            style={{ color: "black" }}
          >
            Help
          </a>
        </Menu.Item>
        <Menu.Item icon={<ExitIcon />} onClick={handleSignOut}>
          Sign Out
        </Menu.Item>
      </Menu>
    </Group>
  );
};

export default UserMenu;
