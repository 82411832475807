import React from "react";

const RequestMalformed = () => {
  return (
    <div>
      <span>Request Malformed</span>
      <br />
      <span>Website refused to connect</span>
    </div>
  );
};

export default RequestMalformed;
