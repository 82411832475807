import { Descriptions } from "antd";
import React from "react";
import { formattedDate } from "../../common/constants/helpers";

const InsuranceCard = ({ ...props }) => {
  const { ins } = props;

  return (
    <Descriptions title={ins?.deductCodeDescription} column={1}>
      <Descriptions.Item label="Description">{ins?.insuranceDescription}</Descriptions.Item>
      <Descriptions.Item label="Copay">{"$" + ins?.coPay?.toFixed(2)}</Descriptions.Item>
      <Descriptions.Item label="Premium">{"$" + ins?.premium.toFixed(2)}</Descriptions.Item>
      <Descriptions.Item label="Deductible">{"$" + ins?.deduction.toFixed(2)}</Descriptions.Item>
      <Descriptions.Item label="Effective Date">{formattedDate(ins?.effectiveDate)}</Descriptions.Item>
      <Descriptions.Item label="Expiration Date">{formattedDate(ins?.expirationDate)}</Descriptions.Item>
    </Descriptions>
  );
};

export default InsuranceCard;
