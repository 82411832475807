import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Select, Button, Card, Text, Group, Space } from "@mantine/core";
import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import ActionCard from "../../../common/components/action-card";

const EmployeeListCard = ({ ...props }) => {
  const { data, viewEmployee, loading, listLoading } = props;
  const [empData, setEmpData] = useState(data);

  useEffect(() => {
    setEmpData(data);
  }, [data]);

  const searchEmp = (e) => {
    if (e) setEmpData(empData.filter((entry) => entry?.employeeNumber === e));
    else setEmpData(data);
  };

  return (
    <>
      {listLoading ? (
        <Spin />
      ) : (
        <>
          <Select
            radius="md"
            variant="filled"
            searchable
            value={empData?.map((e) => e?.employeeID)?.employeeNumber}
            placeholder="Select an employee"
            clearable
            onChange={(e) => searchEmp(e)}
            data={data?.map((empData, index) => ({ key: index.toString(), value: empData?.employeeNumber.toString(), label: empData?.fullName })) || []}
          />
          <Space h="sm" />
          <>
            {React.Children.toArray(
              empData?.map((e) => {
                return (
                  <Card
                    sx={(t) => ({
                      margin: t.spacing.lg,
                      padding: t.spacing.sm,
                      borderColor: t.colors.green
                    })}
                    radius="md"
                    withBorder
                  >
                    <Card.Section
                      sx={(t) => ({
                        padding: t.spacing.md
                      })}
                    >
                      <ActionCard title={<div style={{ fontWeight: "bold" }}>{e?.fullName}</div>} badgeData={`Employee No: ${e?.employeeNumber}`}>
                        <br />
                        {
                          <Group position="apart">
                            <Text> Admin: {<Badge color={e?.isAdmin ? "green" : "red"}>{e?.isAdmin ? "YES" : "NO"}</Badge>}</Text>
                            <Text> Manager: {<Badge color={e?.isManager ? "green" : "red"}>{e?.isManager ? "YES" : "NO"}</Badge>}</Text>
                            <Text> Registered: {<Badge color={e?.isRegisteredUser ? "green" : "red"}>{e?.isRegisteredUser ? "YES" : "NO"}</Badge>}</Text>
                            <Text> Approver: {<Badge color={e?.isApprover ? "green" : "red"}>{e?.isApprover ? "YES" : "NO"}</Badge>}</Text>
                          </Group>
                        }
                      </ActionCard>
                      <Button loading={loading} onClick={() => viewEmployee(e?.employeeID)} fullWidth leftIcon={<FontAwesomeIcon icon="eye" />}>
                        View
                      </Button>
                    </Card.Section>
                  </Card>
                );
              })
            )}
          </>
        </>
      )}
    </>
  );
};

export default EmployeeListCard;
