import { Badge, Card, Group, Accordion, Text } from "@mantine/core";
import React from "react";
import { Card as AntCard } from "antd";
import ActionCard from "../../common/components/action-card";

const PayHistoryBreakdownCard = ({ ...props }) => {
  const { leaves, pays, withHoldings } = props;
  return (
    <>
      <Accordion>
        <Accordion.Item label="Leave">
          <AntCard>
            {leaves?.map((e) => {
              return (
                <>
                  <Card
                    sx={(t) => ({
                      margin: t.spacing.lg,
                      padding: t.spacing.sm,
                      borderColor: t.colors.green
                    })}
                    radius="md"
                    withBorder
                  >
                    <ActionCard title={<div style={{ fontWeight: "bold" }}>{e?.accumulatedLeaveCodeDisplay}</div>}>
                      <br />
                      {
                        <Group position="center" direction="column">
                          <Text> Earned: {<Badge color={e?.earned === 0 ? "red" : "green"}>{e?.earned}</Badge>}</Text>
                          <Text> Used: {<Badge color="green">{e?.used}</Badge>}</Text>
                          <Text> Balance: {<Badge color={e?.balance === 0 ? "red" : "green"}>{e?.balance?.toFixed(2)}</Badge>}</Text>
                        </Group>
                      }
                    </ActionCard>
                  </Card>
                </>
              );
            })}
          </AntCard>
        </Accordion.Item>
        <Accordion.Item label="Pay">
          <AntCard>
            {pays?.map((e) => {
              return (
                <>
                  <Card
                    sx={(t) => ({
                      margin: t.spacing.lg,
                      padding: t.spacing.sm,
                      borderColor: t.colors.green
                    })}
                    radius="md"
                    withBorder
                  >
                    <ActionCard title={<div style={{ fontWeight: "bold" }}>{e?.hoursCodeDisplay}</div>}>
                      <br />
                      {
                        <Group position="center" direction="column">
                          <Text> Code: {<Badge color="green">{e?.hoursCodeDisplay}</Badge>}</Text>
                          <Text> Hours: {<Badge color={e?.hours === 0 ? "red" : "green"}>{e?.hours}</Badge>}</Text>
                          <Text> Amount: {<Badge color={e?.amount === 0 ? "red" : "green"}>{e?.amount}</Badge>}</Text>
                          <Text> Amount YTD: {<Badge color={e?.amountYTD === 0 ? "red" : "green"}>{e?.amountYTD}</Badge>}</Text>
                          <Text> Pay Rate: {<Badge color="green">{e?.rate}</Badge>}</Text>
                        </Group>
                      }
                    </ActionCard>
                  </Card>
                </>
              );
            })}
          </AntCard>
        </Accordion.Item>
        <Accordion.Item label="WithHolding">
          <AntCard>
            {withHoldings?.map((e) => {
              return (
                <>
                  <Card
                    sx={(t) => ({
                      margin: t.spacing.lg,
                      padding: t.spacing.sm,
                      borderColor: t.colors.green
                    })}
                    radius="md"
                    withBorder
                  >
                    <ActionCard title={<div style={{ fontWeight: "bold" }}>{e?.withholdingCode}</div>}>
                      <br />
                      {
                        <Group position="center" direction="column">
                          <Text> Earned: {<Badge color={e?.amount === 0 ? "red" : "green"}>{e?.amount}</Badge>}</Text>
                          <Text> Balance: {<Badge color={e?.amountYTD === 0 ? "red" : "green"}>{e?.amountYTD}</Badge>}</Text>
                        </Group>
                      }
                    </ActionCard>
                  </Card>
                </>
              );
            })}
          </AntCard>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default PayHistoryBreakdownCard;
