import React from "react";
import Page from "../../common/components/Page";
import LeaveTable from "./leave-table";

const Leave = () => {
  return (
    <Page>
      <Page.Title title="Leave" icon="calendar" showBreadcrumb />
      <Page.Content>
        <LeaveTable />
      </Page.Content>
    </Page>
  );
};

export default Leave;
