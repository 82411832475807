import React from "react";
import { Row } from "antd";
import Page from "../../common/components/Page";
import ProfileDetails from "./ProfileDetails";
import ChangePassword from "./ChangePassword";
import useUserProfile from "./useUserProfile";

const UserProfile = () => {
  const { userForm, handleChangePassword, profilePic, handleUpdateEmail, setIsEdit, isEdit } = useUserProfile();

  const handleIsEdit = () => {
    setIsEdit(true);
  };

  return (
    <Page>
      <Page.Title title="User Profile" icon="user" showBreadcrumb />
      <Page.Content>
        <Row gutter={[16, 16]}>
          <ProfileDetails userForm={userForm} profilePic={profilePic} handleUpdateEmail={handleUpdateEmail} isEdit={isEdit} handleIsEdit={handleIsEdit} />
          <ChangePassword userForm={userForm} handleChangePassword={handleChangePassword} />
        </Row>
      </Page.Content>
    </Page>
  );
};

export default UserProfile;
