import { Progress } from "antd";
import React, { useState, useEffect } from "react";

const Timer = ({ ...props }) => {
  const { initialMinute = 0, initialSeconds = 60 } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        if (seconds - 1 === 0) {
          localStorage.clear();
          props.navigate("/");
        }
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return <Progress type="circle" percent={(seconds * 100) / 60} width={80} status="exception" format={() => `${seconds}`} />;
};

export default Timer;
