import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import useForgetPassword from "./useForgetPassword";

const ForgetPasswordForm = ({ ...props }) => {
  const { resetForm, handleResetPassword, handleFindUsername, handleValidateUser, employeeFound, renderPopover, confirmRender } = useForgetPassword();
  const [activateUserNameForm, setActivateUserName] = useState<boolean>(false);
  const history = useNavigate();
  const handleLogin = () => history("/login");
  const handleForgetUserName = () => setActivateUserName(true);
  const handleUserName = () => {
    handleFindUsername();
  };

  return (
    <>
      <Form
        form={resetForm}
        layout="vertical"
        style={{
          maxWidth: "300px",
          margin: "auto"
        }}
      >
        <Row gutter={16}>
          <Col span={24}>
            {employeeFound ? (
              <>
                <Form.Item name="password" rules={[{ required: true, message: "Password is required" }]}>
                  {renderPopover}
                </Form.Item>
                <Form.Item name="confirmPassword" rules={[{ required: true, message: "Confirm password is required" }]}>
                  {confirmRender}
                </Form.Item>
              </>
            ) : activateUserNameForm ? (
              <Form.Item name="email" rules={[{ required: true, message: "Please enter email" }]}>
                <Input prefix={<FontAwesomeIcon icon={["fas", "user"]} />} placeholder="Email" />
              </Form.Item>
            ) : (
              <>
                <Form.Item name="username" rules={[{ required: true, message: "Please enter username" }]}>
                  <Input prefix={<FontAwesomeIcon icon={["fas", "user"]} />} placeholder="Username" />
                </Form.Item>
                <Form.Item name="lastFourSSN" rules={[{ required: true, message: "Please enter last 4 digits of SSN" }]}>
                  <Input prefix={<FontAwesomeIcon icon={["fas", "lock"]} />} placeholder="Last Four (SSN)" maxLength={4} />
                </Form.Item>
              </>
            )}
            {employeeFound ? (
              <Button block type="primary" onClick={handleResetPassword}>
                Reset Password
              </Button>
            ) : activateUserNameForm ? (
              <Button block type="primary" onClick={handleUserName}>
                Find Username
              </Button>
            ) : (
              <Button block type="primary" onClick={handleValidateUser}>
                Reset Password
              </Button>
            )}
            {!activateUserNameForm && !employeeFound && (
              <Button block type="link" onClick={handleForgetUserName}>
                Forget Username
              </Button>
            )}
            <Button block type="link" onClick={handleLogin}>
              Log In
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ForgetPasswordForm;
