import React, { useState } from "react";
import TimeCardMobile from "./timecard-mobile-card";
import { Button } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { CaretDownIcon, CaretUpIcon } from "@modulz/radix-icons";
import BannerCard from "../../common/components/BannerCard";

const TimeCardApprovalMobile = ({ ...props }) => {
  const { data, title } = props;
  const [openedApproval, setOpenedApproval] = useState<number>(null);

  const selectGroup = (group) => {
    if (openedApproval === group?.employeeTimecardId) {
      setOpenedApproval(null);
      return;
    }

    setOpenedApproval(group?.employeeTimecardId);
  };

  return (
    <BannerCard title={title}>
      {React.Children.toArray(
        data?.map((approval) => {
          return (
            <>
              <Button
                radius="md"
                style={{ padding: 10 }}
                fullWidth
                leftIcon={openedApproval === approval?.employeeTimecardId ? <CaretUpIcon /> : <CaretDownIcon />}
                onClick={() => selectGroup(approval)}
                variant="subtle"
              >
                {approval?.fullName}
              </Button>
              {openedApproval === approval?.employeeTimecardId && <TimeCardMobile data={data} openedApproval={openedApproval} />}
            </>
          );
        })
      )}
    </BannerCard>
  );
};

export default TimeCardApprovalMobile;
