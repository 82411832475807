import { Modal, Table } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Card, Group, Badge, Text } from "@mantine/core";
import TableActionButton from "../../common/components/TableActionButton";
import { asDate } from "../../common/constants/helpers";
import useMediaQuery from "../../common/hooks/useMediaQuery";
import { useGetEmployeeLeaveQuery, useGetLeaveDetailQuery } from "../../state/portalApi";
import { RootState } from "../../store";
import moment from "moment";

const useLeave = (fromEmployeeLeave, selectedEmployeeId) => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const [selectedId, setSelectedId] = useState<number>(null);
  const { loggedInEmployeeId } = useSelector((state: RootState) => state.global);
  const { data, isLoading } = useGetEmployeeLeaveQuery(fromEmployeeLeave ? selectedEmployeeId : loggedInEmployeeId);
  const { data: detail, isLoading: detailLoading } = useGetLeaveDetailQuery(
    { employeeId: fromEmployeeLeave ? selectedEmployeeId : loggedInEmployeeId, leaveId: selectedId },
    { skip: selectedId ? false : true }
  );

  const detailColumns = [
    { key: "date", dataIndex: "accrualDate", title: "Date", ...asDate },
    { key: "class", dataIndex: "accrualClass", title: "Class" },
    { key: "description", dataIndex: "accrualClassDescription", title: "Class Description" },
    { key: "entryDescription", dataIndex: "accrualDescription", title: "Entry Description" },
    { key: "amount", dataIndex: "amount", title: "Amount", render: (balance) => `${balance.toFixed(3)} hrs.` },
    { key: "runningTotal", dataIndex: "runningTotal", title: "Running Total", render: (balance) => `${balance.toFixed(3)} hrs.` }
  ];

  const handleViewDetail = (detailId: number) => {
    setSelectedId(detailId);
    Modal.info({
      content: isMobile ? (
        React.Children.toArray(
          detail?.map((leaveDetail) => {
            return (
              <>
                <br />
                <Card shadow="sm" padding="md">
                  <Group position="apart" style={{ marginBottom: 5, marginTop: 2 }}>
                    <Text weight={500}>{leaveDetail?.accrualClassDescription}</Text>
                    <Badge color="pink" variant="light">
                      {leaveDetail?.runningTotal} hrs
                    </Badge>
                  </Group>
                  <Text size="sm" style={{ lineHeight: 1.5 }}>
                    {leaveDetail?.accrualDescription}
                  </Text>
                  <Text size="sm" style={{ lineHeight: 1.5 }}>
                    Date: {moment(leaveDetail?.accrualDate).format("MM-DD-yyyy")}
                  </Text>
                  <Text size="sm" style={{ lineHeight: 1.5 }}>
                    Amount: {leaveDetail?.amount} hours
                  </Text>
                </Card>
              </>
            );
          })
        )
      ) : (
        <Table columns={detailColumns} dataSource={detail} loading={detailLoading} size="small" />
      ),
      width: 800,
      title: "Leave Details"
    });
  };

  const columns = [
    { key: "class", dataIndex: "accrualClass", title: "Class" },
    { key: "description", dataIndex: "accrualClassDescription", title: "Description" },
    { key: "balance", dataIndex: "accruedBalance", title: "Balance", render: (balance) => `${balance.toFixed(3)} hrs.` },
    { key: "ytdEarned", dataIndex: "accrualEarnedYTD", title: "YTD Earned", render: (balance) => `${balance.toFixed(3)} hrs.` },
    { key: "ytdUsed", dataIndex: "accrualUsedYTD", title: "YTD Used", render: (balance) => `${balance.toFixed(3)} hrs.` },
    { key: "id", dataIndex: "accrualClassID", title: "", render: (id) => <TableActionButton onClick={() => handleViewDetail(id)} /> }
  ];

  return {
    data,
    loading: isLoading,
    columns,
    handleViewDetail
  };
};

export default useLeave;
