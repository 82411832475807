import { useState } from "react";
import { useSelector } from "react-redux";
import { formattedDate } from "../../common/constants/helpers";
import { useGetBenefitsQuery, useGetDependentDetailsQuery } from "../../state/portalApi";
import { RootState } from "../../store";

const useBenefits = (fromEmployeeBenefits, selectedEmployeeId) => {
  const [selectedDep, setSelectedDep] = useState(null);
  const { loggedInEmployeeId } = useSelector((state: RootState) => state.global);
  const { data, isLoading } = useGetBenefitsQuery(fromEmployeeBenefits ? selectedEmployeeId : loggedInEmployeeId);
  const { data: detail, isLoading: detailLoading } = useGetDependentDetailsQuery(
    { employeeId: fromEmployeeBenefits ? selectedEmployeeId : loggedInEmployeeId, dependentId: selectedDep },
    { skip: selectedDep ? false : true }
  );

  const handleSelectDependant = (e) => {
    setSelectedDep(e.substr(2));
  };

  return {
    data,
    isLoading,
    handleSelectDependant,
    detail: detail ?? [],
    detailLoading: detailLoading
  };
};

export default useBenefits;
