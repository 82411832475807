import { useMediaQuery } from "@mantine/hooks";
import { Table } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import BannerCard from "../../../common/components/BannerCard";
import Page from "../../../common/components/Page";
import { clearSelectedEmployee } from "../../../state/globalStore";
import EmployeeListCard from "./EmployeeListCard";
import useEmployees from "./useEmployees";

const Employees = () => {
  const { data, cols, isLoading, viewEmployee, activeEmpLoading } = useEmployees();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    dispatch(clearSelectedEmployee());
  }, [dispatch]);

  return (
    <Page>
      <Page.Title title="Employees" icon="users" showBreadcrumb />
      <Page.Content>
        {isMobile ? (
          <EmployeeListCard data={data} viewEmployee={viewEmployee} loading={activeEmpLoading} listLoading={isLoading} />
        ) : (
          <BannerCard title="Employees">
            <Table size="small" loading={isLoading} dataSource={data} columns={cols} scroll={{ x: 720 }} />
          </BannerCard>
        )}
      </Page.Content>
    </Page>
  );
};

export default Employees;
