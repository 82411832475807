import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { Address } from "../../models/employee/employee-address";
import { ChainDetail } from "../../models/approval-chain/approval-chain-detail";

/**
 * Flattens a nested array or tree structure into a single array
 * @param into The array to be used
 * @param node The node you want to flatten
 * @returns A flattened array constructed from the provided nested array or tree
 */
export const flatten = (into, node) => {
  if (node == null) return into;
  if (Array.isArray(node)) return node.reduce(flatten, into);
  into.push(node);
  return flatten(into, node.children);
};

export const dateFormatList = ["MM/DD/YYYY", "MM-DD-YYYY", "YYYY-MM-DD", "YYYY/MM/DD"];

export const isValidId = (id: number) => id && id > 0;

export const isDateBefore = (thisDate: moment.Moment, checkDate: moment.Moment): boolean => thisDate.isBefore(checkDate);

export const formattedDate = (date) => (date && date !== "0001-01-01T00:00:00" ? moment(date).format("MM/DD/YYYY") : "N/A");
export const viewDate = (date) => (date ? moment(date) : null);

export const sumValues = (firstValue, secondValue) => {
  return firstValue + secondValue;
};

export const Months = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" }
];

export const asMoney: Partial<ColumnType<unknown>> = {
  align: "right",
  render: (val) => `$ ${val?.toFixed(2) ?? "0.00"}`
};

export const mobileFriendly = {
  responsive: ["xs", "s", "md"]
};

export const buildColumn = (columnName: string, columnTitle: string, render?: (e: unknown) => JSX.Element, ...rest) => {
  return {
    key: columnName,
    dataIndex: columnName,
    title: columnTitle,
    render: render,
    ...rest
  };
};

export const asDate: Partial<ColumnType<unknown>> = {
  render: (val) => (val ? moment(val).format("MM-DD-YYYY") : "N/A")
};

export const asDecimal: Partial<ColumnType<unknown>> = {
  align: "right",
  render: (val) => Number(val).toFixed(2)
};

export const defaultDateFormat = "MM-DD-YYYY";
export const defaultTimeFormat = "HH:mm:ss";
export const defaultISOFormat = "YYYY-MM-DDTHH:mm:ss[Z]";
export const defaultDateWithTime = "YYYY-MM-DDT12:00:00"

export const sortStrColumn = (value) => ({ sorter: (a, b) => b[value].localeCompare(a[value]) });
export const sortColumn = (value) => ({ sorter: (a, b) => a[value] - b[value] });

export const buildAddress = (address: Address) => {
  if (!address) return "N/A";

  return `${address?.addressLine1} ${address.addressLine2} ${address?.city}, ${address?.state} ${address?.zip}`.trim();
};

export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const securityQuestions = {
  1: "What is your mother's maiden name?",
  2: "What is the name of your first pet",
  3: "What was your first car?",
  4: "What elementry school did you attend?",
  5: "What is the name of the town in which you were born?",
  6: "What was the name of the first street you lived on?",
  7: "What was your high school mascot?",
  8: "What is your father's middle name?",
  9: "What was your first job?",
  10: "In what city did you meet your spouse?"
};

/**
 * Find a node within a tree data structure based on a specific property predicate
 * @param tree The primary, root, parent node of the tree.
 * @param nodesProp Name of the property within which the children are stored. Often called children.
 * @param prop The property you're trying to match. E.g., Id
 * @param value The value of the property that you're trying to match
 * @returns The node found within the tree
 */
export const searchTree = (tree, nodesProp, prop, value) => {
  let i,
    f = null; // iterator, found node
  if (Array.isArray(tree)) {
    // if entry object is array objects, check each object
    for (i = 0; i < tree.length; i++) {
      f = searchTree(tree[i], nodesProp, prop, value);
      if (f) {
        // if found matching object, return it.
        return f;
      }
    }
  } else if (typeof tree === "object") {
    // standard tree node (one root)
    if (tree[prop] !== undefined && tree[prop] === value) {
      return tree; // found matching node
    }
  }
  if (tree[nodesProp] !== undefined && tree[nodesProp].length > 0) {
    // if this is not maching node, search nodes, children (if prop exist and it is not empty)
    return searchTree(tree[nodesProp], nodesProp, prop, value);
  } else {
    return null; // node does not match and it neither have children
  }
};

/**
 * Finds a node in a tree and deletes it by filtering the childeren that belong to the parent found.
 * @param parent The root, parent, main node of the tree.
 * @param node The node you want to delete
 * @callback deleteNodeInTree
 */
export const deleteNodeInTree = (parent: ChainDetail, node: ChainDetail) => {
  if (!parent.children || parent.children.length <= 0) return;

  parent.children.forEach((child: ChainDetail) => {
    if (child.chainDetailId === node.chainDetailId) {
      // eslint-disable-next-line no-param-reassign
      parent.children = parent.children.filter((o) => o.chainDetailId !== node.chainDetailId);
      return;
    } else {
      deleteNodeInTree(child, node);
    }
  });
};

export const resetFilters = (clearFilters, confirm) => {
  clearFilters();
  confirm();
};

export const checkCase = (string) => {
  let updatedString = "";
  // eslint-disable-next-line no-param-reassign
  string = string.split("");
  string.forEach((element) => {
    if (!isNaN(element * 1)) {
      updatedString += element;
    } else {
      if (element == element.toUpperCase()) {
        updatedString += " " + element;
      }
      if (element == element.toLowerCase()) {
        updatedString += element;
      }
    }
  });
  return updatedString;
};

export enum TimecardStatus {
  None = 0,
  New,
  Submitted,
  InApproval,
  Approved,
  Rejected,
  Filed
}

export const filterBooleanData = [
  { text: "Yes", value: true },
  { text: "No", value: false }
];
