import { useMediaQuery } from "@mantine/hooks";
import { Table } from "antd";
import React from "react";
import BannerCard from "../../common/components/BannerCard";
import TimeCardApprovalMobile from "./timecard-approval-mobile";

const TimecardApprovalTable = ({ ...props }) => {
  const { data, cols, title, loading } = props;
  const isMobile = useMediaQuery("(max-width:768px)");

  return isMobile ? (
    <TimeCardApprovalMobile data={data} title={title} />
  ) : (
    <>
      <br />
      <BannerCard title={title}>
        <Table loading={loading} dataSource={data} columns={cols} size="small" bordered={false} scroll={{ x: 720 }} />
      </BannerCard>
    </>
  );
};

export default TimecardApprovalTable;
