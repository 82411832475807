import { Text } from "@mantine/core";
import { Statistic, Table } from "antd";
import React from "react";
import ActionCard from "../../common/components/action-card";
import BannerCard from "../../common/components/BannerCard";
import { buildAddress } from "../../common/constants/helpers";
import useMediaQuery from "../../common/hooks/useMediaQuery";
import useDashboard from "./useDashboard";

const DashboardAddresses = ({ ...props }) => {
  const { data, addressColumns, loading } = useDashboard();
  const { isMobile } = props;

  return isMobile ? (
    <BannerCard hideTitle title="">
      <ActionCard title="Address" badgeData="Primary">
        <Text size="sm" style={{ lineHeight: 1.5 }}>
          {buildAddress(data?.addresses[0])}
        </Text>
      </ActionCard>
    </BannerCard>
  ) : (
    <BannerCard title="Addresses">
      <Table loading={loading} dataSource={data?.addresses} size="small" columns={addressColumns} scroll={{ x: 480 }} />
    </BannerCard>
  );
};

export default DashboardAddresses;
