import { useSelector } from "react-redux";
import { asMoney } from "../../common/constants/helpers";
import { useGetPayHistoryDataMutation, useGetPayHistoryQuery, usePrintPayStubMutation } from "../../state/portalApi";
import { RootState } from "../../store";
import { saveAs } from "file-saver";

const usePayHistory = () => {
  const { loggedInEmployeeId } = useSelector((state: RootState) => state.global);
  const { data, isLoading } = useGetPayHistoryQuery(loggedInEmployeeId);
  const [getPayInfo, { data: payHistoryInfo, isLoading: infoLoading }] = useGetPayHistoryDataMutation();
  const [printPay] = usePrintPayStubMutation();

  const selectCheck = async (checkId) => {
    await getPayInfo({
      employeeId: loggedInEmployeeId,
      checkId: checkId
    }).unwrap();
  };

  const printPayStub = async () => {
    const response = await printPay(payHistoryInfo?.check?.historyId).unwrap();
    const base64Response = await fetch(`data:application/pdf;base64,${response.data}`);
    const blob = await base64Response.blob();
    const url = URL.createObjectURL(blob);
    window.open(url);
  };

  const payCols = [
    { key: "HoursCodeDisplay", dataIndex: "hoursCodeDisplay", title: "Code" },
    { key: "Hours", dataIndex: "hours", title: "Hours" },
    { key: "Amount", dataIndex: "amount", title: "Amount", ...asMoney },
    { key: "AmountYTD", dataIndex: "amountYTD", title: "Amount YTD", ...asMoney },
    { key: "Rate", dataIndex: "rate", title: "Pay Rate", ...asMoney }
  ];

  const leaveCols = [
    { key: "AccumulatedLeaveCodeDisplay", dataIndex: "accumulatedLeaveCodeDisplay", title: "Code" },
    { key: "Earned", dataIndex: "earned", title: "Earned", render: (num) => `${num} hrs.` },
    { key: "Used", dataIndex: "used", title: "Used", render: (num) => `${num} hrs.` },
    { key: "Balance", dataIndex: "balance", title: "Balance", render: (num) => `${num} hrs.` }
  ];

  const withholdingCols = [
    { key: "WithholdingDisplay", dataIndex: "withholdingDisplay", title: "Code" },
    { key: "Amount", dataIndex: "amount", title: "Amount", ...asMoney },
    { key: "AmountYTD", dataIndex: "amountYTD", title: "Amount YTD", ...asMoney }
  ];

  return {
    data: data?.checkHistory,
    isLoading,
    selectCheck,
    payHistoryInfo: payHistoryInfo?.check,
    infoLoading,
    payCols,
    leaveCols,
    withholdingCols,
    printPayStub
  };
};

export default usePayHistory;
