import React from "react";
import { Box, Text } from "@mantine/core";
import { CheckIcon, Cross1Icon } from "@modulz/radix-icons";

const PasswordRequirement = ({ meets, label }: { meets: boolean; label: string }) => {
  return (
    <Text color={meets ? "teal" : "red"} sx={{ display: "flex", alignItems: "center" }} mt={7} size="sm">
      {meets ? <CheckIcon /> : <Cross1Icon />} <Box ml={10}>{label}</Box>
    </Text>
  );
};

export const requirements = [
  { re: /[0-9]/, label: "Includes number" },
  { re: /[a-z]/, label: "Includes lowercase letter" },
  { re: /[A-Z]/, label: "Includes uppercase letter" },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Includes special symbol" }
];

export const getStrength = (password: string) => {
  let multiplier = password.length > 7 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
};

export default PasswordRequirement;
