import React, { useState } from "react";
import { Select } from "@mantine/core";
import ConfigCardForm from "./config-card-form";

const ConfigCard = ({ ...props }) => {
  const { data, handleEdit } = props;
  const [openedConfig, setOpenedConfig] = useState<number>(null);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const selectGroup = (configId) => {
    if (configId) {
      setOpenedConfig(Number(configId));
      setIsSelected(true);
      return;
    }

    setOpenedConfig(null);
    setIsSelected(false);
  };

  return (
    <>
      <Select
        radius="md"
        variant="filled"
        searchable
        placeholder="Select a config option"
        clearable
        onChange={(e) => selectGroup(e)}
        data={data?.map((config) => ({ value: config?.id?.toString(), label: config?.configOption1 })) || []}
      />
      <br />
      {isSelected ? (
        <ConfigCardForm config={data?.find((x) => x?.id === openedConfig)} handleEdit={handleEdit} />
      ) : (
        React.Children.toArray(
          data?.map((config) => {
            return <ConfigCardForm config={config} handleEdit={handleEdit} />;
          })
        )
      )}
    </>
  );
};

export default ConfigCard;
