import React from "react";
import { Card, Input, Space, Typography } from "antd";

const PageSummaryContext = React.createContext(null);

const usePageSummaryContext = () => {
  const context = React.useContext(PageSummaryContext);
  if (!context) {
    throw new Error("InputGroup compound components cannot be rendered outside the InputGroup component");
  }
  return context;
};

/**
 *
 * @param param0 Props for the title and subtitle on the page summary
 * @returns Title Component
 */
type TitleProps = { title: string; subtitle: string };
const Title = ({ ...props }: TitleProps) => {
  return (
    <>
      <Typography.Title level={4}>{props.title}</Typography.Title>
      <Typography.Text style={{ fontSize: 18, color: "#adb5bd" }}>{props.subtitle}</Typography.Text>
    </>
  );
};

/**
 *
 * @param param0 Children components to go in the detail section
 * @returns Details component
 */
const Details = ({ ...props }) => {
  return (
    <>
      <Card>
        <Space direction="vertical" size={16}>
          {props.children}
        </Space>
      </Card>
    </>
  );
};

/**
 *
 * @param param0 Notes on the given page
 * @returns Notes Component
 */
const Notes = ({ ...props }) => {
  return (
    <>
      <Input.TextArea rows={4} defaultValue={props.notes} />
    </>
  );
};

/**
 *
 * @param param0 A collection of Actions that can be taken on the entity
 * @returns Actions Component
 */
const Actions = ({ ...props }) => {
  return (
    <>
      <Space direction="vertical" size={16}>
        {props.children}
      </Space>
    </>
  );
};

/**
 *
 * @param props Accepts the props for its own children
 * @returns PageSummary
 */
const PageSummary = ({ ...props }) => {
  return (
    <PageSummaryContext.Provider value={props}>
      <Space direction="vertical" size={16}>
        {props.children}
      </Space>
    </PageSummaryContext.Provider>
  );
};

/**
 * Assign the PageSummary its compound component fragments
 */
PageSummary.Title = Title;
PageSummary.Details = Details;
PageSummary.Notes = Notes;
PageSummary.Actions = Actions;

export default PageSummary;
