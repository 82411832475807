import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Statistic, Tag, notification, PageHeader, Descriptions, Card, Typography, Popconfirm } from "antd";
import ActiveTag from "../../../common/components/ActiveTag";
import CleanButton from "../../../common/components/CleanButton";
import PageSummary from "../../../common/components/PageSummary";
import useMediaQuery from "../../../common/hooks/useMediaQuery";
import { RoleChangeRequest } from "../../../models/employee/role-change-request";
import { useChangeRoleMutation } from "../../../state/portalApi";
import { RootState } from "../../../store";
import useEmployees from "./useEmployees";

export const EmployeeDetailSummary = ({ ...props }) => {
  const { activeEmployee } = props;
  const [changeRole] = useChangeRoleMutation();
  const [mobileView, setMobileView] = useState<boolean>(false);
  const { loggedInEmployeeId } = useSelector((state: RootState) => state.global);
  const isMobile = useMediaQuery("(max-width:768px)");
  const { viewEmployee, handleDeleteRegistration } = useEmployees();

  const handleChangeRole = async (manager, admin) => {
    const request: RoleChangeRequest = {
      employeeId: activeEmployee?.employeeId,
      admin: admin,
      manager: manager
    };

    const response = await changeRole(request).unwrap();
    if (response.isSuccess) {
      notification.success({ message: response.message });
      viewEmployee(activeEmployee?.employeeId);
    } else {
      notification.error({ message: response.message });
    }
  };

  const toggleMobileView = () => {
    if (isMobile) {
      setMobileView(!mobileView);
    }
  };

  return (
    <Row>
      <Col xl={22} md={22} sm={23} xs={23}>
        {isMobile && (
          <CleanButton icon={mobileView ? "angle-double-down" : "angle-double-right"} onClick={toggleMobileView}>
            Summary Details
          </CleanButton>
        )}
        <PageSummary>
          {isMobile && mobileView ? (
            <Col span={24}>
              <Card>
                <PageHeader
                  title={<Typography.Title level={5}>{activeEmployee?.fullName}</Typography.Title>}
                  backIcon={false}
                  extra={[
                    <Row gutter={12}>
                      <Descriptions column={1}>
                        <Descriptions.Item>
                          <h4 style={{ fontSize: 18, color: "#adb5bd" }}>{activeEmployee?.jobTitle}</h4>
                        </Descriptions.Item>
                        <Descriptions.Item>
                          <CleanButton
                            disabled={!activeEmployee?.isRegistered}
                            style={{ width: "100%" }}
                            onClick={() => handleChangeRole(null, true)}
                            icon={activeEmployee?.isAdmin ? "toggle-on" : "toggle-off"}
                          >
                            {activeEmployee?.isAdmin ? "Disable" : "Enable"} Admin
                          </CleanButton>
                        </Descriptions.Item>
                        <Descriptions.Item>
                          <CleanButton
                            disabled={!activeEmployee?.isRegistered}
                            style={{ width: "100%" }}
                            onClick={() => handleChangeRole(true, null)}
                            icon={activeEmployee?.isManager ? "toggle-on" : "toggle-off"}
                          >
                            {activeEmployee?.isManager ? "Disable" : "Enable"} Manager
                          </CleanButton>
                        </Descriptions.Item>
                        <Descriptions.Item>
                          <CleanButton disabled={!activeEmployee?.isRegistered} style={{ width: "100%" }} icon="lock">
                            Reset Registration
                          </CleanButton>
                        </Descriptions.Item>
                      </Descriptions>
                    </Row>
                  ]}
                  footer={[<PageSummary.Notes />]}
                >
                  <Descriptions column={1}>
                    <Descriptions.Item label="Registration Status">
                      <ActiveTag value={activeEmployee?.isRegistered} />
                    </Descriptions.Item>
                    <Descriptions.Item label="Subordinates">
                      <Tag color="blue">{activeEmployee?.subordinates?.length ?? 0}</Tag>
                    </Descriptions.Item>
                  </Descriptions>
                </PageHeader>
              </Card>
            </Col>
          ) : (
            <>
              {!isMobile && (
                <>
                  <PageSummary.Title title={activeEmployee?.fullName} subtitle={activeEmployee?.jobTitle} />
                  <PageSummary.Details>
                    <Statistic
                      style={{ wordBreak: "break-all" }}
                      title={<span style={{ fontSize: 13.4 }}>Registration Status</span>}
                      valueRender={() => <ActiveTag value={activeEmployee?.isRegistered} />}
                    />
                    <Statistic title="Subordinates" valueRender={() => <Tag color="blue">{activeEmployee?.subordinates?.length ?? 0}</Tag>} />
                  </PageSummary.Details>
                </>
              )}
            </>
          )}

          {!isMobile && (
            <>
              <PageSummary.Notes />
              <PageSummary.Actions>
                <CleanButton
                  icon={activeEmployee?.isAdmin ? "toggle-on" : "toggle-off"}
                  onClick={() => handleChangeRole(null, true)}
                  disabled={!activeEmployee?.isRegistered}
                  style={{ width: "100%" }}
                >
                  {activeEmployee?.isAdmin ? "Disable" : "Enable"} Admin
                </CleanButton>
                <CleanButton
                  icon={activeEmployee?.isManager ? "toggle-on" : "toggle-off"}
                  onClick={() => handleChangeRole(true, null)}
                  disabled={!activeEmployee?.isRegistered}
                  style={{ width: "100%" }}
                >
                  {activeEmployee?.isManager ? "Disable" : "Enable"} Manager
                </CleanButton>
                {activeEmployee?.employeeId !== loggedInEmployeeId ? (
                  <Popconfirm title="User will be reset" onConfirm={handleDeleteRegistration}>
                    <CleanButton icon="lock" disabled={!activeEmployee?.isRegistered} style={{ width: "100%" }}>
                      Reset Registration
                    </CleanButton>
                  </Popconfirm>
                ) : null}
              </PageSummary.Actions>
            </>
          )}
        </PageSummary>
      </Col>
    </Row>
  );
};

export default EmployeeDetailSummary;
