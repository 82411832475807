import { useMediaQuery } from "@mantine/hooks";
import { Card, Collapse, Table } from "antd";
import React from "react";
import PayHistoryBreakdownCard from "./PayHistoryBreakdownCard";
import usePayHistory from "./usePayHistory";

export const PayHistoryBreakdown = ({ ...props }) => {
  const { payHistoryInfo, leaveCols, payCols, withholdingCols } = props;
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <>
      {isMobile ? (
        <PayHistoryBreakdownCard leaves={payHistoryInfo?.leave} pays={payHistoryInfo?.pays} withHoldings={payHistoryInfo?.withholdings} />
      ) : (
        <Card>
          <Collapse accordion>
            <Collapse.Panel key="1" header="Leave">
              <Table dataSource={payHistoryInfo?.leave} columns={leaveCols} size="small" />
            </Collapse.Panel>
            <Collapse.Panel key="2" header="Pay">
              <Table dataSource={payHistoryInfo?.pays} columns={payCols} size="small" />
            </Collapse.Panel>
            <Collapse.Panel key="3" header="Withholding">
              <Table dataSource={payHistoryInfo?.withholdings} columns={withholdingCols} size="small" />
            </Collapse.Panel>
          </Collapse>
        </Card>
      )}
    </>
  );
};

export default PayHistoryBreakdown;
