import React from "react";
import { Drawer, Layout } from "antd";
import "./mobile-nav-bar.less";
import CleanButton from "../../components/CleanButton";
import MobileUserMenu from "../desktop/mobile-user-menu";

const MobileNavbar = ({ ...props }) => {
  return (
    <Layout.Header className="navbar">
      <CleanButton style={{ right: 32 }} icon="bars" onClick={() => props.setVisibility(true)} />
      <MobileUserMenu />
      <Drawer title="Payroll Portal" placement="left" onClose={() => props.setVisibility(false)} visible={props.visibility}>
        {props.menu}
      </Drawer>
    </Layout.Header>
  );
};
export default MobileNavbar;
