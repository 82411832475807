import { Col, Descriptions, Empty, Row, Skeleton } from "antd";
import React from "react";
import BannerCard from "../../common/components/BannerCard";
import { formattedDate } from "../../common/constants/helpers";
import InsuranceCard from "./insurance-card";
import useBenefits from "./useBenefits";

const InsuranceCards = ({ ...props }) => {
  const { fromEmployeeBenefits, selectedEmployeeId } = props;
  const { data, isLoading } = useBenefits(fromEmployeeBenefits, selectedEmployeeId);

  return data?.insurance?.length > 0 ? (
    <Row gutter={[16, 16]}>
      {React.Children.toArray(
        data?.insurance.map((ins) => {
          return (
            <Col lg={12} md={24} sm={24} xs={24}>
              <BannerCard internal hideTitle title="">
                {isLoading ? <Skeleton active /> : <InsuranceCard ins={ins} />}
              </BannerCard>
            </Col>
          );
        })
      )}
    </Row>
  ) : (
    <Empty description="No insurance exist." />
  );
};

export default InsuranceCards;
