import React from "react";
import { Table } from "antd";
import { useMediaQuery } from "@mantine/hooks";
import BannerCard from "../../../common/components/BannerCard";
import Page from "../../../common/components/Page";
import useConfig from "./use-config";
import ConfigModal from "./config-modal";
import ConfigCard from "./config-card";

const Configuration = () => {
  const { data, isLoading, columns, handleSave, handleCancel, showModal, editObject, modalForm, handleEdit } = useConfig();
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <Page>
      <Page.Title title="Configuration" icon="cog" showBreadcrumb={!isMobile} />
      <Page.Content>
        {!isMobile ? (
          <BannerCard title="Config Options">
            <Table pagination={false} dataSource={data} columns={columns} size="small" loading={isLoading} scroll={{ x: 720 }} />
          </BannerCard>
        ) : (
          <ConfigCard data={data} handleEdit={handleEdit} />
        )}
        <ConfigModal showModal={showModal} handleCancel={handleCancel} handleSave={handleSave} editObject={editObject} modalForm={modalForm} />
      </Page.Content>
    </Page>
  );
};

export default Configuration;
