import { Table } from "antd";
import React from "react";
import BannerCard from "../../common/components/BannerCard";
import Page from "../../common/components/Page";
import useForms from "./useForms";

const FormNotifications = () => {
  const { acks, acksLoading, ackCols } = useForms();

  return (
    <Page>
      <Page.Title title="Acknowledgements" icon="bell" showBreadcrumb />
      <Page.Content>
        <BannerCard title="Acknowledgements">
          <Table loading={acksLoading} dataSource={acks} columns={ackCols} size="small" scroll={{ x: 720 }} />
        </BannerCard>
      </Page.Content>
    </Page>
  );
};

export default FormNotifications;
