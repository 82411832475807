import { Empty, Modal, Select, Space, Table, Switch } from "antd";
import React from "react";
import BannerCard from "../../common/components/BannerCard";
import Page from "../../common/components/Page";
import TimecardApprovalTable from "./timecard-approval-table";
import useTimecardApproval from "./useTimecardApproval";
import { formattedDate } from "../../common/constants/helpers";

const TimecardApproval = () => {
  const {
    data,
    isLoading,
    columns,
    entriesData,
    isModalVisible,
    handleCloseModal,
    modalColumns,
    isLeaveModalVisible,
    leaveModalColumns,
    timePeriodsList,
    handleSelectTimecard,
    handleClearTimecard,
    handleApprovedFilter
  } = useTimecardApproval();

  return (
    <Page>
      <Page.Title title="Timecard Approval" icon="thumbs-up" />
      <Page.Content>
        <Space direction="vertical">
          <div style={{ display: "flex" }}>
            <Select
              placeholder="Please select a time period"
              onChange={handleSelectTimecard}
              options={timePeriodsList?.map((tp) => ({ value: tp?.timePeriodId, label: formattedDate(tp?.startDate) + " - " + formattedDate(tp?.endDate) })) || []}
              allowClear
              onClear={handleClearTimecard}
            />
            <span style={{ border: "1px solid grey", padding: "5px", marginLeft: "4px" }}>
              All <Switch onChange={handleApprovedFilter} /> Approved
            </span>
          </div>
          {data?.directApprovals.length > 0 ? (
            <TimecardApprovalTable data={data?.directApprovals} cols={columns} loading={isLoading} title="Direct Approvals" />
          ) : (
            <BannerCard title="" hideTitle>
              <Empty description="There are no direct approvals" />
            </BannerCard>
          )}
          {data?.altApprovals.length > 0 ? (
            <TimecardApprovalTable data={data?.altApprovals} cols={columns} loading={isLoading} title="Alternate Approvals" />
          ) : (
            <BannerCard title="" hideTitle>
              <Empty description="There are no alternate approvals" />
            </BannerCard>
          )}
          {data?.subApprovals.length > 0 ? (
            <TimecardApprovalTable data={data?.subApprovals} cols={columns} loading={isLoading} title="Substitute Approvals" />
          ) : (
            <BannerCard title="" hideTitle>
              <Empty description="There are no substitute approvals" />
            </BannerCard>
          )}
        </Space>
        <Modal width={720} okButtonProps={{ style: { display: "none" } }} cancelText="Close" visible={isModalVisible || isLeaveModalVisible} onCancel={handleCloseModal}>
          <br />
          <Table columns={isLeaveModalVisible ? leaveModalColumns : modalColumns} dataSource={entriesData} size="small" />
        </Modal>
      </Page.Content>
    </Page>
  );
};

export default TimecardApproval;
