import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Form, notification } from "antd";
import { EmployeeTimeCard } from "../../models/timecard/employee-timecard";
import { EmployeeTimeCardEntry } from "../../models/timecard/employee-timecard-entry";
import { PayCode } from "../../models/timecard/pay-code";
import { useGetTimecardInfoMutation, useGetTimecardsQuery, useMakeApprovalActionMutation, useSaveTimecardMutation, useGetPaycodesByIdMutation } from "../../state/portalApi";
import { RootState } from "../../store";
import { addEntry, removeEntry, setActiveTimecard, setIsDirty, setTotalHours, updateEntry } from "../../state/timecardStore";
import { TimePeriod } from "../../models/timecard/time-period";
import { TimecardActions } from "../../models/timecard/timecard-actions.enum";
import { ApprovalActionRequest } from "../../models/approvals/approval-action-request";
import { ServiceResponse } from "../../models/common/service-response";
import { calculateElapsedTime } from "./timecard-helpers";
import { defaultDateFormat, defaultDateWithTime, defaultISOFormat, defaultTimeFormat, formattedDate, TimecardStatus } from "../../common/constants/helpers";
import getConfigs from "../getConfigs";

/**
 * Handle logic for timecards.
 * @example const { canEdit } = useTimecard(boolean, number);
 * @param fromEmployeeTimeCard Is this hook being called from the timecard page, or elsewhere.
 * @param selectedEmployeeId The selected employee id for whom we are viewing the timecard.
 * @returns A hook to allow us to handle a lot of the UI logic for timecard manipulation.
 */
const useTimecard = (fromEmployeeTimeCard = false, selectedEmployeeId = null) => {
  const { loggedInEmployeeId, activeEmployee, selectedEmployee } = useSelector((state: RootState) => state.global);
  const { data: timePeriods, isLoading: timePeriodsLoading } = useGetTimecardsQuery(
    fromEmployeeTimeCard ? { employeeId: selectedEmployeeId, isCalledFromEmp: true } : { employeeId: loggedInEmployeeId, isCalledFromEmp: false }
  );
  const [getTimecardInfo, { data: result, isLoading: timecardInfoLoading }] = useGetTimecardInfoMutation();
  const [getPaycodesById, { data: departmentalPaycodes }] = useGetPaycodesByIdMutation();
  const [saveTimecard] = useSaveTimecardMutation();
  const [makeApprovalAction] = useMakeApprovalActionMutation();
  const { activeTimecard, isDirty, totalHours } = useSelector((state: RootState) => state.timecard);
  const [timeCardForm] = Form.useForm();
  const dispatch = useDispatch();
  const [isBlocking, setisBlocking] = useState<boolean>(false);
  const configurations = getConfigs(["TimecardStartHour", "TimecardStartMinute", "TimecardEndHour", "TimecardEndMinute", "EnableTimecardLunch", "TimecardLunchThreshold", "TimecardHoursPrecision"]);

  useEffect(() => {
    const aggregateHours = () => {
      const hours = activeTimecard?.entries?.reduce((sum, entry) => sum + entry?.laborHours, 0);
      dispatch(setTotalHours(hours));
    };

    aggregateHours();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTimecard?.entries]);

  useEffect(() => {
    if (activeTimecard && activeTimecard.timePeriodID) {
      const selectedTimePeroid = timePeriods?.timePeriods?.filter((e) => e?.timePeriodID === activeTimecard?.timePeriodID);
      if (selectedTimePeroid) {
        timeCardForm.setFieldsValue({ timeCard: `${formattedDate(selectedTimePeroid[0]?.startDate)} - ${formattedDate(selectedTimePeroid[0]?.endDate)}` });
      }
    } else {
      timeCardForm.resetFields();
    }
  }, [activeTimecard, timeCardForm, timePeriods?.timePeriods]);

  useEffect(() => {
    const fetchPaycodes = async () => {
      const toMatchData = selectedEmployee ? selectedEmployee?.departmentId : activeEmployee?.departmentId;
      if (toMatchData) await getPaycodesById(toMatchData).unwrap();
    };
    fetchPaycodes();
  }, [getPaycodesById, selectedEmployee, activeEmployee]);

  /**
   * Removes a timecard entry by its id and updates the state.
   * @param entryId The selected entry to remove
   */
  const handleRemoveEntry = (entryIndex: number) => {
    dispatch(removeEntry(entryIndex));
  };

  /**
   * @returns A filtered list of time periods which are less than or equal to 90 days old.
   * This also filters out results of timeperiods which are locked.
   */
  const currentTimePeriods = timePeriods?.timePeriods.filter((t: TimePeriod) => {
    const now = moment();
    const startDate = moment(t?.startDate);
    const diff = now.diff(startDate, "days");

    return diff <= 90 && !t.locked && t.timeCardStatusID !== TimecardStatus.Approved;
  });

  const includeApprovedTimePeriods = timePeriods?.timePeriods.filter((t: TimePeriod) => {
    const now = moment();
    const startDate = moment(t?.startDate);
    const diff = now.diff(startDate, "days");

    return diff <= 365 && !t.locked && t.timeCardStatusID <= TimecardStatus.Filed;
  });

  /**
   * Select a time period to view timecard entries for.
   * @param timePeriod The ID of the selected time period
   */
  const selectTimePeriod = async (timePeriod: number | string, isDirtyValue = false) => {
    if (timePeriod) {
      const timecardInfo = await getTimecardInfo({
        employeeId: fromEmployeeTimeCard === true ? selectedEmployeeId : loggedInEmployeeId,
        timePeriodId: timePeriod,
        isFk: fromEmployeeTimeCard ? true : false
      }).unwrap();

      dispatch(setActiveTimecard(timecardInfo));
      dispatch(setIsDirty(isDirtyValue));
    } else {
      dispatch(setIsDirty(false));
      dispatch(setActiveTimecard(null));
    }
  };

  /**
   *
   * @param startTime Timecard entry start time
   * @param endTime Timecard entry end time
   * @param takeLunch Timecard entry taking lunch. Boolean value.
   * @returns The amount of time between the start time and end time (factoring in the lunch deduction, should there be any) in hours.
   */
  const elapsedTime = (startTime, endTime, takeLunch: boolean) =>
    calculateElapsedTime(startTime, endTime, takeLunch, configurations.TimecardHoursPrecision, configurations.TimecardLunchThreshold, "30");

  /**
   * Calculate the amount of time that elapsed between the start and end times as a default for a new entry.
   * @returns The default hours for a new timecard entry
   */
  const calculateDefaulHours = (startTime, endTime, allowedLunch) => elapsedTime(startTime, endTime, allowedLunch);

  const calculateWorkDate = () => {
    const selectedTimeCard = includeApprovedTimePeriods?.find((x) => x?.timePeriodID === activeTimecard?.timePeriodID);
    let selectedDate = moment().isBetween(selectedTimeCard?.startDate, selectedTimeCard?.endDate)
      ? moment()
      : moment() < moment(selectedTimeCard?.startDate)
      ? moment(selectedTimeCard?.startDate)
      : moment() > moment(selectedTimeCard?.endDate)
      ? moment(selectedTimeCard?.endDate)
      : moment();
    return selectedDate;
  };

  /**
   * Creates a blank timecard entry to be manipulated.
   */
  const createBlankEntry = () => {
    const info: EmployeeTimeCard = result;

    const tempStartTime = moment(`${configurations.TimecardStartHour}:${configurations.TimecardStartMinute}:00`, defaultTimeFormat);
    const tempEndTime = moment(`${configurations.TimecardEndHour}:${configurations.TimecardEndMinute}:00`, defaultTimeFormat);
    const allowedLunch = configurations.EnableTimecardLunch?.toLowerCase() === "true";

    const timecardEntry: EmployeeTimeCardEntry = {
      workDate: calculateWorkDate().toISOString(),
      startTime: tempStartTime.toISOString(),
      endTime: tempEndTime.toISOString(),
      code: "",
      note: "",
      laborHours: calculateDefaulHours(tempStartTime, tempEndTime, allowedLunch),
      deductLunch: allowedLunch,
      runningTotal: 0,
      employeeID: fromEmployeeTimeCard ? selectedEmployeeId : loggedInEmployeeId,
      employeeTimeCardDetailID: undefined,
      employeeTimeCardID: info?.employeeTimeCardID,
      locationID: 0,
      payCodeID: timePeriods?.payCodes?.find((x: PayCode) => x.codeDescription === "REGULAR")?.payCodeId,
      taxID: 0,
      timeCardStatusID: 0,
      timePeriodID: info?.timePeriodID,
      locationName: "",
      payCodeDescription: "",
      timeCardStatus: "",
      key: Math.floor(Math.random() * 99999999999 + 1)
    };

    dispatch(addEntry(timecardEntry));
  };

  /**
   *
   * @param startTime Timecard entry start time
   * @param endTime Timecard entry end time
   * @param checkLunch Remove lunch hours. Boolean value.
   * @returns The amount of time that took place (in hours) between the start and end times.
   */
  const setLaborHours = (startTime, endTime, checkLunch = false) => {
    return elapsedTime(startTime, endTime, checkLunch);
  };

  /**
   * Sets the start time for the selected timecard entry
   * @param date Timecard entry start time
   * @param entry Timecard entry selected
   * @param manual Is a manual time override
   * @returns A promise to update the selected entry's start time
   */
  const setStartTime = (date: Date, entry: EmployeeTimeCardEntry, manual = false) => {
    if (!date || !entry) {
      return;
    }

    const time = moment(date).toISOString();
    dispatch(
      updateEntry({
        ...entry,
        startTime: time,
        laborHours: setLaborHours(moment(time).format(defaultTimeFormat), moment(entry?.endTime).format(defaultTimeFormat), entry?.deductLunch)
      })
    );
  };

  /**
   * Sets the end time for the selected timecard entry
   * @param date Timecard entry end time
   * @param entry Timecard entry selected
   * @param manual Is a manual time override
   * @returns A promise to update the selected entry's end time
   */
  const setEndTime = (date: Date, entry: EmployeeTimeCardEntry, manual = false) => {
    if (!date || !entry) {
      return;
    }

    const time = moment(date).toISOString();
    dispatch(
      updateEntry({
        ...entry,
        endTime: time,
        laborHours: setLaborHours(moment(entry?.startTime).format(defaultTimeFormat), moment(time).format(defaultTimeFormat), entry?.deductLunch)
      })
    );
  };

  /**
   * Sets the work date for the selected timecard entry
   * @param date Timecard entry work date
   * @param entry Timecard entry selected
   * @param manual Is a manual time override
   * @returns A promise to update the selected entry's work date
   */
  const setWorkDate = (date: Date, entry: EmployeeTimeCardEntry, manual = false) => {
    if (!date || !entry) {
      return;
    }

    dispatch(
      updateEntry({
        ...entry,
        workDate: moment(date).format(defaultDateWithTime) + "Z",
        laborHours: setLaborHours(moment(entry?.startTime).format(defaultTimeFormat), moment(entry?.endTime).format(defaultTimeFormat), entry?.deductLunch)
      })
    );
  };

  /**
   * Sets the paycode for the selected timecard entry
   * @param value Selected paycode ID as a string
   * @param entry Selected timecard entry
   * @returns A promise to update the paycode ID for the selected entry
   */
  const setCode = (value: string, entry: EmployeeTimeCardEntry) => {
    if (!value || !entry) {
      return;
    }

    dispatch(updateEntry({ ...entry, payCodeID: Number(value) }));
  };

  /**
   * Sets the location for the selected timecard entry
   * @param value Selected location ID as a string
   * @param entry Selected timecard entry
   * @returns A promise to update the location ID for the selected entry
   */
  const setLocation = (value: string, entry: EmployeeTimeCardEntry) => {
    if (!value || !entry) {
      return;
    }

    dispatch(updateEntry({ ...entry, locationID: Number(value) }));
  };

  /**
   * Set whether or not lunch hours are to be deducted form this timecard entry.
   * @param val Whether or not lunch is being taken for this entry
   * @param entry Selected timecard entry
   * @returns A promise to update the lunch toggle for the selected entry, and set the labor hours.
   */
  const setLunch = (val: boolean, entry: EmployeeTimeCardEntry) => {
    if (!entry) return;

    const workingMinutes = moment(entry.endTime).diff(moment(entry.startTime), "m");
    if (workingMinutes <= 30) {
      notification.error({ key: "lunchNotification", message: "You are not allowed for lunch" });
    }

    dispatch(
      updateEntry({
        ...entry,
        deductLunch: !entry.deductLunch,
        laborHours: setLaborHours(moment(entry?.startTime).format("HH:mm:ss"), moment(entry?.endTime).format("HH:mm:ss"), !entry.deductLunch)
      })
    );
  };

  /**
   * Sets the note for the selected timecard entry
   * @param e The note for the selected entry
   * @param entry Selected timecard entry
   * @returns A promise to update the note for the selected entry
   */
  const setNote = (e: any, entry: EmployeeTimeCardEntry) => {
    if (!e || !entry) {
      return;
    }

    dispatch(updateEntry({ ...entry, note: e.target.value }));
  };

  /**
   *  Validates the timecard entries to make sure they are able to be submitted.
   * @returns A response object confirming whether or not the operation was successful,
   * and a message (success or failure) to explain what happened.
   */
  const validateTimecardEntries = () => {
    const tempActiveTimecard = { ...activeTimecard, entries: [] };
    let hasError = "";

    activeTimecard?.entries.forEach((e: EmployeeTimeCardEntry) => {
      const startTime = moment(e?.startTime);
      const endTime = moment(e?.endTime);

      if (endTime.isBefore(startTime)) {
        hasError = "You cannot have a timecard entry where the end time is before the start time.";
      }

      if (e?.laborHours > 24) {
        hasError = "You cannot have an entry where you work more than 24 hours in a day. An issue has occurred. Try deleting and recreating the timecard entry in question.";
      }

      tempActiveTimecard?.entries?.push({ ...e, startTime: moment(e.startTime).format(defaultISOFormat), endTime: moment(e.endTime).format(defaultISOFormat) });
    });

    return { message: hasError, data: tempActiveTimecard };
  };

  /**
   * Update the state to allow the user to edit the timecard entries.
   */
  const allowEdit = async (): Promise<void> => {
    dispatch(setIsDirty(!isDirty));
    setisBlocking(true);
  };

  /**
   * Process a timecard approval action: E.g., Submit, Approve, Reject.
   * @param response Service response for a timecard approval action
   * @param overrideMessage A message to send that did not come from the service
   */
  const handleActionResponse = async (response: ServiceResponse, overrideMessage?: string, isDirtyValue = false) => {
    if (overrideMessage) {
      notification.success({ message: overrideMessage });
    } else if (response?.isSuccess) {
      notification.success({ message: response?.message });
    } else {
      notification.error({ message: response?.message });
    }

    dispatch(setIsDirty(isDirtyValue));
    await selectTimePeriod(activeTimecard?.timePeriodID, isDirtyValue);
  };

  /**
   * Saves a timecard record to the database and refetches the server cache for the selected timecard.
   * Then, calls @method handleActionResponse to process the response.
   */
  const validateTimeOverlap = () => {
    let error = false;
    const data = validateTimecardEntries();
    const values = data?.data?.entries.filter((v, i, a) => a.indexOf(v) === i);
    const tempDate = [];
    data?.data?.entries.forEach((date) => {
      if (!tempDate.includes(moment(date.workDate).format(defaultDateFormat))) {
        tempDate.push(moment(date.workDate).format(defaultDateFormat));
      }
    });
    tempDate.forEach((x) => {
      let startTime = "";
      let endTime = "";
      data?.data?.entries.forEach((e) => {
        if (x === moment(e?.workDate).format(defaultDateFormat)) {
          if (!startTime) {
            startTime = moment(e?.startTime).utc().format(defaultTimeFormat);
            endTime = moment(e?.endTime).utc().format(defaultTimeFormat);
          } else {
            if (
              moment(e?.startTime).isBetween(moment(moment(startTime, defaultTimeFormat).format(defaultISOFormat)), moment(moment(endTime, defaultTimeFormat).format(defaultISOFormat))) ||
              moment(e?.endTime).isBetween(moment(moment(startTime, defaultTimeFormat).format(defaultISOFormat)), moment(moment(endTime, defaultTimeFormat).format(defaultISOFormat)))
            ) {
              error = x;
            }
          }
        }
      });
    });
    return error;
  };
  const handleSaveTimecard = async () => {
    const validationResult = validateTimecardEntries();
    if (validationResult.message) {
      notification.error({ message: validationResult.message });
      return false;
    }

    const overlapDateData = validateTimeOverlap();
    if (overlapDateData) {
      notification.error({ message: `Time is overlapping for ${overlapDateData} date` });
      return false;
    }
    const saveResponse = await saveTimecard(validationResult.data).unwrap();

    await handleActionResponse(saveResponse);

    setisBlocking(false);
    dispatch(setIsDirty(false));
    dispatch(setActiveTimecard(activeTimecard));
    return true;
  };

  /**
   * Submits a timecard record to the database and refetches the server cache for the selected timecard.
   * Then, calls @method handleActionResponse to process the response.
   */
  const handleSubmitTimecard = async (): Promise<void> => {
    let timecardInfo: EmployeeTimeCard = null;
    if (isDirty) {
      if (!(await handleSaveTimecard())) {
        return;
      }
    }

    const validationResult = validateTimecardEntries();
    if (validationResult.message) return notification.error({ message: validationResult.message });

    if (!activeTimecard?.employeeTimeCardID)
      timecardInfo = await getTimecardInfo({
        employeeId: fromEmployeeTimeCard === true ? selectedEmployeeId : loggedInEmployeeId,
        timePeriodId: activeTimecard.timePeriodID,
        isFk: fromEmployeeTimeCard ? true : false
      }).unwrap();

    const actionRequest: ApprovalActionRequest = {
      timecardId: activeTimecard?.employeeTimeCardID || timecardInfo.employeeTimeCardID,
      action: TimecardActions.Submit,
      note: "",
      actionEmployeeId: fromEmployeeTimeCard ? selectedEmployeeId : loggedInEmployeeId
    };

    const submitResponse = await makeApprovalAction(actionRequest).unwrap();
    await handleActionResponse(submitResponse);
  };

  /**
   * Unsubmits a timecard record to the database and refetches the server cache for the selected timecard.
   * Then, calls @method handleActionResponse to process the response.
   */
  const handleUnsubmitTimecard = async (): Promise<void> => {
    const actionRequest: ApprovalActionRequest = {
      timecardId: activeTimecard?.employeeTimeCardID,
      action: TimecardActions.Save,
      note: "",
      actionEmployeeId: fromEmployeeTimeCard ? selectedEmployeeId : loggedInEmployeeId
    };

    const undoSubmitResponse = await makeApprovalAction(actionRequest).unwrap();
    await handleActionResponse(undoSubmitResponse, "Timecard successfully unsubmitted.", true);
  };

  /**
   * Check if timecard can be edited.
   * @returns Whether or not we can edit this timecard.
   */
  const canEdit = (): boolean => {
    if (activeTimecard) {
      const timecardStatus = activeTimecard?.timeCardStatusId;
      return timecardStatus === 0 || timecardStatus === 1 || timecardStatus === 5;
    }

    return false;
  };

  /**
   * Check if timecard can be unsubmitted.
   * @returns Whether or not we can unsubmit this timecard.
   */
  const canUnsubmit = (): boolean => {
    if (activeTimecard) {
      const timecardStatus = activeTimecard?.timeCardStatusId;
      return timecardStatus === 2 || timecardStatus === 3;
    }

    return false;
  };

  /**
   * Check if timecard can be submitted.
   * @returns Whether or not we can submit this timecard.
   */
  const canSubmit = (): boolean => {
    if (activeTimecard) {
      const timecardStatus = activeTimecard?.timeCardStatusId;
      return timecardStatus === 0 || timecardStatus === 1 || timecardStatus === 5;
    }

    return false;
  };

  /**
   * Create a timecard entry, assigning it all of the properties of the copied entry and creating a unique
   * key which will be used to handle the timecard entry until it's saved in the database and assigned a detail id.
   * @param id The id of the selected timecard entry we are trying to copy.
   * @param entry The entry we are trying to copy
   */
  const handleCopyEntry = (id, entry: EmployeeTimeCardEntry) => {
    const newEntry: EmployeeTimeCardEntry = { ...entry, employeeTimeCardDetailID: undefined, key: Math.floor(Math.random() * 99999999999 + 1) };

    dispatch(addEntry(newEntry));
  };

  return {
    currentTimePeriods,
    timePeriods: timePeriods?.timePeriods,
    locations: timePeriods?.locations,
    paycodes: departmentalPaycodes?.data,
    timecardsLoading: timePeriodsLoading,
    timecardInfo: result,
    timecardInfoLoading,
    canEdit,
    canUnsubmit,
    canSubmit,
    selectTimePeriod,
    createBlankEntry,
    setWorkDate,
    setStartTime,
    setEndTime,
    setCode,
    setLocation,
    setLunch,
    handleSaveTimecard,
    allowEdit,
    handleSubmitTimecard,
    handleUnsubmitTimecard,
    isRejected: activeTimecard?.timeCardStatusId === 5,
    handleCopyEntry,
    handleRemoveEntry,
    isDirty,
    isBlocking,
    setisBlocking,
    activeTimecard,
    totalHours,
    timeCardForm,
    setNote,
    includeApprovedTimePeriods
  };
};

export default useTimecard;
