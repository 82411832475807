import { useSelector } from "react-redux";
import { asDate } from "../../common/constants/helpers";
import { useGetInventoryQuery } from "../../state/portalApi";
import { RootState } from "../../store";

const useInventory = () => {
  const { loggedInEmployeeId } = useSelector((state: RootState) => state.global);
  const { data, isLoading } = useGetInventoryQuery(loggedInEmployeeId);

  const columns = [
    { key: "name", dataIndex: "itemName", title: "Item" },
    { key: "qty", dataIndex: "quantity", title: "Qty" },
    { key: "tag", dataIndex: "tagOrDescription", title: "Description" },
    { key: "receivedDate", dataIndex: "receivedDate", title: "Received Date", ...asDate }
  ];

  return {
    data: data?.data,
    isLoading,
    columns
  };
};

export default useInventory;
