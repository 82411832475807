import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Image, Spin } from "antd";
import { Center, Card } from "@mantine/core";
import logo from "../../../assets/logo2.png";
import useMediaQuery from "../../common/hooks/useMediaQuery";
import LoginForm from "./LoginForm";

const Login = () => {
  const history = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const isMobile = useMediaQuery("(max-width:768px)");

  const handleSubmit = async () => {
    // const newConnection = new HubConnectionBuilder()
    //   .withUrl(payrollPortalBaseUrl.substring(0, payrollPortalBaseUrl.indexOf("/api")) + "/hubs/notifications")
    //   .configureLogging(LogLevel.Information)
    //   .withAutomaticReconnect()
    //   .build();

    // await newConnection.start();
    // dispatch(setHub(newConnection));

    history("/dashboard");
  };

  return (
    <Center style={{ height: 750 }}>
      <Card shadow="sm" padding="lg" style={{ width: isMobile ? "90%" : "50%" }}>
        {loading ? (
          <Spin />
        ) : (
          <>
            <Center>
              <Image src={logo} preview={false} height={isMobile ? 150 : 350} />
            </Center>
            <LoginForm onSubmit={handleSubmit} loading={loading} />
          </>
        )}
      </Card>
    </Center>
  );
};

export default Login;
