import React, { useEffect, useState } from "react";
import { Button, Card, Grid, Modal } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useTimecardApproval from "./useTimecardApproval";
import TimecardApprovalEntry from "./timecard-approval-entries";
import TimecardApprovalLeaves from "./timecard-approval-leaves";

const TimeCardMobile = ({ ...props }) => {
  const { openedApproval, data } = props;
  const { renderStepper, setEmpFK, leaveData, leaveLoading, empFK } = useTimecardApproval();
  const [individualTimecard, setIndividualTimecard] = useState<any>();
  const [opened, setOpened] = useState<boolean>(false);
  const [openedLeaves, setOpenedLeaves] = useState<boolean>(false);

  useEffect(() => {
    const updatedData = data?.find((x) => openedApproval === x.employeeTimecardId);
    setIndividualTimecard(updatedData);
  }, [data, openedApproval]);

  useEffect(() => {
    if (!leaveLoading && leaveData.length) {
      setOpenedLeaves(true);
    }
  }, [empFK, leaveData, leaveLoading]);

  const handleSetLeavesData = async () => {
    setOpenedLeaves(true);
    setEmpFK(individualTimecard?.employeeFK);
  };

  return (
    <Card
      sx={(t) => ({
        margin: t.spacing.lg,
        padding: t.spacing.sm
      })}
      radius="md"
    >
      <Grid gutter="sm" grow align="center" columns={24}>
        <Grid.Col lg={24} md={24} sm={24} xs={24}>
          <Button radius="md" onClick={() => setOpened(true)} fullWidth leftIcon={<FontAwesomeIcon icon={["fas", "briefcase"]} />}>
            {individualTimecard?.hours + " " + "hrs"}
          </Button>
          <br />
          <br />
          <Button radius="md" onClick={() => handleSetLeavesData()} fullWidth leftIcon={<FontAwesomeIcon icon={["fas", "calendar"]} />}>
            Leaves
          </Button>
        </Grid.Col>
        <Grid.Col lg={24} md={24} sm={24} xs={24}>
          {renderStepper(individualTimecard?.actions)}
        </Grid.Col>
      </Grid>
      <Modal opened={opened} onClose={() => setOpened(false)} title="Timecard Breakdown">
        <TimecardApprovalEntry entries={individualTimecard?.entries} />
      </Modal>
      <Modal opened={openedLeaves} onClose={() => setOpenedLeaves(false)} title="Leaves Breakdown">
        <TimecardApprovalLeaves accumulatedLeaves={leaveData} />
      </Modal>
    </Card>
  );
};

export default TimeCardMobile;
