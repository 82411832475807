import { Button } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { CaretDownIcon, CaretUpIcon } from "@modulz/radix-icons";
import React, { useState } from "react";
import BannerCard from "../../common/components/BannerCard";
import { Form } from "../../models/forms/form";
import { FormGroup } from "../../models/forms/form-group";
import { useSaveFormMutation } from "../../state/portalApi";
import FormGroupCard from "./form-group-card";
import FormTable from "./form-table";
import useForms from "./useForms";

const FormList = ({ ...props }) => {
  const { forms } = props;
  const { handlePrintForm, handleAcknowledgeForm } = useForms();
  const [saveForm, { isLoading }] = useSaveFormMutation();
  const isMobile = useMediaQuery("(max-width:768px)");
  const [openedGroup, setOpenedGroup] = useState<number>(null);

  const handleDeleteForm = async (row) => {
    const updatedForm: Form = {
      ...row,
      formMessage: { isDeleted: true, messageCode: "", messageDescription: "", messageText: "" },
      isDeleted: true
    };

    await saveForm(updatedForm).unwrap();
  };

  const selectGroup = (group: FormGroup) => {
    if (openedGroup === group?.id) {
      selectGroup(null);
      return;
    }

    setOpenedGroup(group?.id);
  };

  return (
    <BannerCard title="" hideTitle internal={!isMobile}>
      {React.Children.toArray(
        forms?.map((formGroup: FormGroup) => {
          return isMobile ? (
            <>
              <Button
                radius="md"
                style={{ padding: 10 }}
                fullWidth
                leftIcon={openedGroup === formGroup?.id ? <CaretUpIcon /> : <CaretDownIcon />}
                onClick={() => selectGroup(formGroup)}
                variant="subtle"
              >
                {formGroup?.name}
              </Button>
              {openedGroup === formGroup?.id && <FormGroupCard forms={formGroup?.forms} handleDeleteForm={handleDeleteForm} handlePrintForm={handlePrintForm} />}
            </>
          ) : (
            <>
              <br />
              <BannerCard title={formGroup?.name}>
                <FormTable formGroup={formGroup} handlePrintForm={handlePrintForm} isLoading={isLoading} handleDeleteForm={handleDeleteForm} handleAcknowledgeForm={handleAcknowledgeForm} />
              </BannerCard>
            </>
          );
        })
      )}
    </BannerCard>
  );
};

export default FormList;
