import { Popconfirm, Table } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import CleanButton from "../../common/components/CleanButton";
import { FormGroup } from "../../models/forms/form-group";
import { RootState } from "../../store";

type FormTableProps = {
  handlePrintForm: (e) => void;
  handleDeleteForm: (e) => void;
  formGroup: FormGroup;
  isLoading: boolean;
  handleAcknowledgeForm: (e) => void;
};
const FormTable = ({ ...props }: FormTableProps) => {
  const { handlePrintForm, handleDeleteForm, formGroup, isLoading, handleAcknowledgeForm } = props;
  const { activeEmployee } = useSelector((state: RootState) => state.global);

  return (
    <Table
      size="small"
      dataSource={formGroup?.forms}
      scroll={{ x: 720 }}
      columns={[
        {
          dataIndex: "formName",
          title: "Form Name",
          render: (val, row) => {
            return <a onClick={() => handlePrintForm(row)}>{val}</a>;
          }
        },
        { dataIndex: "formDescription", title: "Description", ellipsis: true },
        {
          dataIndex: "formListId",
          title: "Action",
          width: 160,
          render: (id, row) => {
            return (
              <>
                {row.requiresAcknowledgement && row.isAcknowledged === false && (
                  <Popconfirm okText="Acknowledge" onConfirm={() => handleAcknowledgeForm(row)} title={row.ackMessage}>
                    <CleanButton type="link" icon="bell" tooltip="Acknowledgement" />
                  </Popconfirm>
                )}
                <CleanButton loading={isLoading} type="link" style={{ marginLeft: "5px" }} icon="download" onClick={() => handlePrintForm(row)} tooltip="Download" />
                {activeEmployee?.isAdmin || activeEmployee?.isManager ? (
                  <Popconfirm onConfirm={() => handleDeleteForm(row)} title="Form will be deleted">
                    <CleanButton loading={isLoading} type="link" style={{ marginLeft: "5px" }} icon="trash" tooltip="Delete" />
                  </Popconfirm>
                ) : null}
              </>
            );
          }
        }
      ]}
    />
  );
};

export default FormTable;
