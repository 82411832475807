import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { notification } from "antd";
import BooleanTag from "../../../common/components/BooleanTag";
import ColumnSearch from "../../../common/components/ColumnSearch";
import TableActionButton from "../../../common/components/TableActionButton";
import { filterBooleanData, resetFilters, sortColumn, sortStrColumn } from "../../../common/constants/helpers";
import { ServiceResponse } from "../../../models/common/service-response";
import { AddSubstituteRequest } from "../../../models/employee/add-substitute-request";
import { Employee } from "../../../models/employee/employee";
import { setSelectedEmployee, setSubstituteApprovers } from "../../../state/globalStore";
import {
  useAddSubsituteMutation,
  useDeleteRegistrationMutation,
  useGetActiveEmployeeInfoMutation,
  useGetEmployeeListQuery,
  useGetEmployeeSubsitutesMutation,
  useRemoveSubsituteMutation
} from "../../../state/portalApi";
import { RootState } from "../../../store";

const useEmployees = () => {
  const [selectedSub, setSelectedSub] = useState<number>(null);
  const { data, isLoading } = useGetEmployeeListQuery(undefined);
  const history = useNavigate();
  const dispatch = useDispatch();
  const [getEmployeeInfo, { isLoading: activeEmpLoading }] = useGetActiveEmployeeInfoMutation();
  const [getSubstitutes] = useGetEmployeeSubsitutesMutation();
  const [addSubstitute] = useAddSubsituteMutation();
  const [removeSubstitute] = useRemoveSubsituteMutation();
  const [deleteRegistration] = useDeleteRegistrationMutation();

  const { selectedEmployee } = useSelector((state: RootState) => state.global);

  const getEmployeeSubs = async (employeeId) => {
    const subs = await getSubstitutes(employeeId).unwrap();
    dispatch(setSubstituteApprovers(subs?.data));
  };

  const viewEmployee = async (employeeId: number) => {
    const employeeResponse: ServiceResponse<Employee> = await getEmployeeInfo({ employeeId: employeeId, isCalledFromEmp: true }).unwrap();
    if (employeeResponse.isSuccess) {
      dispatch(setSelectedEmployee(employeeResponse?.data));
      await getEmployeeSubs(employeeId);
      history("/employees/detail");
    } else {
      notification.error({ message: employeeResponse?.message });
    }
  };

  const reset = () => {
    setSelectedSub(null);
  };

  const handleAddSub = async () => {
    if (!selectedSub) {
      return notification.error({ message: "Select a substitute first" });
    }
    const request: AddSubstituteRequest = {
      employeeId: selectedEmployee?.employeeId,
      substituteEmployeeId: selectedSub
    };

    const addResponse = await addSubstitute(request).unwrap();
    if (!addResponse.isSuccess) {
      notification.error({ message: addResponse.message });
    } else {
      notification.success({ message: addResponse.message });
    }

    await getEmployeeSubs(request.employeeId);
    reset();
  };

  const handleSetApprover = (empId) => {
    setSelectedSub(Number(empId));
  };

  const handleRemoveSub = async (employeeId) => {
    const request: AddSubstituteRequest = {
      employeeId: selectedEmployee?.employeeId,
      substituteEmployeeId: employeeId
    };

    const removeResponse = await removeSubstitute(request).unwrap();
    if (!removeResponse.isSuccess) {
      notification.error({ message: removeResponse.message });
    } else {
      notification.success({ message: removeResponse.message });
    }

    await getEmployeeSubs(request.employeeId);
    reset();
  };

  const handleDeleteRegistration = async () => {
    const deleteResponse = await deleteRegistration(selectedEmployee?.employeeId).unwrap();
    if (deleteResponse.isSuccess) {
      notification.success({ message: deleteResponse.message });
      history("/employees");
    } else {
      notification.error({ message: deleteResponse.message });
    }
  };

  const cols = [
    { key: "FullName", dataIndex: "fullName", title: "Name", ...ColumnSearch("fullName", "Name", resetFilters), ...sortStrColumn("fullName") },
    { key: "employeeNumber", dataIndex: "employeeNumber", title: "Employee Number", ...sortStrColumn("employeeNumber") },
    {
      key: "isRegisteredUser",
      dataIndex: "isRegisteredUser",
      title: "Registered",
      render: (val) => <BooleanTag value={val} />,
      ...sortColumn("isRegisteredUser"),
      filters: filterBooleanData,
      onFilter: (value, record) => record?.isRegisteredUser === value
    },
    {
      key: "isManager",
      dataIndex: "isManager",
      title: "Manager",
      render: (val) => <BooleanTag value={val} />,
      ...sortColumn("isManager"),
      filters: filterBooleanData,
      onFilter: (value, record) => record?.isManager === value
    },
    {
      key: "isAdmin",
      dataIndex: "isAdmin",
      title: "Admin",
      render: (val) => <BooleanTag value={val} />,
      ...sortColumn("isAdmin"),
      filters: filterBooleanData,
      onFilter: (value, record) => record?.isAdmin === value
    },
    {
      key: "isApprover",
      dataIndex: "isApprover",
      title: "Approver",
      render: (val) => <BooleanTag value={val} />,
      ...sortColumn("isApprover"),
      filters: filterBooleanData,
      onFilter: (value, record) => record?.isApprover === value
    },
    {
      key: "EmployeeID",
      dataIndex: "employeeID",
      title: "Actions",
      render: (e) => <TableActionButton onClick={() => viewEmployee(e)} />
    }
  ];

  return {
    data: data,
    isLoading,
    cols,
    viewEmployee,
    getEmployeeSubs,
    handleAddSub,
    handleRemoveSub,
    activeEmpLoading,
    handleDeleteRegistration,
    handleSetApprover
  };
};

export default useEmployees;
