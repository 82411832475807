import React from "react";
import { useNavigate } from "react-router";
import ReactIdleTimer from "react-idle-timer";
import { Modal } from "antd";
import Timer from "./Timer";

const IdleTimer = () => {
  const navigate = useNavigate();

  const handleOnIdle = () => {
    let secondsToGo = 61;
    const isLoggedIn = localStorage.getItem("token") ? true : false;
    if (isLoggedIn) {
      const sessionModal = Modal.confirm({
        title: <b>Your session is about to expire</b>,
        content: (
          <>
            <p>You have been idle for more then 20 minutes</p>
            <div style={{ textAlign: "center" }}>
              <Timer navigate={navigate} />
            </div>
          </>
        ),
        okText: "Continue",
        onOk() {},
        cancelText: "Logout",
        onCancel: handleLogout
      });
      const timer = setInterval(() => {
        secondsToGo -= 1;
      }, 1000);
      setTimeout(() => {
        clearInterval(timer);
        sessionModal.destroy();
      }, secondsToGo * 1000);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div>
      <ReactIdleTimer timeout={19 * 60 * 1000} onIdle={handleOnIdle} />
    </div>
  );
};

export default IdleTimer;
