import React, { useState } from "react";
import { useLocation } from "react-router";
import moment from "moment";
import { Row, Col, Form, Select, Checkbox, Alert } from "antd";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { Button, Group, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { CaretDownIcon, CaretUpIcon } from "@modulz/radix-icons";
import BannerCard from "../../common/components/BannerCard";
import CleanButton from "../../common/components/CleanButton";
import Page from "../../common/components/Page";
import { defaultDateFormat, formattedDate } from "../../common/constants/helpers";
import { EmployeeTimeCardEntry } from "../../models/timecard/employee-timecard-entry";
import { TimePeriod } from "../../models/timecard/time-period";
import getConfigs from "../getConfigs";
import TimecardEntry from "./timecard-entry";
import TimecardToolbar from "./timecard-toolbar";
import useTimecard from "./useTimecard";

const Timecard = ({ ...props }) => {
  const { fromEmployeeTimeCard, selectedEmployeeId } = props;
  const {
    currentTimePeriods,
    selectTimePeriod,
    timecardInfo,
    handleUnsubmitTimecard,
    handleSubmitTimecard,
    handleSaveTimecard,
    canEdit,
    canSubmit,
    canUnsubmit,
    allowEdit,
    isDirty,
    isRejected,
    paycodes,
    locations,
    createBlankEntry,
    timeCardForm,
    includeApprovedTimePeriods,
    activeTimecard,
    totalHours
  } = useTimecard(fromEmployeeTimeCard, selectedEmployeeId);

  const [openedEntry, setOpenedEntry] = useState<number>(null);
  const isMobile = useMediaQuery("(max-width:768px)");
  const location = useLocation();
  const configurations = getConfigs(["TimecardAllowManualEntry"]);
  const [isIncluded, setIsIncluded] = useState<boolean>(false);

  const selectEntry = (entry: EmployeeTimeCardEntry) => {
    if (openedEntry === entry?.employeeTimeCardDetailID) {
      setOpenedEntry(null);
      return;
    }

    setOpenedEntry(entry?.employeeTimeCardDetailID);
    return;
  };

  currentTimePeriods?.sort((a, b) => {
    return moment(a.startDate).diff(b.startDate);
  });

  includeApprovedTimePeriods?.sort((a, b) => {
    return moment(a.startDate).diff(b.startDate);
  });

  const handleIncluded = (e) => {
    if (isIncluded) {
      timeCardForm.resetFields();
      selectTimePeriod(0, false);
    }
    setIsIncluded(e.target.checked);
  };

  const handleSelectTimeCard = (timecardID) => {
    selectTimePeriod(timecardID, false);
  };

  return (
    <Page>
      {!fromEmployeeTimeCard && <Page.Title title="Timecards" icon={faClock} showBreadcrumb />}
      <Page.Toolbar>
        <TimecardToolbar
          allowEdit={allowEdit}
          canEdit={canEdit}
          canSubmit={canSubmit}
          canUnsubmit={canUnsubmit}
          handleSaveTimecard={handleSaveTimecard}
          handleSubmitTimecard={handleSubmitTimecard}
          handleUnsubmitTimecard={handleUnsubmitTimecard}
          isDirty={isDirty}
          timecardInfo={timecardInfo}
          location={location}
        />
      </Page.Toolbar>
      <Page.Content>
        <Form form={timeCardForm}>
          <Row gutter={[16, 16]}>
            <Col lg={6} md={8} sm={24} xs={24}>
              <Form.Item name="timeCard">
                <Select
                  placeholder="Please select a time period"
                  onChange={handleSelectTimeCard}
                  options={
                    isIncluded
                      ? includeApprovedTimePeriods?.map((tp: TimePeriod) => ({ value: tp?.timePeriodID, label: formattedDate(tp?.startDate) + " - " + formattedDate(tp?.endDate) })) || []
                      : currentTimePeriods?.map((tp: TimePeriod) => ({ value: tp?.timePeriodID, label: formattedDate(tp?.startDate) + " - " + formattedDate(tp?.endDate) })) || []
                  }
                />
              </Form.Item>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              <Form.Item name="includeApproved" valuePropName="checked">
                <Checkbox onChange={handleIncluded}>Show Prior up to 365 days</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <br />
        <Row gutter={[16, 16]}>
          {activeTimecard && (
            <>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <Group direction="column">
                  <Text>Total Hours: {totalHours?.toFixed(2)}</Text>
                  <Text>{activeTimecard?.entries?.length} Entries</Text>
                  {canEdit() && configurations.TimecardAllowManualEntry?.toLowerCase() === "true" && isDirty && (
                    <CleanButton icon="plus" onClick={createBlankEntry}>
                      Add Entry
                    </CleanButton>
                  )}
                </Group>
              </Col>
              {isRejected && (
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <Alert message="Timecard Rejected" type="error" description={`Your timecard was rejected by your manager because of ${activeTimecard.note}`} closable />
                </Col>
              )}
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                {activeTimecard?.entries?.length > 0 && (
                  <BannerCard title="" hideTitle>
                    {React.Children.toArray(
                      activeTimecard?.entries?.map((entry: EmployeeTimeCardEntry, entryIndex) => {
                        return isMobile ? (
                          <>
                            <Button
                              radius="md"
                              style={{ padding: 10 }}
                              fullWidth
                              leftIcon={openedEntry === entry?.employeeTimeCardDetailID ? <CaretUpIcon /> : <CaretDownIcon />}
                              onClick={() => selectEntry(entry)}
                              variant="subtle"
                            >
                              {"Entry Date: " + moment(entry?.workDate).format(defaultDateFormat).toString() + " / Hours: " + entry?.laborHours}
                            </Button>
                            {openedEntry === entry?.employeeTimeCardDetailID && (
                              <TimecardEntry
                                key={String(entry?.workDate) + String(entryIndex)}
                                canEdit={canEdit() && isDirty}
                                fromEmployeeTimeCard={fromEmployeeTimeCard}
                                selectedEmployeeId={selectedEmployeeId}
                                entry={entry}
                                paycodes={paycodes}
                                locations={locations}
                                currentTimePeriods={isIncluded ? includeApprovedTimePeriods : currentTimePeriods}
                                entryIndex={entryIndex}
                              />
                            )}
                          </>
                        ) : (
                          <TimecardEntry
                            key={String(entry?.workDate) + String(entryIndex)}
                            canEdit={canEdit() && isDirty}
                            fromEmployeeTimeCard={fromEmployeeTimeCard}
                            selectedEmployeeId={selectedEmployeeId}
                            entry={entry}
                            paycodes={paycodes}
                            locations={locations}
                            currentTimePeriods={isIncluded ? includeApprovedTimePeriods : currentTimePeriods}
                            entryIndex={entryIndex}
                          />
                        );
                      })
                    )}
                  </BannerCard>
                )}
              </Col>
            </>
          )}
        </Row>
      </Page.Content>
    </Page>
  );
};

export default Timecard;
