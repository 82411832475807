import React from "react";
import moment from "moment";
import { EmployeeTimeCardEntry } from "../../models/timecard/employee-timecard-entry";
import { ActionIcon, Card, Checkbox, createStyles, Grid, InputWrapper, NumberInput, Select, TextInput, useMantineTheme } from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates";
import { PayCode } from "../../models/timecard/pay-code";
import { Location } from "../../models/timecard/location";
import { ClockIcon, CopyIcon, TrashIcon } from "@modulz/radix-icons";
import useTimecard from "./useTimecard";
import getConfigs from "../getConfigs";
import { calculateTimeDifference } from "./timecard-helpers";
import { defaultDateWithTime } from "../../common/constants/helpers";

type TimecardEntryProps = {
  entry: EmployeeTimeCardEntry;
  paycodes: PayCode[];
  locations: Location[];
  fromEmployeeTimeCard: boolean;
  selectedEmployeeId: number;
  canEdit: boolean;
  currentTimePeriods: any;
  entryIndex: number;
};

const useStyles = createStyles((theme) => {
  return {
    input: {
      backgroundColor: "#f5f6f7"
    }
  };
});

const TimecardEntry = ({ ...props }: TimecardEntryProps) => {
  const { entry, paycodes, locations, fromEmployeeTimeCard, selectedEmployeeId, canEdit, currentTimePeriods, entryIndex } = props;
  const { handleCopyEntry, setWorkDate, setStartTime, setEndTime, setLocation, setCode, setLunch, handleRemoveEntry, isDirty, activeTimecard, setNote } = useTimecard(
    fromEmployeeTimeCard,
    selectedEmployeeId
  );
  const theme = useMantineTheme();
  const configurations = getConfigs(["DisableHourEditing", "ShowTimecardLocation", "EnableTimecardLunch", "TimecardLunchThreshold"]);

  return (
    <Card
      sx={(t) => ({
        margin: t.spacing.lg,
        padding: t.spacing.sm
      })}
      radius="md"
      title={moment(entry?.workDate).toString()}
    >
      <Grid gutter="sm" grow align="center" columns={24}>
        <Grid.Col lg={3} md={3} sm={24} xs={24}>
          <InputWrapper label="Work Date">
            <DatePicker
              radius="md"
              variant="filled"
              icon={<ClockIcon color={theme.colors.brandSecondary[5]} />}
              disabled={!canEdit}
              defaultValue={moment(entry?.workDate).toDate()}
              onChange={(e) => setWorkDate(e, entry)}
              minDate={new Date(moment(currentTimePeriods?.find((x) => x?.timePeriodID === activeTimecard?.timePeriodID)?.startDate).format(defaultDateWithTime) + "Z")}
              maxDate={new Date(moment(currentTimePeriods?.find((x) => x?.timePeriodID === activeTimecard?.timePeriodID)?.endDate).format(defaultDateWithTime) + "Z")}
            />
          </InputWrapper>
        </Grid.Col>
        <Grid.Col lg={2} md={4} sm={24} xs={24}>
          <InputWrapper label="Start Time">
            <TimeInput
              key={String(isDirty)}
              radius="md"
              variant="filled"
              format={isDirty ? "24" : "12"}
              disabled={!canEdit || configurations.DisableHourEditing?.toLowerCase() === "true"}
              defaultValue={moment(entry?.startTime).toDate()}
              onChange={(e) => setStartTime(e, entry)}
            />
          </InputWrapper>
        </Grid.Col>
        <Grid.Col lg={2} md={4} sm={24} xs={24}>
          <InputWrapper label="End Time">
            <TimeInput
              key={String(isDirty)}
              radius="md"
              variant="filled"
              format={isDirty ? "24" : "12"}
              disabled={!canEdit || configurations.DisableHourEditing?.toLowerCase() === "true"}
              defaultValue={moment(entry?.endTime).toDate()}
              onChange={(e) => setEndTime(e, entry)}
            />
          </InputWrapper>
        </Grid.Col>
        <Grid.Col lg={4} md={4} sm={24} xs={24}>
          <InputWrapper label="Paycode">
            <Select
              radius="md"
              variant="filled"
              disabled={!canEdit}
              defaultValue={entry?.payCodeID?.toString()}
              placeholder="Select a paycode"
              searchable
              clearable={canEdit && isDirty}
              onChange={(e) => setCode(e, entry)}
              data={paycodes?.map((paycode: PayCode) => ({ value: paycode?.payCodeId?.toString(), label: `${paycode?.code} - ${paycode?.codeDescription}` })) || []}
            />
          </InputWrapper>
        </Grid.Col>
        {configurations.ShowTimecardLocation?.toLowerCase() === "true" && (
          <Grid.Col lg={4} md={2} sm={24} xs={24}>
            <InputWrapper label="Location">
              <Select
                radius="md"
                variant="filled"
                disabled={!canEdit}
                defaultValue={entry?.locationID?.toString()}
                searchable
                placeholder="Select a location"
                clearable={canEdit && isDirty}
                onChange={(e) => setLocation(e, entry)}
                data={locations?.map((location: Location) => ({ value: location?.locationID?.toString(), label: location?.locationName })) || []}
              />
            </InputWrapper>
          </Grid.Col>
        )}
        <Grid.Col lg={3} md={2} sm={24} xs={24}>
          <InputWrapper label="Note">
            <TextInput radius="md" variant="filled" disabled={!canEdit} defaultValue={entry?.note} onChange={(e) => setNote(e, entry)} />
          </InputWrapper>
        </Grid.Col>
        <Grid.Col lg={1.5} md={4} sm={6} xs={6}>
          <InputWrapper label="Hours">
            <NumberInput radius="md" variant="filled" disabled hideControls value={entry?.laborHours} precision={2} />
          </InputWrapper>
        </Grid.Col>
        <Grid.Col lg={1} md={4} sm={6} xs={6}>
          <InputWrapper style={{ marginBottom: "15px" }} label="Lunch">
            <Checkbox
              radius="md"
              disabled={!canEdit || configurations.EnableTimecardLunch?.toLowerCase() === "false"}
              checked={entry?.deductLunch && calculateTimeDifference(entry?.startTime, entry?.endTime) > 0.5 ? true : false}
              onChange={(e) => setLunch(e.target.checked, entry)}
            />
          </InputWrapper>
        </Grid.Col>
        <Grid.Col lg={1} md={4} sm={6} xs={6}>
          <ActionIcon radius="md" disabled={!canEdit} onClick={() => handleCopyEntry(entry.employeeTimeCardDetailID, entry)}>
            <CopyIcon />
          </ActionIcon>
        </Grid.Col>
        <Grid.Col lg={1} md={4} sm={6} xs={6}>
          <ActionIcon radius="md" disabled={!canEdit} onClick={() => handleRemoveEntry(entryIndex)}>
            <TrashIcon />
          </ActionIcon>
        </Grid.Col>
      </Grid>
    </Card>
  );
};

export default TimecardEntry;
