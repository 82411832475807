import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";

const BreadCrumbs = () => {
  const location = useLocation();
  const { pathname } = location;
  const pathnames = pathname.split("/").filter((item) => item);
  const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

  return pathname === "Dashboard" ? (
    <></>
  ) : (
    <Breadcrumb style={{ marginTop: "-20px" }}>
      <Breadcrumb.Item>
        <Link to="/dashboard">Dashboard</Link>
      </Breadcrumb.Item>
      {React.Children.toArray(
        pathnames.map((p, i) => {
          const routeTo = `/${pathnames.slice(0, i + 1).join("/")}`;
          const isLast = i === pathnames.length - 1;
          return isLast ? (
            <Breadcrumb.Item>{capitalize(p)}</Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item>
              <Link to={`${routeTo}`}>{capitalize(p)}</Link>
            </Breadcrumb.Item>
          );
        })
      )}
    </Breadcrumb>
  );
};

export default BreadCrumbs;
