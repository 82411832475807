import React from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Input, notification, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserLogin } from "../../models/authentication/user-login";
import { setToken } from "../../state/globalStore";
import { useTokenMutation } from "../../state/portalApi";
import { RootState } from "../../store";

const LoginForm = ({ ...props }) => {
  const [form] = Form.useForm();
  const [token, { isLoading: loginLoading }] = useTokenMutation();
  const dispatch = useDispatch();
  const history = useNavigate();
  const { loading } = useSelector((state: RootState) => state.global);

  const handleRegister = () => history("/register");
  const handleForgetPassword = () => history("/forget");

  const handleSubmit = async () => {
    const values = await form.validateFields();
    if (values) {
      const userLogin: UserLogin = {
        password: values.password,
        username: values.username
      };

      const tokenResponse = await token(userLogin).unwrap();
      if (tokenResponse.isSuccess) {
        localStorage.setItem("username", values.username);
        localStorage.setItem("token", tokenResponse.data.token);
        dispatch(setToken(tokenResponse.data));
        props.onSubmit();
      } else {
        notification.error({ message: tokenResponse.message });
      }
    }
  };

  return (
    <>
      <Form
        form={form}
        initialValues={{
          remember: true
        }}
        style={{
          maxWidth: "300px",
          margin: "auto"
        }}
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your username."
            }
          ]}
        >
          <Input prefix={<FontAwesomeIcon icon={["fas", "user"]} />} placeholder="Username" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password."
            }
          ]}
        >
          <Input onPressEnter={handleSubmit} prefix={<FontAwesomeIcon icon={["fas", "lock"]} />} type="password" placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button icon={<FontAwesomeIcon icon={["fas", "sign-in-alt"]} />} loading={loginLoading} type="primary" block onClick={handleSubmit}>
            Log in
          </Button>
          <Row gutter={16} align="middle">
            <Col span={24}>
              <Button block type="link" onClick={handleRegister}>
                Register
              </Button>
            </Col>
            <Col span={24}>
              <Button block type="link" onClick={handleForgetPassword}>
                Forgot Username / Password
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export default LoginForm;
