import React from "react";
import CleanButton from "./CleanButton";

type Props = { onClick: (e) => void };
const TableActionButton = ({ ...props }: Props) => {
  const { onClick } = props;

  return <CleanButton type="link" icon="eye" onClick={onClick} />;
};

export default TableActionButton;
